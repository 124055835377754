import React from 'react';
import { getFirestore, doc, onSnapshot } from "firebase/firestore"; // Import Firestore functions
import { useNavigate, useParams } from 'react-router-dom';
import './gamepage.css';
import Carousel from './Carousel';
import Adventureicon from '../assets/genres/adventure-dark.svg';
import Battleroyalicon from '../assets/genres/battleroyale-dark.svg';
import Fightingicon from '../assets/genres/fighting-dark.svg';
import FPSicon from '../assets/genres/fps-dark.svg';
import Minigamesicon from '../assets/genres/minigames-dark.svg';
import Racingicon from '../assets/genres/racing-dark.svg';
import RPGicon from '../assets/genres/rpg-dark.svg';
import Strategyicon from '../assets/genres/strategy-dark.svg';
import Survivalicon from '../assets/genres/survival-dark.svg';
import iosIcon from '../assets/OSystems/ios-dark.svg';
import macosIcon from '../assets/OSystems/macos-dark.svg';
import gplayIcon from '../assets/OSystems/gplay-dark.svg';
import windowsIcon from '../assets/OSystems/windows-dark.svg';
import InstaIcon from '../assets/socials/insta-dark.svg';
import TiktokIcon from '../assets/socials/tiktok-dark.svg';
import XIcon from '../assets/socials/X-dark.svg';
import YoutubeSocialIcon from '../assets/socials/youtube-dark.svg';
import FacebookIcon from '../assets/socials/facebook-dark.svg';
import TwitchIcon from '../assets/socials/twitch-dark.svg';
import TelegramIcon from '../assets/socials/telegram-dark.svg';
import DiscordIcon from '../assets/socials/discord-dark.svg';
import WebsiteIcon from '../assets/socials/web-icon.svg';

const genreIcons = {
    Adventure: Adventureicon,
    'Battle Royale': Battleroyalicon,
    Fighting: Fightingicon,
    FPS: FPSicon,
    'Minigames': Minigamesicon,
    Racing: Racingicon,
    RPG: RPGicon,
    Strategy: Strategyicon,
    Survival: Survivalicon,
};

const platformIcons = {
    iOS: iosIcon,
    Mac: macosIcon,
    Android: gplayIcon,
    Windows: windowsIcon,
};

const getHandleFromUrl = (url, platform) => {
    const urlObj = new URL(url);
    switch (platform) {
      case 'instagram':
        return urlObj.pathname.split('/')[1]; // Get the handle from the path
      case 'youtube':
        return urlObj.pathname.split('/')[1]; // Get the handle from the path
      case 'x':
        return urlObj.pathname.split('/')[1]; // Get the handle from the path
      case 'tiktok':
        return urlObj.pathname.split('/')[1]; // Get the handle from the path
      case 'twitch':
        return urlObj.pathname.split('/')[1]; // Get the handle from the path
      case 'facebook':
        return urlObj.pathname.split('/')[1]; // Get the handle from the path
      case 'telegram':
        return urlObj.pathname.split('/')[1]; // Get the handle from the path
      case 'discord':
        return urlObj.pathname.split('/')[1]; // Get the handle from the path
      default:
        return '';
    }
};

const GamePage = () => {
    const { userId, gameNumber, gameTitle } = useParams();
    const [gameData, setGameData] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [gameContent, setGameContent] = React.useState([]); // State to track the current game content
    const [userData, setUserData] = React.useState(null);
    const db = getFirestore(); // Initialize Firestore
    console.log("Firestore has been initialized."); // Log when Firestore is initialized
    const navigate = useNavigate();

    React.useEffect(() => {
        const gameRef = doc(db, 'users', userId, gameNumber, 'gameDetails'); // Added 'gameDetails' as the fourth segment
        console.log("Fetching game data from:", gameRef.path); // Log the document path

        const unsubscribeGame = onSnapshot(gameRef, (snapshot) => {
            const data = snapshot.data();
            if (data) {
                setGameData(data);
                setGameContent(data.game1content || []);
                setUserData(data);
            } else {
                console.error('No data found for game:', gameTitle);
            }
            setLoading(false);
        });

        return () => unsubscribeGame(); // Clean up the listener on unmount
    }, [gameTitle, userId, gameNumber, db]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!gameData) {
        return <p>Game data not found.</p>; // Handle case where gameData is not available
    }

    return (
        <div className="creator-page header-app-route game-page">
            <div className="profile-banner">
                <img src={gameData[`game${gameNumber.charAt(gameNumber.length - 1)}profileBanner`]} alt="Profile Banner" className="banner-image" />
                <div className="overlay"></div>
                <div className="edit-button">
                    <button className="nav-button" onClick={() => navigate(`/edit-studio-page/${userId}`)}>Edit Profile</button>
                </div>
                <div className="share-button">
                    <button className="nav-button" onClick={() => navigate(`/${gameData.username}`)}>Share</button>
                </div>
                <div className="profile-info">
                    <img src={gameData[`game${gameNumber.charAt(gameNumber.length - 1)}profilePic`]} alt="Profile" className="profile-pic" />
                    <h2 className="profile-displayname">{gameData[`game${gameNumber.charAt(gameNumber.length - 1)}title`]}</h2>
                </div>
            </div>
            <div className="profile-details-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className="question-container bio">
                    <p>{gameData[`game${gameNumber.charAt(gameNumber.length - 1)}description`]}</p>
                </div>
                <div className="carousel-container">
                    <Carousel items={gameContent} navigation={true} />
                </div>
                <div className="profile-details" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div className="columns">
                        <div className="question-container featured-content" style={{minWidth: '100px', width: '50%'}}>
                            <div className="genre-section-container gamepage-section">
                                <div className="section-title" style={{ width: '100%' }}>
                                    <h3>Game Genres</h3>
                                </div>
                                <div className="genre-icons">   
                                    {gameData[`game${gameNumber.charAt(gameNumber.length - 1)}genres`]?.map((genre, index) => (
                                        <img 
                                            key={index} 
                                            src={genreIcons[genre.trim()]} 
                                            alt={genre.trim()} 
                                            className="genre-icon" 
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className="platforms-section-container gamepage-section">
                                <div className="section-title" style={{ width: '100%' }}>
                                    <h3>Compatible Platforms</h3>
                                </div>
                                <div className="platform-icons genre-icons">   
                                    {gameData[`game${gameNumber.charAt(gameNumber.length - 1)}platforms`]?.map((platform, index) => (
                                        <img 
                                            key={index} 
                                            src={platformIcons[platform.trim()]} 
                                            alt={platform.trim()} 
                                            className="platform-icon genre-icon" 
                                        />
                                    ))} 
                                </div>
                            </div>
                            <div className="development-stage-section-container gamepage-section">
                                <div className="section-title" style={{ width: '100%' }}>
                                    <h3>Development Stage</h3>
                                </div>
                                <div className="genre-icons">   
                                    <p style={{fontSize: '18px',fontWeight: 'bold', marginBottom: '0px'}}>
                                        {gameData[`game${gameNumber.charAt(gameNumber.length - 1)}developmentStage`]}
                                    </p>
                                </div>
                            </div>
                            <div className="development-stage-section-container gamepage-section">
                                <div className="section-title" style={{ width: '100%' }}>
                                    <h3>Studio</h3>
                                </div>
                                <div className="genre-icons">   
                                    <p style={{fontSize: '18px',fontWeight: 'bold', marginBottom: '0px'}}><a href={`/${userData?.username}`} target="_blank" rel="noopener noreferrer">{userData?.name}</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="social-links-section-container gamepage-section" style={{width: '50%'}}>
                            <div className="section-title" style={{ width: '100%' }}>
                                <h3>Official Links</h3>
                            </div>
                            <div className="social-accounts-list">
                                {Object.entries(gameData[`game${gameNumber.charAt(gameNumber.length - 1)}socialUrls`]).map(([platform, url], index) => {
                                    const trimmedPlatform = platform.trim();
                                    return (
                                        url && ( // Only render if url is populated
                                            <div key={index} className="social-account-item">
                                                <div className="social-links">
                                                    <div className="social-link-item">
                                                        {trimmedPlatform === 'instagram' && (
                                                            <>
                                                                <img src={InstaIcon} alt="Instagram" className="social-icon" />
                                                                <a href={url} target="_blank" rel="noopener noreferrer">
                                                                    @{getHandleFromUrl(url, 'instagram')}
                                                                </a>
                                                            </>
                                                        )}
                                                        {trimmedPlatform === 'tiktok' && (
                                                            <>
                                                                <img src={TiktokIcon} alt="TikTok" className="social-icon" />
                                                                <a href={url} target="_blank" rel="noopener noreferrer">
                                                                    @{getHandleFromUrl(url, 'tiktok').replace('@', '')}
                                                                </a>
                                                            </>
                                                        )}
                                                        {trimmedPlatform === 'x' && (
                                                            <>
                                                                <img src={XIcon} alt="X" className="social-icon" />
                                                                <a className="handle-link" href={url} target="_blank" rel="noopener noreferrer">
                                                                    @{getHandleFromUrl(url, 'x')}
                                                                </a>
                                                            </>
                                                        )}
                                                        {trimmedPlatform === 'youtube' && (
                                                            <>
                                                                <img src={YoutubeSocialIcon} alt="YouTube" className="social-icon youtube-icon" />
                                                                <a href={url} target="_blank" rel="noopener noreferrer">
                                                                    @{getHandleFromUrl(url, 'youtube').replace('@', '')}
                                                                </a>
                                                            </>
                                                        )}
                                                        {trimmedPlatform === 'facebook' && (
                                                            <>
                                                                <img src={FacebookIcon} alt="Facebook" className="social-icon" />
                                                                <a href={url} target="_blank" rel="noopener noreferrer">
                                                                    @{getHandleFromUrl(url, 'facebook')}
                                                                </a>
                                                            </>
                                                        )}
                                                        {trimmedPlatform === 'twitch' && (
                                                            <>
                                                                <img src={TwitchIcon} alt="Twitch" className="social-icon" />
                                                                <a href={url} target="_blank" rel="noopener noreferrer">
                                                                    @{getHandleFromUrl(url, 'twitch')}
                                                                </a>
                                                            </>
                                                        )}
                                                        {trimmedPlatform === 'discord' && (
                                                            <>
                                                                <img src={DiscordIcon} alt="Discord" className="social-icon" />
                                                                <a href={url} target="_blank" rel="noopener noreferrer">
                                                                    {getHandleFromUrl(url, 'discord')}
                                                                </a>
                                                            </>
                                                        )}
                                                        {trimmedPlatform === 'telegram' && (
                                                            <>
                                                                <img src={TelegramIcon} alt="Telegram" className="social-icon" />
                                                                <a href={url} target="_blank" rel="noopener noreferrer">
                                                                    {new URL(url).hostname}
                                                                </a>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    );
                                })}
                                {gameData[`game${gameNumber.charAt(gameNumber.length - 1)}website`] && (
                                    <div className="social-account-item">
                                        <div className="social-links">
                                            <div className="social-link-item">
                                                <img src={WebsiteIcon} alt="Website" className="social-icon" />
                                                <a href={gameData[`game${gameNumber.charAt(gameNumber.length - 1)}website`]} target="_blank" rel="noopener noreferrer">
                                                    {new URL(gameData[`game${gameNumber.charAt(gameNumber.length - 1)}website`]).hostname}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="news-section-container gamepage-section">
                        <div className="section-title" style={{ width: '50%' }}>
                            <h3>Recent News</h3>
                        </div>
                        <div className="news-cards">   
                            {gameData[`game${gameNumber.charAt(gameNumber.length - 1)}news`]?.game1news1link && (
                                <div className="news-card news-card-1 ">
                                    <a href={gameData[`game${gameNumber.charAt(gameNumber.length - 1)}news`][`game${gameNumber.charAt(gameNumber.length - 1)}news1link`]} target="_blank" rel="noopener noreferrer">
                                        <img src={gameData[`game${gameNumber.charAt(gameNumber.length - 1)}news`][`game${gameNumber.charAt(gameNumber.length - 1)}news1pic`]} alt="News 1" />
                                    </a>
                                </div>
                            )}
                            {gameData[`game${gameNumber.charAt(gameNumber.length - 1)}news`]?.game2news2link && (
                                <div className="news-card news-card-2 ">
                                    <a href={gameData[`game${gameNumber.charAt(gameNumber.length - 1)}news`][`game${gameNumber.charAt(gameNumber.length - 1)}news2link`]} target="_blank" rel="noopener noreferrer">
                                        <img src={gameData[`game${gameNumber.charAt(gameNumber.length - 1)}news`][`game${gameNumber.charAt(gameNumber.length - 1)}news2pic`]} alt="News 2" />
                                    </a>
                                </div>
                            )}
                            {gameData[`game${gameNumber.charAt(gameNumber.length - 1)}news`]?.game3news3link && (
                                <div className="news-card news-card-3 ">
                                    <a href={gameData[`game${gameNumber.charAt(gameNumber.length - 1)}news`][`game${gameNumber.charAt(gameNumber.length - 1)}news3link`]} target="_blank" rel="noopener noreferrer">
                                        <img src={gameData[`game${gameNumber.charAt(gameNumber.length - 1)}news`][`game${gameNumber.charAt(gameNumber.length - 1)}news3pic`]} alt="News 3" />
                                    </a>
                                </div>
                            )}
                            {gameData[`game${gameNumber.charAt(gameNumber.length - 1)}news`]?.game4news4link && (
                                <div className="news-card news-card-4 ">
                                    <a href={gameData[`game${gameNumber.charAt(gameNumber.length - 1)}news`][`game${gameNumber.charAt(gameNumber.length - 1)}news4link`]} target="_blank" rel="noopener noreferrer">
                                        <img src={gameData[`game${gameNumber.charAt(gameNumber.length - 1)}news`][`game${gameNumber.charAt(gameNumber.length - 1)}news4pic`]} alt="News 4" />
                                    </a>
                                </div>
                            )}
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    );
};

export default GamePage;  