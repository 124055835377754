import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './CreatorPage.css';
import Adventureicon from '../assets/genres/adventure-dark.svg';
import Battleroyalicon from '../assets/genres/battleroyale-dark.svg';
import Fightingicon from '../assets/genres/fighting-dark.svg';
import FPSicon from '../assets/genres/fps-dark.svg';
import Minigamesicon from '../assets/genres/minigames-dark.svg';
import Racingicon from '../assets/genres/racing-dark.svg';
import RPGicon from '../assets/genres/rpg-dark.svg';
import Strategyicon from '../assets/genres/strategy-dark.svg';
import Survivalicon from '../assets/genres/survival-dark.svg'; 
import locationDropIcon from '../assets/images/location-drop-icon.svg';
import ReactFlagsSelect from 'react-flags-select'; // Import ReactFlagsSelect
import InstaIcon from '../assets/socials/insta-dark.svg';
import TiktokIcon from '../assets/socials/tiktok-dark.svg';
import XIcon from '../assets/socials/X-dark.svg';
import YoutubeSocialIcon from '../assets/socials/youtube-dark.svg';
import TwitchIcon from '../assets/socials/twitch-dark.svg';
import FacebookIcon from '../assets/socials/facebook-dark.svg';
import Footer from '../components/Footer'; // Adjust the path as necessary
import axios from 'axios'; // Import Axios
import { auth } from '../firebase'; // Import Firebase auth
import { signOut } from 'firebase/auth'; // Import signOut from Firebase
import { Helmet } from 'react-helmet'; // Import Helmet
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore"; // Import Firestore functions

const genreIcons = {
  Adventure: Adventureicon,
  'Battle Royale': Battleroyalicon,
  Fighting: Fightingicon,
  FPS: FPSicon,
  'Minigames': Minigamesicon,
  Racing: Racingicon,
  RPG: RPGicon,
  Strategy: Strategyicon,
  Survival: Survivalicon,
};

const languageFlags = {
  Afrikaans: 'ZA',
  Albanian: 'AL',
  Arabic: 'AE',
  Armenian: 'AM',
  Basque: 'ES',
  Bengali: 'BD',
  Bulgarian: 'BG',
  Catalan: 'ES',
  Chinese: 'CN',
  Croatian: 'HR',
  Czech: 'CZ',
  Danish: 'DK',
  Dutch: 'NL',
  English: 'US',
  Estonian: 'EE',
  Finnish: 'FI',
  French: 'FR',
  Galician: 'ES',
  Georgian: 'GE',
  German: 'DE',
  Greek: 'GR',
  Hebrew: 'IL',
  Hindi: 'IN',
  Hungarian: 'HU',
  Icelandic: 'IS',
  Indonesian: 'ID',
  Irish: 'IE',
  Italian: 'IT',
  Japanese: 'JP',
  Korean: 'KR',
  Latvian: 'LV',
  Lithuanian: 'LT',
  Macedonian: 'MK',
  Malay: 'MY',
  Maltese: 'MT',
  Norwegian: 'NO',
  Persian: 'IR',
  Polish: 'PL',
  Portuguese: 'PT',
  Romanian: 'RO',
  Russian: 'RU',
  Serbian: 'RS',
  Slovak: 'SK',
  Slovenian: 'SI',
  Spanish: 'ES',
  Swahili: 'KE',
  Swedish: 'SE',
  Thai: 'TH',
  Turkish: 'TR',
  Ukrainian: 'UA',
  Vietnamese: 'VN',
  Welsh: 'GB',
  Yiddish: 'IL',
};

const regionNames = {
  NA: "North America",
  EU: "Europe",
  EAS: "East Asia",
  SA: "South America",
  AU: "Australia and Pacific Islands",
  CAS: "Central Asia",
  ME: "Middle East",
  NAF: "North Africa",
  RU: "Russia",
  SAF: "Sub-Saharan Africa",
  SAS: "South Asia",
  SEA: "South-East Asia",
  CA: "Central America",
};

const getHandleFromUrl = (url, platform) => {
  const urlObj = new URL(url);
  switch (platform) {
    case 'instagram':
    case 'youtube':
    case 'x':
    case 'tiktok':
    case 'twitch':
    case 'facebook':
      return urlObj.pathname.split('/')[1];
    default:
      return '';
  }
};

const API_KEY = 'd56fb269b2d345f3a6261a0dc8a34a28';

const fetchEmbedData = async (url) => {
  if (!url) {
    console.error('No URL provided for embed data fetching');
    return null;
  }

  try {
    const response = await axios.get(`https://api.embedly.com/1/oembed?key=${API_KEY}&url=${encodeURIComponent(url)}&native=true`);
    return response.data;
  } catch (error) {
    console.error('Error fetching embed data:', error);
    return null;
  }
};

const PublicProfile = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [embedData, setEmbedData] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const scrollableContentRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 850);
  const db = getFirestore();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 850);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('username', '==', username));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        const user = userDoc.data();
        console.log('User data found:', user);
        setUserData(user);
        if (user.featureContent) {
          const links = user.featureContent.split(',').map(link => link.trim()).filter(link => link);
          const embedPromises = links.map(link => {
            console.log('Fetching embed data for URL:', link);
            return fetchEmbedData(link);
          });
          const embeds = await Promise.all(embedPromises);
          setEmbedData(embeds.filter(embed => embed));
        }
        const profileBannerUrl = user.profileBanner;
        setUserData({ ...user, profileBanner: profileBannerUrl });
      } else {
        console.error('No user found with username:', username);
      }
      setLoading(false);
    };

    fetchUserData();
  }, [username, db]);

  useEffect(() => {
    if (!loading && userData) { 
      const handleScroll = () => {
        const scrollTop = scrollableContentRef.current.scrollTop;
        console.log('Scroll event triggered');
        console.log('Current scroll position:', scrollTop);

        if (scrollTop > 1) {
          setIsCollapsed(true);
          console.log('Banner collapsed:', true);
        } else {
          setIsCollapsed(false);
          console.log('Banner collapsed:', false);
        }
      };

      const scrollableDiv = scrollableContentRef.current;
      if (scrollableDiv) {
        scrollableDiv.addEventListener('scroll', handleScroll);
        console.log('Scroll event listener attached');
      }

      handleScroll();

      return () => {
        if (scrollableDiv) {
          scrollableDiv.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [loading, userData]);

  const handleSignOut = async () => {
    try {
      await signOut(auth); // Sign out the user
      navigate('/login'); // Redirect to the login page after sign-out
    } catch (error) {
      console.error('Error signing out:', error); // Log any errors
    }
  };

  if (loading) {
    console.log('Loading user data...');
    return <div className="loading-container"><h2 style={{ textAlign: 'center' }}>Loading shareable profile...</h2><div className="loading-spinner"></div></div>;
  }

  if (!userData) {
    return (
      <div className="creator-page header-app-route" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <p style={{color: 'rgba(230, 8, 198, 0.5)', fontSize: '20px', fontWeight: '600', fontFamily: 'Montserrat, sans-serif'}}>Oops! We couldn't find your profile. Please sign out and try again.</p>
        <button className="sign-out-button" onClick={handleSignOut}>
          Sign Out
        </button>
      </div>
    ); // Ensure this block is correctly formatted
  }

  console.log('Meta Tag Values:', {
    title: userData.name,
    description: userData.creatorBio,
    image: userData.profileBanner,
  });

  return (
    <>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={userData.name} />
        <meta name="twitter:description" content={userData.creatorBio} />
        <meta name="twitter:image" content={userData.profileBanner} />
      </Helmet>
      <div className={`creator-page header-app-route public-profile`}>
        <div className={`profile-banner ${isCollapsed ? 'collapsed' : ''}`}>
          <img src={userData.profileBanner} alt="Profile Banner" className="banner-image" />
          <div className="overlay"></div>
          <div className={`profile-info ${isCollapsed ? 'collapsed' : ''}`}>
            <img src={userData.profilePic} alt="Profile" className="profile-pic" />
            <h2 className="profile-displayname">{userData.name}</h2>
            <div className="profile-location">
              <img src={locationDropIcon} alt="Location Icon" className="location-drop-icon" />
              <p>{regionNames[userData.locationAnswer] || userData.locationAnswer}</p>
            </div>
            {!isMobile ? (
              <div className={`question-container bio desktop-bio ${isCollapsed ? 'collapsed' : ''}`}>
                <p>{userData.creatorBio}</p>
              </div>
            ) : null}
          </div>
        </div>
        <div className="scrollable-content" ref={scrollableContentRef}>
          <div className="profile-details-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {isMobile ? (
              <div className="question-container bio">
                <p>{userData.creatorBio}</p>
              </div>
            ) : null}
            <div className="profile-details">
              <div className="columns">
                <div className="column left-column">
                  <div className="question-container social-accounts">
                    <div className="section-title">
                      <h3>Socials</h3>
                    </div>
                    <div className="social-accounts-list">
                      {userData.contentPlatforms.split(',').map((contentPlatform, index) => {
                        const trimmedPlatform = contentPlatform.trim();
                        const socialUrl = userData.socialUrls[trimmedPlatform];

                        return (
                          socialUrl && (
                            <div key={index} className="social-account-item">
                              <div className="social-links">
                                <div className="social-link-item">
                                  {trimmedPlatform === 'instagram' && (
                                    <>
                                      <img src={InstaIcon} alt="Instagram" className="social-icon" />
                                      <a href={socialUrl} target="_blank" rel="noopener noreferrer">
                                        @{getHandleFromUrl(socialUrl, 'instagram')}
                                      </a>
                                    </>
                                  )}
                                  {trimmedPlatform === 'tiktok' && (
                                    <>
                                      <img src={TiktokIcon} alt="TikTok" className="social-icon" />
                                      <a href={socialUrl} target="_blank" rel="noopener noreferrer">
                                        @{getHandleFromUrl(socialUrl, 'tiktok').replace('@', '')}
                                      </a>
                                    </>
                                  )}
                                  {trimmedPlatform === 'x' && (
                                    <>
                                      <img src={XIcon} alt="X" className="social-icon" />
                                      <a className="handle-link" href={socialUrl} target="_blank" rel="noopener noreferrer">
                                        @{getHandleFromUrl(socialUrl, 'x')}
                                      </a>
                                    </>
                                  )}
                                  {trimmedPlatform === 'youtube' && (
                                    <>
                                      <img src={YoutubeSocialIcon} alt="YouTube" className="social-icon youtube-icon" />
                                      <a href={socialUrl} target="_blank" rel="noopener noreferrer">
                                        @{getHandleFromUrl(socialUrl, 'youtube').replace('@', '')}
                                      </a>
                                    </>
                                  )}
                                  {trimmedPlatform === 'twitch' && (
                                    <>
                                      <img src={TwitchIcon} alt="Twitch" className="social-icon" />
                                      <a href={socialUrl} target="_blank" rel="noopener noreferrer">
                                        @{getHandleFromUrl(socialUrl, 'twitch')}
                                      </a>
                                    </>
                                  )}
                                  {trimmedPlatform === 'facebook' && (
                                    <>
                                      <img src={FacebookIcon} alt="Facebook" className="social-icon" />
                                      <a href={socialUrl} target="_blank" rel="noopener noreferrer">
                                        @{getHandleFromUrl(socialUrl, 'facebook')}
                                      </a>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="column center-column">
                  <div className="question-container featured-content">
                    <div className="section-title featured-content">
                      <h3>Featured Content</h3>
                    </div>
                    {embedData.map((embed, index) => (
                      <div key={index} className="embedded-content">
                        <div dangerouslySetInnerHTML={{ __html: embed.html }} />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="column right-column">
                  <div className="question-container genres">
                    <div className="section-title">
                      <h3>Genres</h3>
                    </div>
                    <div className="genre-icons">
                      {userData.genrePreference.split(',').map((genre, index) => (
                        <img 
                          key={index} 
                          src={genreIcons[genre.trim()]} 
                          alt={genre.trim()} 
                          className="genre-icon" 
                        />
                      ))}
                    </div>
                  </div>
                  <div className="question-container languages">
                    <div className="section-title">
                      <h3>Languages</h3>
                    </div>
                    <div className="languages-list">
                      {userData.language.split(',').map((language, index) => {
                        const trimmedLanguage = language.trim();
                        const countryCode = languageFlags[trimmedLanguage];

                        return (
                          <div key={index} className="language-item">
                            <ReactFlagsSelect style={{ backgroundColor: 'transparent' }}
                              selected={countryCode}
                              countries={Object.values(languageFlags)}
                              customLabels={{
                                [countryCode]: trimmedLanguage
                              }}
                              disabled
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicProfile;