import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFirestore, doc, updateDoc, getDoc, onSnapshot } from "firebase/firestore"; // Import Firestore functions
import ClipLoader from 'react-spinners/ClipLoader';
import downloadIcon from '../assets/images/Download-icon.svg'; // Path to your download icon
import profileIcon from '../assets/images/Profile-icon.svg'; // Path to your profile icon
import YoutubeIcon from '../assets/socials/youtube-light.svg'; // Path to your Youtube icon
import ContinueIcon from '../assets/images/angle-grey.svg'; // Path to your continue icon
import locationIcon from '../assets/images/location-drop-icon.svg'; // Path to your location icon
import LanguageIcon from '../assets/images/language-icon.svg'; // Path to your language icon
import linkIcon from '../assets/images/link-chain-icon.svg'; // Path to your link icon
import UsernameIcon from '../assets/images/@.svg'; // Path to your username icon
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import './EditCreatorPage.css';
import './CreatorPage.css';
import checkIcon from '../assets/images/Check-icon.svg'; // Import the check icon
import InstaIcon from '../assets/socials/insta-dark.svg';
import TiktokIcon from '../assets/socials/tiktok-dark.svg';
import XIcon from '../assets/socials/X-dark.svg';
import YoutubeSocialIcon from '../assets/socials/youtube-dark.svg';
import FacebookIcon from '../assets/socials/facebook-dark.svg';
import TwitchIcon from '../assets/socials/twitch-dark.svg';
import BioIcon from '../assets/images/bio-icon.svg'; // Import the bio icon
import GenreIcon from '../assets/images/genre-icon.svg'; // Import the genre icon

const regions = [
  { value: "NA", label: "North America" },
  { value: "EU", label: "Europe" },
  { value: "EAS", label: "East Asia" },
  { value: "SA", label: "South America" },
  { value: "AU", label: "Australia and PI" },
  { value: "CAS", label: "Central Asia" },
  { value: "ME", label: "Middle East" },
  { value: "NAF", label: "North Africa" },
  { value: "RU", label: "Russia" },
  { value: "SAF", label: "Sub-Saharan Africa" },
  { value: "SAS", label: "South Asia" },
  { value: "SEA", label: "South-East Asia" },
  { value: "CA", label: "Central America" },
];

const languages = [
  { name: 'Afrikaans', code: 'ZA' },
  { name: 'Albanian', code: 'AL' },
  { name: 'Arabic', code: 'AE' },
  { name: 'Armenian', code: 'AM' },
  { name: 'Basque', code: 'ES' },
  { name: 'Bengali', code: 'BD' },
  { name: 'Bulgarian', code: 'BG' },
  { name: 'Catalan', code: 'ES' },
  { name: 'Chinese', code: 'CN' },
  { name: 'Croatian', code: 'HR' },
  { name: 'Czech', code: 'CZ' },
  { name: 'Danish', code: 'DK' },
  { name: 'Dutch', code: 'NL' },
  { name: 'English', code: 'US' },
  { name: 'Estonian', code: 'EE' },
  { name: 'Finnish', code: 'FI' },
  { name: 'French', code: 'FR' },
  { name: 'Galician', code: 'ES' },
  { name: 'Georgian', code: 'GE' },
  { name: 'German', code: 'DE' },
  { name: 'Greek', code: 'GR' },
  { name: 'Hebrew', code: 'IL' },
  { name: 'Hindi', code: 'IN' },
  { name: 'Hungarian', code: 'HU' },
  { name: 'Icelandic', code: 'IS' },
  { name: 'Indonesian', code: 'ID' },
  { name: 'Irish', code: 'IE' },
  { name: 'Italian', code: 'IT' },
  { name: 'Japanese', code: 'JP' },
  { name: 'Korean', code: 'KR' },
  { name: 'Latvian', code: 'LV' },
  { name: 'Lithuanian', code: 'LT' },
  { name: 'Macedonian', code: 'MK' },
  { name: 'Malay', code: 'MY' },
  { name: 'Maltese', code: 'MT' },
  { name: 'Norwegian', code: 'NO' },
  { name: 'Persian', code: 'IR' },
  { name: 'Polish', code: 'PL' },
  { name: 'Portuguese', code: 'PT' },
  { name: 'Romanian', code: 'RO' },
  { name: 'Russian', code: 'RU' },
  { name: 'Serbian', code: 'RS' },
  { name: 'Slovak', code: 'SK' },
  { name: 'Slovenian', code: 'SI' },
  { name: 'Spanish', code: 'ES' },
  { name: 'Swahili', code: 'KE' },
  { name: 'Swedish', code: 'SE' },
  { name: 'Thai', code: 'TH' },
  { name: 'Turkish', code: 'TR' },
  { name: 'Ukrainian', code: 'UA' },
  { name: 'Vietnamese', code: 'VN' },
  { name: 'Welsh', code: 'GB' },
  { name: 'Yiddish', code: 'IL' },
];

const genres = [
  { name: 'Adventure', code: 'ADV' },
  { name: 'Battle Royale', code: 'BR' },
  { name: 'Fighting', code: 'FIGHT' },
  { name: 'FPS', code: 'FPS' },
  { name: 'Minigames', code: 'MINI' },
  { name: 'Racing', code: 'RACE' },
  { name: 'RPG', code: 'RPG' },
  { name: 'Strategy', code: 'STRAT' },
  { name: 'Survival', code: 'SURV' },
];

const EditCreatorPage = () => {
  const { userId } = useParams();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [bannerFile, setBannerFile] = useState(null);
  const [profileFile, setProfileFile] = useState(null);
  const [profilePicUploaded, setProfilePicUploaded] = useState(false); // State for profile picture upload
  const [profileBannerUploaded, setProfileBannerUploaded] = useState(false); // State for banner upload
  const [isEditingDisplayName, setIsEditingDisplayName] = useState(false);
  const [tempDisplayName, setTempDisplayName] = useState(userData?.name || ''); // Temporary state for display name input
  const [tempUsername, setTempUsername] = useState(userData?.username || ''); // Temporary state for username input
  const [isEditingUsername, setIsEditingUsername] = useState(false); // State to manage editing username
  const [usernameError, setUsernameError] = useState(''); // State for username validation error
  const [usernameUpdated, setUsernameUpdated] = useState(false); // Track if username has been updated
  const [displayNameUpdated, setDisplayNameUpdated] = useState(false); // Track if display name has been updated
  const [socialLinks, setSocialLinks] = useState({
    youtube: '',
    instagram: '',
    tiktok: '',
    x: '',
    facebook: '',
    twitch: ''
  });
  const [isEditingSocials, setIsEditingSocials] = useState(false);
  const [socialLinksUpdated, setSocialLinksUpdated] = useState(false); // New state for tracking social links updates

  // Temporary state for social links
  const [tempSocialLinks, setTempSocialLinks] = useState({
    youtube: '',
    instagram: '',
    tiktok: '',
    x: '',
    facebook: '',
    twitch: ''
  });

  const [tempBio, setTempBio] = useState(''); // Temporary state for bio
  const [bioUpdated, setBioUpdated] = useState(false); // Track if bio has been updated
  const [error, setError] = useState(''); // State for error messages
  const [isEditingBio, setIsEditingBio] = useState(false); // State to manage editing bio
  const [isEditingFeaturedContent, setIsEditingFeaturedContent] = useState(false); // State to manage editing featured content
  const [tempFeaturedContent, setTempFeaturedContent] = useState(['', '', '', '']); // Temporary state for featured content inputs
  const [featuredContentUpdated, setFeaturedContentUpdated] = useState(false); // State to track if featured content is updated
  const [isEditingLocation, setIsEditingLocation] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [isEditingLanguage, setIsEditingLanguage] = useState(false);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [iconColor, setIconColor] = useState('#5E00D4'); // Default icon color
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [isEditingGenres, setIsEditingGenres] = useState(false);

  const db = getFirestore(); // Initialize Firestore

  useEffect(() => {
    const userRef = doc(db, 'users', userId); // Use Firestore document reference
    const unsubscribe = onSnapshot(userRef, (snapshot) => {
      const data = snapshot.data(); // Get data from the document
      if (data) {
        setUserData(data);
        setSocialLinks({
          youtube: data.socialUrls?.youtube || '',
          instagram: data.socialUrls?.instagram || '', 
          tiktok: data.socialUrls?.tiktok || '',
          x: data.socialUrls?.x || '',
          facebook: data.socialUrls?.facebook || '',
          twitch: data.socialUrls?.twitch || ''
        });
        // Initialize temporary social links
        setTempSocialLinks({
          youtube: data.socialUrls?.youtube || '',
          instagram: data.socialUrls?.instagram || '',
          tiktok: data.socialUrls?.tiktok || '',
          x: data.socialUrls?.x || '',
          facebook: data.socialUrls?.facebook || '',
          twitch: data.socialUrls?.twitch || ''
        });
        setTempBio(data.bio || ''); // Initialize temporary bio
        // Set the initial state for featured content based on existing data
        const existingFeaturedContent = data.featureContent ? data.featureContent.split(',') : [];
        setTempFeaturedContent(existingFeaturedContent.concat(Array(4 - existingFeaturedContent.length).fill(''))); // Fill with empty strings if less than 4
        setSelectedLanguages(data.language.split(',').map(lang => lang.trim())); // Initialize selected languages
        setSelectedGenres(data.genrePreference ? data.genrePreference.split(',').map(genre => genre.trim()) : []);
      } else {
        console.error('No data found for user:', userId);
      }
      setLoading(false);
    });

    return () => unsubscribe(); // Call unsubscribe to clean up
  }, [userId, db]);

  useEffect(() => {
    const fetchUserData = async () => {
      const userRef = doc(db, 'users', userId); // Use Firestore document reference
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const data = userDoc.data();
        setUserData(data);
        // Additional logic to set other states based on user data can go here
      } else {
        console.error('No data found for user:', userId);
      }
    };

    fetchUserData(); // Call the fetch function
  }, [userId, db]); // Ensure userId and db are included in the dependencies

  useEffect(() => {
    const validateUsername = async () => {
        if (tempUsername) {
            const userRef = doc(db, 'users', userId); // Use Firestore document reference
            const userDoc = await getDoc(userRef);
            if (userDoc.exists()) {
                const existingUsernames = Object.values(userDoc.data()).map(user => user.username);

            if (existingUsernames.includes(tempUsername)) {
                setUsernameError('This username is unavailable'); // Set error message if username is taken
            } else {
                setUsernameError(''); // Clear error message if username is available
            }
        } else {
            setUsernameError(''); // Clear error message if input is empty
        }
    };
  }

    const handler = setTimeout(() => {
        validateUsername();
    }, 500); // Validate after 500ms of inactivity

    return () => {
        clearTimeout(handler); // Cleanup timeout on unmount or when tempUsername changes
    };
  }, [tempUsername, db, userId]);

  // URL validation function
  const isValidURL = (url) => {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z0-9\\-]+\\.)+[a-z]{2,})|' + // domain name
      'localhost|' + // localhost
      '\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}|' + // IP address
      '\\[?[a-f0-9:\\.]+\\])' + // IPv6
      '(\\:\\d+)?(\\/[-a-z0-9%_.~+@]*)*' + // port and path (allow @ in path)
      '(\\?[;&a-z0-9%_.~+=-]*)?' + // query string
      '(\\#[-a-z0-9_]*)?$','i'); // fragment locator
    return !!pattern.test(url);
  };

  const handleUpdate = async () => {
    // Validate URLs
    for (const [key, value] of Object.entries(tempSocialLinks)) {
      if (value && !isValidURL(value)) {
        setError(`Invalid URL for ${key.charAt(0).toUpperCase() + key.slice(1)}`);
        return; // Stop the update if any URL is invalid
      }
    }

    // Clear error if all URLs are valid
    setError('');

    // Check if there are any updates
    const hasUpdates = 
      tempDisplayName !== userData.name ||
      tempUsername !== userData.username ||
      tempBio !== userData.bio ||
      JSON.stringify(tempSocialLinks) !== JSON.stringify(userData.socialUrls) ||
      selectedRegion !== userData.locationAnswer ||
      selectedLanguages.join(', ') !== userData.language ||
      JSON.stringify(tempFeaturedContent) !== JSON.stringify(userData.featureContent.split(',')); // Check for featured content changes

    if (!hasUpdates) {
        // No updates made, navigate back to the profile page
        navigate(`/creator-page/${userId}`);
        return;
    }

    const updates = {
      ...(tempDisplayName && { name: tempDisplayName }), // Only include if defined
      ...(tempUsername && { username: tempUsername }), // Only include if defined
      profileBanner: userData.profileBanner,
      profilePic: userData.profilePic,
      ...(selectedRegion && { locationAnswer: selectedRegion }), // Only include if defined
      contentPlatforms: Object.keys(socialLinks).filter(key => socialLinks[key]).join(','), // Always include
      ...(Object.keys(tempSocialLinks).some(key => tempSocialLinks[key]) && { socialUrls: tempSocialLinks }), // Only include if any URL is defined
      ...(tempBio && { creatorBio: tempBio }), // Only include if defined
      ...(selectedLanguages.length > 0 && { language: selectedLanguages.join(', ') }), // Only include if defined
      ...(tempFeaturedContent.some(content => content) && { featureContent: tempFeaturedContent.join(',') }), // Only include if any content is defined
      ...(selectedGenres.length > 0 && { genrePreference: selectedGenres.join(', ') }) // Only include if defined
    };

    // Handle file uploads if any
    if (bannerFile) {
      const bannerURL = await uploadFile(bannerFile, `profileBanners/${userId}/${bannerFile.name}`);
      updates.profileBanner = bannerURL;  
    }

    if (profileFile) {
      const profileURL = await uploadFile(profileFile, `profilePics/${userId}/${profileFile.name}`);
      updates.profilePic = profileURL;
    }

    const userRef = doc(db, 'users', userId); // Use Firestore document reference
    await updateDoc(userRef, updates); // Update Firestore document
    navigate(`/creator-page/${userId}`); // Redirect after update
  };

  const uploadFile = async (file, path) => {
    const storage = getStorage();
    const storageReference = storageRef(storage, path);
    await uploadBytes(storageReference, file);
    return await getDownloadURL(storageReference);
  };

  const handleDisplayNameClick = () => {
    setIsEditingDisplayName(!isEditingDisplayName);
  };

  const handleLocationClick = () => {
    setIsEditingLocation(!isEditingLocation);
  };

  const handleLanguageClick = () => {
    setIsEditingLanguage(!isEditingLanguage);
  };

  const handleLanguageSelect = (lang) => {
    setSelectedLanguages(prev => {
      const newSelectedLanguages = prev.includes(lang) 
        ? prev.filter(l => l !== lang) // Remove language if already selected
        : [...prev, lang]; // Add language if not selected
      
      // Update icon color based on selection
      setIconColor(newSelectedLanguages.length > 0 ? '#C26DFF' : '#5E00D4');
      return newSelectedLanguages;
    });
  };

  const handleGenreSelect = (genreName) => {
    setSelectedGenres(prev => {
      if (prev.includes(genreName)) {
        return prev.filter(genre => genre !== genreName); // Remove genre if already selected
      } else {
        return [...prev, genreName]; // Add genre if not selected
      }
    });
  };

  if (loading) {
    return (
      <div className="loading-container">
        <ClipLoader size={50} color={"#123abc"} loading={loading} />
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="edit-creator-page header-app-route">
      <div className="profile-banner">
        <img src={userData.profileBanner} alt="Profile Banner" className="banner-image" />
        <div className="profile-info">
          <img src={userData.profilePic} alt="Profile" className="profile-pic" />
          <h2>{userData.name}</h2>
        </div>
      </div>
      <div className="edit-buttons-container">
        <div className="edit-buttons">
          <button onClick={handleDisplayNameClick}>
            <div className="edit-buttons-icon" style={{ backgroundColor: displayNameUpdated ? '#E608C6' : '#5E00D4' }}>
                <img src={profileIcon} alt="Profile-icon" />
            </div>
            Edit Display Name
            <img className="continue-icon" src={ContinueIcon} alt="Continue-icon" />
            </button>
            {isEditingDisplayName && (
                <input
                    type="text"
                    className="input"
                    placeholder={userData.name} // Assuming userData.name holds the current display name
                    value={tempDisplayName} // Bind the input value to tempDisplayName
                    onChange={(e) => {
                        setTempDisplayName(e.target.value);
                        setDisplayNameUpdated(true); // Mark as updated
                    }}
                    style={{
                      padding: '8px 10px', // Increase padding for height
                      fontSize: '16px', // Adjust font size
                      lineHeight: '1.5', // Adjust line height
                      height: 'auto', // Allow height to adjust based on content
                      display: 'block', // Ensure it behaves like a block element
                    }}
                />
            )}

            <button onClick={() => setIsEditingUsername(!isEditingUsername)}>
            <div className="edit-buttons-icon" style={{ backgroundColor: usernameUpdated ? '#E608C6' : '#5E00D4' }}>
                <img src={UsernameIcon} alt="Username-icon" />
            </div>
            Edit Username
            <img className="continue-icon" src={ContinueIcon} alt="Continue-icon" />
            </button>
            {isEditingUsername && (
                <>
                    <input
                        type="text"
                        className="input"
                        placeholder={userData.username} // Assuming userData.username holds the current username
                        value={tempUsername} // Bind the input value to tempUsername
                        onChange={(e) => {
                          setTempUsername(e.target.value);
                          setUsernameUpdated(true); // Mark as updated
                        }}
                        style={{
                          padding: '8px 10px', // Increase padding for height
                          fontSize: '16px', // Adjust font size
                          lineHeight: '1.5', // Adjust line height
                          height: 'auto', // Allow height to adjust based on content
                          display: 'block', // Ensure it behaves like a block element
                        }}
                    />
                    {usernameError && <p style={{ color: 'red' }}>{usernameError}</p>} {/* Display error message */}
                </>
            )}

            <button onClick={() => document.getElementById('banner-input').click()}>
            <div className="edit-buttons-icon" style={{ backgroundColor: profileBannerUploaded ? '#E608C6' : '#5E00D4' }}>
                <img src={profileBannerUploaded ? checkIcon : downloadIcon} alt="Banner-icon" />
            </div>
            Edit Banner Picture
            <img className="continue-icon" src={ContinueIcon} alt="Continue-icon" />
            </button>
        <input
          type="file"
          id="banner-input"
          style={{ display: 'none' }}
          onChange={(e) => {
            setBannerFile(e.target.files[0]);
            setProfileBannerUploaded(true); // Set to true when a file is selected
          }}
        />
        <button onClick={() => document.getElementById('profile-input').click()}>
            <div className="edit-buttons-icon" style={{ backgroundColor: profilePicUploaded ? '#E608C6' : '#5E00D4' }}>
                <img src={profilePicUploaded ? checkIcon : downloadIcon} alt="Profile-icon" />
            </div>
            Edit Profile Picture
            <img className="continue-icon" src={ContinueIcon} alt="Continue-icon" />
            </button>
        <input
          type="file"
          id="profile-input"
          style={{ display: 'none' }}
          onChange={(e) => {
            setProfileFile(e.target.files[0]);
            setProfilePicUploaded(true); // Set to true when a file is selected
          }}
        />
        <button onClick={() => setIsEditingSocials(!isEditingSocials)}>
            <div className="edit-buttons-icon" style={{ backgroundColor: socialLinksUpdated ? '#E608C6' : '#5E00D4' }}>
                <img src={YoutubeIcon} alt="Youtube-icon" />
            </div>
            Edit Social Links
            <img className="continue-icon" src={ContinueIcon} alt="Continue-icon" />
            </button>
        {isEditingSocials && (
          <div className="social-links-container">
            <div className="social-link">
              <img src={YoutubeSocialIcon} alt="YouTube" className="social-icon youtube-icon" />
              <input
                type="text"
                placeholder="YouTube URL"
                value={tempSocialLinks.youtube}
                onChange={(e) => {
                  setTempSocialLinks(prev => ({ ...prev, youtube: e.target.value }));
                  setSocialLinksUpdated(true); // Mark as updated
                }}
                className='input'
              />
            </div>
            <div className="social-link">
              <img src={InstaIcon} alt="Instagram" className="social-icon" />
              <input
                type="text"
                placeholder="Instagram URL"
                value={tempSocialLinks.instagram}
                onChange={(e) => {
                  setTempSocialLinks(prev => ({ ...prev, instagram: e.target.value }));
                  setSocialLinksUpdated(true); // Mark as updated
                }}
                className='input'
              />
            </div>
            <div className="social-link">
              <img src={TiktokIcon} alt="TikTok" className="social-icon" />
              <input
                type="text"
                placeholder="TikTok URL"
                value={tempSocialLinks.tiktok}
                onChange={(e) => {
                  setTempSocialLinks(prev => ({ ...prev, tiktok: e.target.value }));
                  setSocialLinksUpdated(true); // Mark as updated
                }}
                className='input'
              />
            </div>
            <div className="social-link">
              <img src={XIcon} alt="X" className="social-icon" />
              <input
                type="text"
                placeholder="X URL"
                value={tempSocialLinks.x}
                onChange={(e) => {
                  setTempSocialLinks(prev => ({ ...prev, x: e.target.value }));
                  setSocialLinksUpdated(true); // Mark as updated
                }}
                className='input'
              />
            </div>
            <div className="social-link">
              <img src={FacebookIcon} alt="Facebook" className="social-icon" />
              <input
                type="text"
                placeholder="Facebook URL"
                value={tempSocialLinks.facebook}
                onChange={(e) => {
                  setTempSocialLinks(prev => ({ ...prev, facebook: e.target.value }));
                  setSocialLinksUpdated(true); // Mark as updated
                }}
                className='input'
              />
            </div>
            <div className="social-link">
              <img src={TwitchIcon} alt="Twitch" className="social-icon" />
              <input
                type="text"
                placeholder="Twitch URL"
                value={tempSocialLinks.twitch}
                onChange={(e) => {
                  setTempSocialLinks(prev => ({ ...prev, twitch: e.target.value }));
                  setSocialLinksUpdated(true); // Mark as updated
                }}
                className='input'
              />
            </div>
          </div>
        )}
        <button onClick={() => setIsEditingBio(!isEditingBio)}>
          <div className="edit-buttons-icon" style={{ backgroundColor: bioUpdated ? '#E608C6' : '#5E00D4' }}>
            <img src={BioIcon} alt="Bio-icon" />
          </div>
          Edit Bio
          <img className="continue-icon" src={ContinueIcon} alt="Continue-icon" />
        </button>
        {isEditingBio && (
          <>
            <input
                type="text"
                placeholder="Write your bio here..."
                value={tempBio}
                onChange={(e) => {
                    if (e.target.value.length <= 250) { // Check if the length is within the limit
                        setTempBio(e.target.value);
                        setBioUpdated(true); // Mark as updated
                    }
                }}
                className='input'
                maxLength={250} // Limit input to 250 characters
                style={{
                    padding: '8px 10px', // Increase padding for height
                    fontSize: '16px', // Adjust font size
                    lineHeight: '1.5', // Adjust line height
                    height: 'auto', // Allow height to adjust based on content
                    display: 'block', // Ensure it behaves like a block element
                }}
            />
            <p style={{fontSize: '12px', color: '#5E00D4', margin: '0px'}}>{250 - tempBio.length} characters remaining</p> {/* Display remaining characters */}
          </>
        )}
        {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error message */}
        <button onClick={() => setIsEditingFeaturedContent(!isEditingFeaturedContent)}>
            <div className="edit-buttons-icon" style={{backgroundColor: featuredContentUpdated ? '#E608C6' : '#5E00D4'}}>
                <img src={linkIcon} alt="Link-icon" />
            </div>
            Edit Featured Content
            <img className="continue-icon" src={ContinueIcon} alt="Continue-icon" />
        </button>
        {isEditingFeaturedContent && (
            <div className="featured-content-inputs" style = {{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                {tempFeaturedContent.map((content, index) => (
                    <div className="content-input-container" key={index}>
                        <input
                            type="text"
                            placeholder={content || `Enter URL ${index + 1}`} // Use current content as placeholder
                            value={content}
                            onChange={(e) => {
                                const newContent = [...tempFeaturedContent];
                                newContent[index] = e.target.value;
                                setTempFeaturedContent(newContent);
                                setFeaturedContentUpdated(true); // Mark as updated
                            }}
                            className='input'
                        />
                    </div>
                ))}
            </div>
        )}
        <button onClick={handleLocationClick}>
          <div className="edit-buttons-icon">
            <img src={locationIcon} alt="Location-icon" />
          </div>
          Edit Location
          <img className="continue-icon" src={ContinueIcon} alt="Continue-icon" />
        </button>
        {isEditingLocation && (
          <select
            className="input"
            style={{
              padding: '8px 10px', // Increase padding for height
              fontSize: '16px', // Adjust font size
              lineHeight: '1.5', // Adjust line height
              height: 'auto', // Allow height to adjust based on content
              display: 'block', // Ensure it behaves like a block element
            }}
            onChange={(e) => setSelectedRegion(e.target.value)}
          >
            <option value="">Select a region</option>
            {regions.map((region) => (
              <option key={region.value} value={region.value}>
                {region.label}
              </option>
            ))}
          </select>
        )}
        <button onClick={handleLanguageClick}>
          <div className="edit-buttons-icon">
            <img src={LanguageIcon} alt="Language-icon" style={{ color: iconColor }} /> {/* Change icon color */}
          </div>
          Edit Languages
          <img className="continue-icon" src={ContinueIcon} alt="Continue-icon" />
        </button>
        {isEditingLanguage && (
          <div className="language-list">
            {languages.map((language) => (
              <div
                key={language.code}
                className={`language-item ${selectedLanguages.includes(language.name) ? 'selected' : ''}`}
                onClick={() => handleLanguageSelect(language.name)}
              >
                {language.name}
              </div>
            ))}
          </div>
        )}
        <button onClick={() => setIsEditingGenres(!isEditingGenres)}>
          <div className="edit-buttons-icon">
            <img src={GenreIcon} alt="Genre-icon" />
          </div>
          Edit Genres
          <img className="continue-icon" src={ContinueIcon} alt="Continue-icon" />
        </button>
        {isEditingGenres && (
          <div className="language-list">
            {genres.map((genre) => (
              <div 
                style={{
                  height: '200px',
                }}
                key={genre.code}
                className={`language-item ${selectedGenres.includes(genre.name) ? 'selected' : ''}`}
                onClick={() => handleGenreSelect(genre.name)}
              >
                {genre.name}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="update-button-container">
        <button className="update-button" style={{textAlign: 'center'}} onClick={handleUpdate}>Update Profile</button>
      </div>
    </div>
    </div>
  );
};

export default EditCreatorPage;
