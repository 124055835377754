import logo from '../assets/images/core-logo-tertiary.svg'; // Import the logo image
import notificationBell from '../assets/images/notification-bell.svg'; // Import the notification bell icon
import angleGrey from '../assets/images/angle-grey.svg'; // Import the angle grey icon
import accountCircle from '../assets/images/Profile-icon.svg'; // Import the account-circle SVG
import settingsIcon from '../assets/images/Settings-icon.svg'; // Import the settings SVG
import logOutIcon from '../assets/images/Signout-icon.svg'; // Import the log out SVG
import HideIcon from '../assets/images/eye-slash.svg'; // Import the hide icon
import menuIcon from '../assets/images/menu-icon.svg'; // Import the menu icon
import dashboardIcon from '../assets/images/Dashboard-icon.svg'; // Import the dashboard icon
import campaignIcon from '../assets/images/Campaign-icon.svg'; // Import the campaign icon
import deliverablesIcon from '../assets/images/Deliverables-icon.svg'; // Import the deliverables icon
import offersIcon from '../assets/images/Offers-icon.svg'; // Import the offers icon
import discoveryIcon from '../assets/images/Discovery-icon.svg'; // Import the discover icon
import logoOnDark from '../assets/images/core-logo-on-dark.svg'; // Import the logo image
import menuIconHighlighted from '../assets/images/menu-icon-highlighted.svg'; // Import the menu icon highlighted
import dashboardIconHighlighted from '../assets/images/Dashboard-icon-highlighted.svg'; // Import highlighted dashboard icon
import campaignIconHighlighted from '../assets/images/Campaign-icon-highlighted.svg'; // Import highlighted campaign icon
import deliverablesIconHighlighted from '../assets/images/Deliverables-icon-highlighted.svg'; // Import highlighted deliverables icon
import offersIconHighlighted from '../assets/images/Offers-icon-highlighted.svg'; // Import highlighted offers icon
import discoveryIconHighlighted from '../assets/images/Discovery-icon-highlighted.svg'; // Import highlighted discovery icon
import settingsIconHighlighted from '../assets/images/Settings-icon-highlighted.svg'; // Import highlighted settings icon
import './Header.css'; // Ensure this line is present
import React, { useState, useEffect } from 'react'; // Ensure useState and useEffect are imported
import { signOut } from 'firebase/auth'; // Import signOut from Firebase
import { auth } from '../firebase'; // Import your Firebase auth instance
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate for navigation
import { getFirestore, doc, getDoc } from "firebase/firestore"; // Import Firestore methods
import { onAuthStateChanged } from 'firebase/auth'; // Import onAuthStateChanged from Firebase Authentication

const HeaderApp = ({ profilePic, name, isNavOpen, setNavOpen, isLeftNavOpen, setLeftNavOpen, username, studioProfilePic }) => { // Accept profilePic, displayName, isNavOpen, and setNavOpen as props
  const [angleRotation, setAngleRotation] = useState(90); // State to manage angle rotation
  const [copyMessage, setCopyMessage] = useState(''); // State for copy message
  const navigate = useNavigate(); // Initialize useNavigate
  const [hoveredIcon, setHoveredIcon] = useState(null); // State to track hovered icon
  const location = useLocation();
  const isFeaturePage = location.pathname === '/creator-dashboard'; // Adjust the path as necessary
  const [userType, setUserType] = useState(null);
  const db = getFirestore(); // Initialize Firestore
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserId(user.uid); // Set the userId from the authenticated user
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          console.log('User data:', userDoc.data());
          setUserType(userDoc.data().userType); // Ensure this matches the field name in Firestore
          console.log('User type set to:', userDoc.data().userType); // Log the userType
        } else {
          console.error('No user data found for userId:', user.uid);
        }
      } else {
        console.log('User is not logged in'); 
      }
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [db]);

  useEffect(() => {
      setLeftNavOpen(false); // Always open left nav if on a feature page
  }, [setLeftNavOpen]);

  const toggleNav = () => {
    setNavOpen(!isNavOpen); // Toggle side nav visibility
    setAngleRotation(prevRotation => (prevRotation === 90 ? 270 : 90)); // Toggle angle rotation between 90 and 270 degrees
  };

  const toggleLeftNav = () => {
    setLeftNavOpen(!isLeftNavOpen); // Toggle the left nav state
  };


  const handleLogout = async () => {
    if (isNavOpen || isLeftNavOpen) { // Log out if either the left or right nav is open
      try {
        await signOut(auth); // Sign out the user
        console.log('User signed out successfully'); // Log success message
        navigate('/login'); // Redirect to the login page after sign-out
      } catch (error) {
        console.error('Error signing out:', error); // Log any errors
      }
    }
  };

  const handleNameClick = () => {
    navigator.clipboard.writeText(name) // Copy display name to clipboard
      .then(() => {
        setCopyMessage('Profile copied to clipboard!'); // Set the message
        setTimeout(() => setCopyMessage(''), 2000); // Clear the message after 2 seconds
      })
      .catch(err => console.error('Failed to copy: ', err)); // Log any errors
  };

  const handleProfileClick = () => {
    if (userType === 'studio') {
      navigate(`/studio/${userId}`); // Navigate to the studio's profile page
    } else if (userType === 'creator') {
      navigate(`/creator/${userId}`); // Navigate to the creator's profile page
    } else {
      console.error('User type is not defined');
    }
  };

  const handleDashboardClick = () => {
    if (userType === 'studio') {
      navigate(`/creator-dashboard`); // Navigate to the studio's profile page
    } else if (userType === 'creator') {
      navigate(`/creator-dashboard`); // Navigate to the creator's profile page
    } else {
      console.error('User type is not defined');
    }
  };

  const handleSettingsClick = () => {
    if (userType === 'studio' || userType === 'creator') {
      navigate(`/settings/${userId}`); // Navigate to the studio's profile page
    } else {
      console.error('User type is not defined');
    }
  };

  return (
    <div className={`header-app-container ${isFeaturePage ? 'no-blur' : ''}`} style={{ display: 'flex' }}>
      {/* Left Slide-Out Menu */}
      <div className={`side-nav left ${isLeftNavOpen ? 'open' : ''}`} style={{ width: '225px', height: '100%', backgroundColor: '#3B006F', boxShadow: '2px 0 5px rgba(0,0,0,0.5)', transition: 'transform 0.3s ease', transform: isLeftNavOpen ? 'translateX(0)' : 'translateX(-100%)', zIndex: '1000' }}>
        <div style={{ padding: '10px 0px', height: '100%', fontFamily: 'Montserrat', display: 'flex', flexDirection: 'column', fontSize: '16px', fontWeight: '600', gap: '5px' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px', paddingTop: '10px' }}>
            <img src={menuIconHighlighted} alt="Menu" style={{ width: '20px', height: '20px', marginRight: '10px', marginBottom: '5px', cursor: 'pointer'}} onClick={toggleLeftNav} />
            <a href="https://app.newgame.me" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
              <img src={logoOnDark} alt="Logo" style={{ width: '154px', height: '26px', marginLeft: '10px', cursor: 'pointer'}} />
            </a>
          </div>
          {/* Add more items as needed */}  
          
          {/* Dashboard */}
          <div className={`highlight-on-left-hover ${isFeaturePage ? 'dashboard' : ''}`} style={{ display: 'flex', alignItems: 'center' }} onMouseEnter={() => setHoveredIcon('dashboard')} onMouseLeave={() => setHoveredIcon(null)}>
            <div className={`hover-line ${isFeaturePage ? 'dashboard' : ''}`} style={{ height: '100%', width: '5px' }}></div>
            <div style={{ width: '100%', padding: '15px 20px', display: 'flex', alignItems: 'center', cursor: 'pointer', color: 'white' }} onClick={handleDashboardClick}>
              <img src={hoveredIcon === 'dashboard' || isFeaturePage ? dashboardIconHighlighted : dashboardIcon} alt="Dashboard" style={{ width: '24px', height: '24px', marginRight: '10px' }} />
              Dashboard
            </div>
          </div>

          {/* Offers */}
          <div className={`highlight-on-left-hover`} style={{ display: 'flex', alignItems: 'center' }} onMouseEnter={() => setHoveredIcon('offers')} onMouseLeave={() => setHoveredIcon(null)}>
            <div className="hover-line" style={{ height: '100%', width: '5px' }}></div>
            <div style={{ width: '100%', padding: '15px 20px', display: 'flex', alignItems: 'center', cursor: 'pointer', color: 'white' }} onClick={toggleLeftNav}>
              <img src={hoveredIcon === 'offers' ? offersIconHighlighted : offersIcon} alt="Offers" style={{ width: '24px', height: '24px', marginRight: '10px' }} />
              Offers
            </div>
          </div>

          {/* Campaign */}
          <div className={`highlight-on-left-hover`} style={{ display: 'flex', alignItems: 'center' }} onMouseEnter={() => setHoveredIcon('campaign')} onMouseLeave={() => setHoveredIcon(null)}>
            <div className="hover-line" style={{ height: '100%', width: '5px' }}></div>
            <div style={{ width: '100%', padding: '15px 20px', display: 'flex', alignItems: 'center', cursor: 'pointer', color: 'white' }} onClick={toggleLeftNav}>
              <img src={hoveredIcon === 'campaign' ? campaignIconHighlighted : campaignIcon} alt="Campaign" style={{ width: '24px', height: '24px', marginRight: '10px' }} />
              Campaigns
            </div>
          </div>

          {/* Reputation */}
          <div className={`highlight-on-left-hover`} style={{ display: 'flex', alignItems: 'center' }} onMouseEnter={() => setHoveredIcon('discovery')} onMouseLeave={() => setHoveredIcon(null)}>
            <div className="hover-line" style={{ height: '100%', width: '5px' }}></div>
            <div style={{ width: '100%', padding: '15px 20px', display: 'flex', alignItems: 'center', cursor: 'pointer', color: 'white' }} onClick={toggleLeftNav}>
              <img src={hoveredIcon === 'discovery' ? discoveryIconHighlighted : discoveryIcon} alt="Discovery" style={{ width: '24px', height: '24px', marginRight: '10px' }} />
              Reputation
            </div>
          </div>

          {/* Deliverables */}
          <div className={`highlight-on-left-hover`} style={{ display: 'flex', alignItems: 'center' }} onMouseEnter={() => setHoveredIcon('deliverables')} onMouseLeave={() => setHoveredIcon(null)}>
            <div className="hover-line" style={{ height: '100%', width: '5px' }}></div>
            <div style={{ width: '100%', padding: '15px 20px', display: 'flex', alignItems: 'center', cursor: 'pointer', color: 'white' }} onClick={toggleLeftNav}>
              <img src={hoveredIcon === 'deliverables' ? deliverablesIconHighlighted : deliverablesIcon} alt="Deliverables" style={{ width: '24px', height: '24px', marginRight: '10px' }} />
              Payout Portal
            </div>
          </div>

          <div className={`highlight-on-left-hover`} style={{ display: 'flex', alignItems: 'center' }}>
          <div className="hover-line" style={{ height: '100%', width: '5px' }}></div>
            <div style={{ width: '100%', padding: '15px 20px', display: 'flex', alignItems: 'center', cursor: 'pointer', color: '#E608C6' }} onClick={toggleLeftNav}>
              <img src={HideIcon} alt="Hide Panel" style={{ width: '24px', height: '24px', marginRight: '10px' }} /> {/* Hide Panel Icon */}
              Hide Panel
            </div>
          </div>
          <div className={`highlight-on-left-hover`} style={{ display: 'flex', alignItems: 'center', marginTop: 'auto', marginBottom: '30px',   }}>
          <div className="hover-line" style={{ height: '100%', width: '5px' }}></div>
            <div style={{ width: '100%', padding: '15px 20px', display: 'flex', alignItems: 'center', cursor: 'pointer', color: '#E608C6'}} onClick={handleLogout}>
              <img src={logOutIcon} alt="Sign Out" style={{ width: '24px', height: '24px', marginRight: '10px' }} /> {/* Sign Out Icon */}
              Sign Out
            </div>
          </div>  
        </div>
      </div>

      {/* Right Slide-Out Menu */}
      <div className={`side-nav right ${isNavOpen ? 'open' : ''}`} style={{ width: '200px', height: '100%', backgroundColor: '#3B006F', boxShadow: '-2px 0 5px rgba(0,0,0,0.5)', transition: 'transform 0.3s ease', transform: isNavOpen ? 'translateX(0)' : 'translateX(100%)' }}>
        <div style={{ padding: '10px 0px', height: '100%', fontFamily: 'Montserrat', display: 'flex', flexDirection: 'column', fontSize: '16px', fontWeight: '600', gap: '5px' }}>
          <div className={`scale-on-hover`} style={{ padding: '15px 20px', display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleNameClick}>
            <img 
              src={profilePic || studioProfilePic} 
              alt="User Profile" 
              className="user-profile-pic" 
              style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px', marginLeft: '0px' }}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h2 className="profile-username" style={{ marginLeft: '5px', fontWeight: '600', marginBottom: '2px' }}>@</h2>
              <h2 className="profile-username" style={{ marginLeft: '2px', fontWeight: '600'}}>{username}</h2>
            </div>
          </div>
          {copyMessage && (
            <div style={{
              backgroundColor: '#5E00D4',
              width: 'fit-content',
              padding: '5px',
              borderRadius: '5px',
              position: 'absolute',
              color: 'white',
              fontSize: '12px',
              marginTop: '5px',
              left: '50px', // Adjust as needed to align with the display name
              top: '10%', // Position it slightly lower than the display name
              transform: 'translateY(20%)', // Adjust the vertical position
            }}>
              {copyMessage}
            </div>
          )}
          <div className={`highlight-on-hover`} style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '100%', padding: '15px 20px', display: 'flex', alignItems: 'center', cursor: 'pointer', color: 'white' }} onClick={handleProfileClick}>
              <img src={accountCircle} alt="Profile" style={{ width: '24px', height: '24px', marginRight: '10px' }} /> {/* Account Icon */}
              Profile
            </div>
            <div className="hover-line" style={{ height: '100%', width: '5px' }}></div>
          </div>
          <div className={`highlight-on-hover`} style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
            <div style={{ width: '100%', padding: '15px 20px', display: 'flex', alignItems: 'center', cursor: 'pointer', color: 'white' }} onClick={handleSettingsClick}>
            <img src={hoveredIcon === 'settings' ? settingsIconHighlighted : settingsIcon} alt="Settings" style={{ width: '24px', height: '24px', marginRight: '10px' }} />
            Settings
            </div>
            <div className="hover-line" style={{ height: '100%', width: '5px' }}></div>
          </div>
          <div className={`highlight-on-hover`} style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
            <div style={{ width: '100%', padding: '15px 20px', display: 'flex', alignItems: 'center', cursor: 'pointer', color: '#E608C6' }} onClick={toggleNav}>
              <img src={HideIcon} alt="Hide Panel" style={{ width: '24px', height: '24px', marginRight: '10px' }} /> {/* Hide Panel Icon */}
              Hide Panel
            </div>
            <div className="hover-line" style={{ height: '100%', width: '5px' }}></div>
          </div>
          <div className={`highlight-on-hover`} style={{ display: 'flex', alignItems: 'center', marginTop: 'auto', marginBottom: '30px' }}>
            <div style={{ width: '100%', padding: '20px 20px', display: 'flex', alignItems: 'center', cursor: 'pointer', color: '#E608C6' }} onClick={handleLogout}>
              <img src={logOutIcon} alt="Sign Out" style={{ width: '24px', height: '24px', marginRight: '10px' }} /> {/* Log Out Icon */}
              Sign Out
            </div>
            <div className="hover-line" style={{ height: '100%', width: '5px' }}></div>
          </div>
        </div>
      </div>

      <div className="app-header" style={{ flex: 1, transition: 'margin-left 0.3s ease, margin-right 0.3s ease', marginLeft:'0px', marginRight: isNavOpen ? '200px' : '0' }}>
        <div className="header" style={{ backgroundColor: '#EDEDED', padding: '10px 15px', width: '100vw' }}>
          <div className="banner" style={{ display: 'flex', alignItems: 'center', width: 'fit-content' }}>
            <div className={`menu-logo-container ${!isLeftNavOpen ? '' : 'fade-out'}`} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <img src={menuIcon} alt="Menu" style={{ width: '20px', height: '20px', marginRight: '10px', cursor: 'pointer' }} onClick={() => setLeftNavOpen(!isLeftNavOpen)} /> {/* Toggle left menu */}
              <a href="https://app.newgame.me" target="_blank" rel="noopener noreferrer" className="header-logo" style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                <img src={logo} alt="Logo" /> 
              </a>
            </div>
          </div>
          <div className="header-right" style={{ display: 'flex', alignItems: 'center', marginRight: '40px' }}>
            <img src={notificationBell} alt="Notification Bell" className="notification-bell" /> {/* Add the notification bell icon */}
            {profilePic || studioProfilePic ? ( // Check for profilePic or studioProfilePic
              <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }} onClick={toggleNav}>
                <img src={profilePic || studioProfilePic} alt="User Profile" className="user-profile-pic" style={{ cursor: 'pointer', width: '40px', height: '40px', borderRadius: '50%', marginLeft: '20px' }} />
                <img src={angleGrey} alt="Angle Grey" className="angle-grey" style={{ marginLeft: '15px', transform: `rotate(${angleRotation}deg)`, transition: 'transform 0.3s ease', cursor: 'pointer' }} />
              </div>
            ) : null}
          </div>
        </div>
        {/* Main content goes here */}
      </div>
    </div>
  );
};

export default HeaderApp;
