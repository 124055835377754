import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../firebase'; // Adjust the path as necessary
import { confirmPasswordReset } from 'firebase/auth';
import Modal from './Modal'; // Import the Modal component

const PasswordSet = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const oobCode = queryParams.get('oobCode'); // One-time code for verification
  const continueUrl = queryParams.get('continueUrl'); // Optional continue URL

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false); // State to control modal visibility

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }

    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      setModalOpen(true); // Open the modal on success

      // Redirect to the continueUrl if provided
      if (continueUrl) {
        window.location.href = continueUrl;
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    navigate('/login'); // Navigate to the login page when modal is closed
  };

  return (
    <div className='password-reset-container' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 'auto', marginBottom: 'auto'}}>
      <h1>Set Your New Password</h1>
      <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div>
          <input style={{margin: '30px 0px 20px 0px'}}
            type="password"
            id="newPassword"
            placeholder='New Password'
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <input
            type="password"
            id="confirmPassword"
            placeholder='Confirm Password'
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
            {errorMessage && <p style={{ color: '#E46FED', marginTop: '-10px'}}>{errorMessage}</p>}
        </div>
        <button type="submit" style={{marginTop: '20px'}}>Reset Password</button>
      </form>

      <Modal 
        isOpen={modalOpen} 
        onClose={handleModalClose} 
        message="Success! Your password has been reset, please sign in." 
        onSignIn={handleModalClose} // Use the same function to close the modal and navigate
      />
    </div>
  );
};

export default PasswordSet;
