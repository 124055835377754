import React, { useState } from 'react';
import logo from '../assets/images/logo_title.png'; // Import the logo image
import './HeaderMobile.css'; // Import mobile-specific styles
import { ReactComponent as HamburgerIcon } from '../assets/images/hamburger-icon.svg'; // Import the hamburger icon

const HeaderMobile = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(prevState => !prevState); // Toggle dropdown state
    };

    return (
        <div className="header-mobile">
            <div className="header-content">
                <div className="hamburger" onClick={toggleDropdown}>
                    <HamburgerIcon />
                </div>
                <div className="mobileBanner" onClick={() => window.open("https://app.newgame.me", "_blank")}>
                    <img src={logo} alt="Logo" className="mobile-logo" />
                </div>
            </div>
            {dropdownOpen && (
                <div className="mobile-dropdown-content">
                    <a href="https://newgame.me/agency" className="nav-link">Agency</a>
                    <div className="separator"></div> {/* Separator line */}
                    <a href="https://docs.newgame.me" target="_blank" rel="noopener noreferrer" className="nav-link">Documentation</a>
                    <div className="separator"></div> {/* Separator line */}
                    <a href="./login" rel="noopener noreferrer" className="nav-link">Sign In</a>
                </div>
            )}
        </div>
    );
};

export default HeaderMobile;
