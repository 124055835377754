import React, { useState } from 'react';
import { auth } from '../firebase'; // Adjust the path as necessary
import { sendPasswordResetEmail, fetchSignInMethodsForEmail } from 'firebase/auth';

function PasswordResetForm() {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const actionCodeSettings = {
      url: 'https://app.newgame.me/password-reset', // Your app's URL
      handleCodeInApp: true,
      // Optional: Add iOS and Android settings if needed
      // iOSBundleId: 'com.yourcompany.yourapp',
      // androidPackageName: 'com.yourcompany.yourapp',
    };

    try {
      // Check if the email is associated with an existing user
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      if (signInMethods.length === 0) {
        setErrorMessage('No user found with this email address.');
        return;
      }

      // If user exists, send the password reset email
      await sendPasswordResetEmail(auth, email, actionCodeSettings);
      setErrorMessage('Password reset email sent. Check your inbox.');
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div className='login-profile' style={{marginTop: 'auto', marginBottom: 'auto'}}>
        <h1>Reset Password</h1>
        <p>Enter your email to reset your password</p>
    <form style={{marginTop: '20px', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column'}} onSubmit={handleSubmit}>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <input
          style={{width: '18vw'}}
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder='Email'
          required
        />
          {errorMessage && <p style={{color: '#E46FED', marginTop: '-10px'}}>{errorMessage}</p>}
      </div>
      <button type="submit">Send Reset Email</button>
    </form>
    </div>
  );
}

export default PasswordResetForm;
