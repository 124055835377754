import React, { useState, useEffect } from 'react';
import { auth } from '../firebase'; // Import your Firebase auth instance
import { sendEmailVerification, updateEmail, updatePassword, deleteUser } from 'firebase/auth';
import './SettingsPage.css'; // Import the CSS for styling
import { Link } from 'react-router-dom'; // Import Link for navigation


const SettingsPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [user, setUser] = useState(null);
    const [verificationSent, setVerificationSent] = useState(false);

    useEffect(() => {
        const user = auth.User;
        if (user) {
            setUser(user);
            setEmail(user.email || ' '); // Show existing email or ' '
        }
        else {
            console.error('User is not defined');
        }
    }, []);

    const handleEmailUpdate = async () => {
        if (user) {
            try {
                await updateEmail(user, email);
                await sendEmailVerification(user);
                setVerificationSent(true);
            } catch (error) {
                console.error('Error updating email:', error);
            }
        }
    };

    const handlePasswordUpdate = async () => {
        if (user) {
            try {
                await updatePassword(user, password);
            } catch (error) {
                console.error('Error updating password:', error);
            }
        }
    };

    const handleAccountDeletion = async () => {
        if (user) {
            try {
                await deleteUser(user);
                // Handle post-deletion logic (e.g., redirect to login)
            } catch (error) {
                console.error('Error deleting account:', error);
            }
        }
    };

    return (
        <div className="settings-page">
            <h1>Settings</h1>
            <p>Want to edit your profile? head to the <Link to="/edit-profile">Edit Profile Page.</Link></p>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '40px' }}>
                <label>Email:</label>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)}
                placeholder={email} />
                <button className="update-data-button" onClick={handleEmailUpdate}>Update Email</button>
                {verificationSent && <p>Verification email sent!</p>}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '40px' }}>
                <label>Password:</label>
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                <button className="update-data-button" onClick={handlePasswordUpdate}>Update Password</button>
            </div>
            <button className="delete-account-button" onClick={handleAccountDeletion}>Delete Account</button>
        </div>
    );
};

export default SettingsPage; 