import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; // Import Swiper styles
import { Navigation } from 'swiper/modules'; // Import Navigation module

const Carousel = ({ items }) => {
    const swiperRef = useRef(null); // Create a ref for the Swiper instance

    if (!Array.isArray(items)) {
        return <div>No items to display</div>; // Handle the case where items is not an array
    }

    return (
        <div>
            <Swiper
                ref={swiperRef} // Attach the ref to the Swiper component
                spaceBetween={1}
                slidesPerView={1}
                navigation={{ clickable: true }} // Ensure navigation is enabled
                modules={[Navigation]} // Include Navigation module
                onSlideChange={(swiper) => console.log('Slide index changed to: ', swiper.activeIndex)}
                onNavigationClick={() => console.log('Navigation clicked')}
            >
                {items.map((item, index) => (
                    <SwiperSlide key={index}>
                        <img src={item} alt={`Slide ${index}`} style={{ width: '100%', height: 'auto' }} />
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className="custom-pagination">
                {items.map((item, index) => (
                    <div key={index} className="pagination-preview" onClick={() => swiperRef.current.swiper.slideTo(index)}>
                        <img src={item} alt={`Preview ${index}`} style={{ width: '75px', height: 'auto', cursor: 'pointer' }} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Carousel;