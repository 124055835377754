import './CreatorDashboard.css';
import actionIcon from '../../assets/function-icons/action-icon.svg';

const CreatorDashboard = () => {

    return (
        <div className="dashboard-container header-app-route no-header-blur">
            <header className="dashboard-header">
            <div className="coming-soon-overlay" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <h1>Core Features Coming Soon</h1>
                    <h3> Previews and testing starting 12/13</h3>
                </div>
                <div className="dashboard-actions content-blur">
                    <button>
                        <img src={actionIcon} alt="Campaign Offers" />
                        <div className="action-label">
                            <h2>Campaign Offers</h2>
                            <p>Review Offers</p>
                        </div>
                    </button>
                    <button>
                        <img src={actionIcon} alt="Manage Campaigns" />
                        <div className="action-label">
                            <h2>Manage Campaigns</h2>
                            <p>Manage current campaigns</p>
                        </div>
                    </button>
                    <button>
                        <img src={actionIcon} alt="Analytics Report" />
                        <div className="action-label">
                            <h2>Analytics Report</h2>
                            <p>Review your metrics</p>
                        </div>
                    </button>
                    <button>
                        <img src={actionIcon} alt="Reputation & Ratings" />
                        <div className="action-label">
                            <h2>Reputation & Ratings</h2>
                            <p>Check your reputation</p>
                        </div>
                    </button>
                </div>
            </header>
            <section className="current-campaigns content-blur">
                <h2>Current Campaigns</h2>
                <div className="campaign-list">
                    <div className="campaign-item">
                        <span>Cosmik Battle - Cosmik Campaign</span>
                        <span>$3,250</span>
                    </div>
                    <div className="campaign-item">
                        <span>Pixel Vault - Season 1 Campaign</span>
                        <span>$1,500</span>
                    </div>
                    <div className="campaign-item">
                        <span>Ai Arena - Battle Royale Campaign</span>
                        <span>$12,100</span>
                    </div>
                </div>
            </section>
            <section className="new-offers content-blur">
                <h2>New Campaign Offers</h2>
                <div className="offer-item">
                    <span>Dreamrunner - The Soul Of Myra Campaign</span>
                    <span>$14,200</span>
                    <p>Dreamrunner campaign strategy will engage players, create hype around Knights of the Ether.</p>
                </div>
            </section>
            <section className="content-frequency content-blur">
                <h2>Content Frequency</h2>
                <div className="frequency-chart">
                    {/* Placeholder for chart */}
                </div>
            </section>
            <footer className="dashboard-footer">
                <div className="footer-info">
                    <span>$14,500 Upcoming Earnings</span>
                    <span>$77,000 Current Revenue</span>
                    <span>6 Live Campaigns</span>
                </div>
                <div className="footer-stats">
                    <span>Click-Throughs: 4,209</span>
                    <span>Impressions: 1.32M</span>
                </div>
            </footer>
        </div>
    );
};

export default CreatorDashboard;
