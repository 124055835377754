import React from 'react';
import logomark from '../assets/images/logomark.svg'; // Adjust the path if necessary
import './Footer.css'; // Create a CSS file for styling

const Footer = () => {
    return (
        <footer className="footer">
            <img src={logomark} alt="Logomark" className="footer-logo" />
            <p className="footer-text">New Game+ Copyright 2024</p>
        </footer>
    );
};

export default Footer;
