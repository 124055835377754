import React, { useEffect, useRef } from 'react';
import './Modal.css'; // Add your styles for the modal

const Modal = ({ isOpen, onClose, message, onSignIn }) => {
  const modalRef = useRef(); // Create a ref for the modal

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose(); // Close modal if clicked outside
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside); // Add event listener
    } else {
      document.removeEventListener('mousedown', handleClickOutside); // Clean up listener
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside); // Clean up on unmount
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay"> {/* Removed the show class */}
      <div className="modal-content" ref={modalRef}>
        <h2>Oops!</h2>
        <p className={message === "Password must be at least 8 characters long and include a special character." ? "error-message" : ""}>
          {message}
        </p>
        {message === "This account already exists! Sign In to continue." ? (
          <button className='modal-button' onClick={onSignIn}>Sign In</button>
        ) : (
          <button className='modal-button' onClick={onClose}>Close</button>
        )}
      </div>
    </div>
  );
};

export default Modal;
