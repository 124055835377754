import React, { useState } from 'react';
import './ChatPage.css';
import gamebot from '../assets/gamebot.svg';

const ChatPage = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');

    const handleSend = () => {
        if (input.trim()) {
            setMessages([...messages, { text: input, sender: 'user' }]);
            setInput('');
            
            // Simulate AI response with typing effect
            const aiResponse = 'AI response to: ' + input;
            let index = 0;

            // Clear previous AI message if exists
            setMessages(prevMessages => [...prevMessages, { text: '', sender: 'ai' }]);

            const typingInterval = setInterval(() => {
                setMessages(prevMessages => {
                    const newMessages = [...prevMessages];
                    newMessages[newMessages.length - 1].text = aiResponse.slice(0, index + 1);
                    return newMessages;
                });
                index++;
                if (index === aiResponse.length) {
                    clearInterval(typingInterval);
                }
            }, 100); // Adjust typing speed here
        }
    };

    return (
        <div className="chat-page" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <div className="chat-header" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                <h2>GAMEBOT+</h2>
                <img src={gamebot} alt="GAMEBOT+" />
            </div>
            <div className="chat-container">
                <div className="messages">
                    {messages.map((msg, index) => (
                        <div key={index} className={`message ${msg.sender}`}>
                            {msg.text}
                        </div>
                    ))}
                </div>
                <div className="input-container">
                    <input
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                        placeholder="Type your message..."
                    />
                    <button onClick={handleSend}>Send</button>
                </div>
            </div>
        </div>
    );
};

export default ChatPage;
