import React from 'react'; // Import useState and useEffect

import './LoadingOverlay.css'; // Create a CSS file for styling

const LoadingOverlay = ({ contentType, onOverlayClick }) => {
  return (
    <div className="loading-overlay" onClick={onOverlayClick}>
      <h1 className='fade-in'>Welcome!</h1>
      <p className='fade-button' style={{ margin: '40px 0px 40px 0px' }}>
        We are so glad you're here.
        <br />
        <br />
        Let's get you started by building a custom profile to showcase your {contentType}.
      </p>
      <button className='fade-button'>Click anywhere to start</button>
    </div>
  );
};

export default LoadingOverlay; 