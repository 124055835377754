import React, { useState, useEffect, useRef } from 'react'; // Import useRef
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../assets/images/logo_title.png'; // Import the logo image
import './StudioTypeform.css';
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore"; // Import Firestore functions
import ProgressBar from './ProgressBar'; // Import the ProgressBar component
import continueIcon from '../assets/continue-icon.svg'; // Import the SVG icon
import backIcon from '../assets/chevron-left.svg'; 
import Adventureicon from '../assets/genres/adventure-dark.svg';
import Battleroyalicon from '../assets/genres/battleroyale-dark.svg';
import Fightingicon from '../assets/genres/fighting-dark.svg';
import FPSicon from '../assets/genres/fps-dark.svg';
import Minigamesicon from '../assets/genres/minigames-dark.svg';
import Racingicon from '../assets/genres/racing-dark.svg';
import RPGicon from '../assets/genres/rpg-dark.svg';
import Strategyicon from '../assets/genres/strategy-dark.svg';
import Survivalicon from '../assets/genres/survival-dark.svg';
import AdventureSelected from '../assets/genres/Adventure.svg';
import BattleroyalSelected from '../assets/genres/Battle-Royale.svg';
import FightingSelected from '../assets/genres/Fighting.svg';
import FPSSelected from '../assets/genres/FPS.svg';
import MinigamesSelected from '../assets/genres/Minigames.svg';
import RacingSelected from '../assets/genres/Racing-icon.svg';
import RPGSelected from '../assets/genres/RPG.svg';
import StrategySelected from '../assets/genres/Strategy.svg';
import SurvivalSelected from '../assets/genres/Survival.svg';
import WorldMap from '../assets/regions/world-dark.svg'; // Default world map
import WorldNA from '../assets/regions/world-NA.svg'; // North America map
import WorldEU from '../assets/regions/world-EU.svg'; // Europe map
import WorldEAS from '../assets/regions/world-EAS.svg'; // East Asia map
import WorldSA from '../assets/regions/world-SA.svg'; // South America map
import WorldAU from '../assets/regions/world-AU.svg'; // Australia and PI map
import WorldCAS from '../assets/regions/world-CAS.svg'; // Central Asia map
import WorldME from '../assets/regions/world-ME.svg'; // Middle East map
import WorldNAF from '../assets/regions/world-NAF.svg'; // North Africa map
import WorldRU from '../assets/regions/world-RU.svg'; // Russia map
import WorldSAF from '../assets/regions/world-SAF.svg'; // Sub-Saharan Africa map
import WorldSAS from '../assets/regions/world-SAS.svg'; // South Asia map
import WorldSEA from '../assets/regions/world-SEA.svg'; // South-East Asia map
import WorldCA from '../assets/regions/world-CA.svg'; // Central America map
// Import other region maps as needed
import { auth } from '../firebase'; // Import Firebase auth
import { signOut, setPersistence, browserSessionPersistence } from 'firebase/auth';
import downloadIcon from '../assets/images/Download-icon.svg'; // Path to your download icon
import checkIcon from '../assets/images/Check-icon.svg'; // Path to your check icon
import addLinkIcon from '../assets/images/add-link.svg'; // Import the SVG icon 
import yesIcon from '../assets/decision-circles/check-circle-dark.svg'; // Import the SVG icon
import noIcon from '../assets/decision-circles/x-circle-dark.svg'; // Import the SVG icon
import yesSelectedIcon from '../assets/decision-circles/check-circle-light.svg'; // Import the SVG icon
import noSelectedIcon from '../assets/decision-circles/x-circle-light.svg'; // Import the SVG icon
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage"; // Import Firebase Storage functions
import iosIcon from '../assets/OSystems/ios-dark.svg';
import macosIcon from '../assets/OSystems/macos-dark.svg';
import gplayIcon from '../assets/OSystems/gplay-dark.svg';
import windowsIcon from '../assets/OSystems/windows-dark.svg'; 
import iosIconLight from '../assets/OSystems/ios-light.svg';
import macosIconLight from '../assets/OSystems/macos-light.svg';
import gplayIconLight from '../assets/OSystems/gplay-light.svg';
import windowsIconLight from '../assets/OSystems/windows-light.svg';
import LoadingOverlay from './LoadingOverlay'; // Import the LoadingOverlay component
import { CSSTransition } from 'react-transition-group';

const genresList = [
  { name: 'Adventure', icon: Adventureicon, selectedIcon: AdventureSelected },
  { name: 'Battle Royale', icon: Battleroyalicon, selectedIcon: BattleroyalSelected },
  { name: 'Fighting', icon: Fightingicon, selectedIcon: FightingSelected },
  { name: 'FPS', icon: FPSicon, selectedIcon: FPSSelected }, 
  { name: 'Minigames', icon: Minigamesicon, selectedIcon: MinigamesSelected },
  { name: 'Racing', icon: Racingicon, selectedIcon: RacingSelected },
  { name: 'RPG', icon: RPGicon, selectedIcon: RPGSelected },
  { name: 'Strategy', icon: Strategyicon, selectedIcon: StrategySelected },
  { name: 'Survival', icon: Survivalicon, selectedIcon: SurvivalSelected },
];

const socialMediaList = [ 
  { name: 'instagram', icon: 'insta-dark.svg', selectedIcon: 'insta-light.svg' },
  { name: 'tiktok', icon: 'tiktok-dark.svg', selectedIcon: 'tiktok-light.svg' },
  { name: 'youtube', icon: 'youtube-dark.svg', selectedIcon: 'youtube-light.svg' },
  { name: 'x', icon: 'X-dark.svg', selectedIcon: 'x-light.svg' },
  { name: 'facebook', icon: 'facebook-dark.svg', selectedIcon: 'facebook-light.svg' },
  { name: "twitch", icon: "twitch-dark.svg", selectedIcon: "twitch-light.svg" },
  { name: "discord", icon: "discord-dark.svg", selectedIcon: "discord-light.svg" },
  { name: "telegram", icon: "telegram-dark.svg", selectedIcon: "telegram-light.svg" },
];

const isValidURL = (string) => {
    if (typeof string !== 'string') {
        return false; // Return false if the input is not a string
    }
    // Updated regex to allow "@" in the URL and ensure it starts with http or https
    const res = string.match(/^(https?:\/\/[^\s]+)$/);
    return (res !== null);
};

const db = getFirestore(); // Initialize Firestore

const StudioTypeform = ({ userId }) => { // Destructure userId from props
  const location = useLocation();
  const { userId: userIdFromState } = location.state || { userId: null };

  const [loading, setLoading] = useState(true); // State to manage loading overlay visibility
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [username, setUsername] = useState('');
  const [selectedRegion, setSelectedRegion] = useState(''); // State for selected region
  const [map, setMap] = useState(WorldMap); // State for the current map
  const [studioBio, setStudioBio] = useState('');
  const [studioResources, setStudioResources] = useState([]);
  const [isUsernameUnique, setIsUsernameUnique] = useState(true);
  const navigate = useNavigate();

  const logoRef = useRef(null);

  const [clickedCoordinates, setClickedCoordinates] = useState({ x: 0, y: 0 });
  const [studioProfilePicUploaded, setStudioProfilePicUploaded] = useState(false);
  const [studioProfileBannerUploaded, setStudioProfileBannerUploaded] = useState(false);
  const [game1ProfileBannerUploaded, setGame1ProfileBannerUploaded] = useState(false);
  const [game2ProfileBannerUploaded, setGame2ProfileBannerUploaded] = useState(false);
  const [game3ProfileBannerUploaded, setGame3ProfileBannerUploaded] = useState(false);
  const [game4ProfileBannerUploaded, setGame4ProfileBannerUploaded] = useState(false);
  const [game1ProfilePicUploaded, setGame1ProfilePicUploaded] = useState(false);
  const [game2ProfilePicUploaded, setGame2ProfilePicUploaded] = useState(false);
  const [game3ProfilePicUploaded, setGame3ProfilePicUploaded] = useState(false);
  const [game4ProfilePicUploaded, setGame4ProfilePicUploaded] = useState(false);
  const [game1description, setGame1Description] = useState('');
  const [game2description, setGame2Description] = useState('');
  const [game3description, setGame3Description] = useState('');
  const [game4description, setGame4Description] = useState('');
  const [game1title, setgame1title] = useState('');
  const [game2title, setgame2title] = useState('');
  const [game3title, setgame3title] = useState('');
  const [game4title, setgame4title] = useState('');
  const [game1website, setgame1website] = useState('');
  const [game2website, setgame2website] = useState('');
  const [game3website, setgame3website] = useState('');
  const [game4website, setgame4website] = useState('');
  const [game1developmentStage, setgame1developmentStage] = useState('');
  const [game2developmentStage, setgame2developmentStage] = useState('');
  const [game3developmentStage, setgame3developmentStage] = useState('');
  const [game4developmentStage, setgame4developmentStage] = useState('');
  const [game1platforms, setGame1Platforms] = useState([]); // State for game1 platforms
  const [game2platforms, setGame2Platforms] = useState([]); // State for game2 platforms
  const [game3platforms, setGame3Platforms] = useState([]); // State for game3 platforms
  const [game4platforms, setGame4Platforms] = useState([]); // State for game4 platforms
  const [game1genres, setGame1Genres] = useState([]); // State for game1 genres
  const [game2genres, setGame2Genres] = useState([]); // State for game2 genres
  const [game3genres, setGame3Genres] = useState([]); // State for game3 genres
  const [game4genres, setGame4Genres] = useState([]); // State for game4 genres 
  const [selectedgame1Socials, setGame1SelectedSocials] = useState([]); // State for game1 selected socials
  const [selectedgame2Socials, setGame2SelectedSocials] = useState([]); // State for game2 selected socials
  const [selectedgame3Socials, setGame3SelectedSocials] = useState([]); // State for game3 selected socials
  const [selectedgame4Socials, setGame4SelectedSocials] = useState([]); // State for game4 selected socials
  const [game1socialUrls, setGame1SocialUrls] = useState({}); // State for game1 social URLs
  const [game2socialUrls, setGame2SocialUrls] = useState({}); // State for game2 social URLs
  const [game3socialUrls, setGame3SocialUrls] = useState({}); // State for game3 social URLs
  const [game4socialUrls, setGame4SocialUrls] = useState({}); // State for game4 social URLs
  const [game1news, setGame1News] = useState([]); // State to hold combined news data
  const [game2news, setGame2News] = useState(''); // Ensure this is initialized as a string
  const [game3news, setGame3News] = useState(''); // Ensure this is initialized as a string
  const [game4news, setGame4News] = useState(''); // Ensure this is initialized as a string
  const [game1profilePic, setGame1ProfilePic] = useState('');
  const [game2profilePic, setGame2ProfilePic] = useState('');
  const [game3profilePic, setGame3ProfilePic] = useState('');
  const [game4profilePic, setGame4ProfilePic] = useState('');
  const [game1profileBanner, setGame1ProfileBanner] = useState('');
  const [game2profileBanner, setGame2ProfileBanner] = useState('');
  const [game3profileBanner, setGame3ProfileBanner] = useState('');
  const [game4profileBanner, setGame4ProfileBanner] = useState('');
  const [game1news1pic, setGame1News1Pic] = useState('');
  const [game1news2pic, setGame1News2Pic] = useState('');
  const [game1news3pic, setGame1News3Pic] = useState('');
  const [game1news4pic, setGame1News4Pic] = useState(''); 
  const [game2news1pic, setGame2News1Pic] = useState('');
  const [game2news2pic, setGame2News2Pic] = useState('');
  const [game2news3pic, setGame2News3Pic] = useState('');
  const [game2news4pic, setGame2News4Pic] = useState('');
  const [game3news1pic, setGame3News1Pic] = useState('');
  const [game3news2pic, setGame3News2Pic] = useState('');
  const [game3news3pic, setGame3News3Pic] = useState('');
  const [game3news4pic, setGame3News4Pic] = useState('');
  const [game4news1pic, setGame4News1Pic] = useState('');
  const [game4news2pic, setGame4News2Pic] = useState('');
  const [game4news3pic, setGame4News3Pic] = useState('');
  const [game4news4pic, setGame4News4Pic] = useState('');

  const [game1news1picUploaded, setGame1News1PicUploaded] = useState(false);
  const [game1news2picUploaded, setGame1News2PicUploaded] = useState(false);
  const [game1news3picUploaded, setGame1News3PicUploaded] = useState(false);
  const [game1news4picUploaded, setGame1News4PicUploaded] = useState(false);
  const [game2news1picUploaded, setGame2News1PicUploaded] = useState(false);
  const [game2news2picUploaded, setGame2News2PicUploaded] = useState(false);
  const [game2news3picUploaded, setGame2News3PicUploaded] = useState(false);
  const [game2news4picUploaded, setGame2News4PicUploaded] = useState(false);
  const [game3news1picUploaded, setGame3News1PicUploaded] = useState(false);
  const [game3news2picUploaded, setGame3News2PicUploaded] = useState(false);
  const [game3news3picUploaded, setGame3News3PicUploaded] = useState(false);
  const [game3news4picUploaded, setGame3News4PicUploaded] = useState(false);
  const [game4news1picUploaded, setGame4News1PicUploaded] = useState(false);
  const [game4news2picUploaded, setGame4News2PicUploaded] = useState(false);
  const [game4news3picUploaded, setGame4News3PicUploaded] = useState(false);
  const [game4news4picUploaded, setGame4News4PicUploaded] = useState(false);
  const [newsUploadCount, setNewsUploadCount] = useState(1); // Start with one news upload


  const [linkCount, setLinkCount] = useState(1); // State to manage the number of link inputs

  const [addAnotherGame, setAddAnotherGame] = useState(null); // State to manage the final question response

  const [game1content, setGame1Content] = useState([]); // State to hold uploaded game content URLs
  const [game2content, setGame2Content] = useState([]); // State to hold uploaded game content URLs
  const [game3content, setGame3Content] = useState([]); // State to hold uploaded game content URLs
  const [game4content, setGame4Content] = useState([]); // State to hold uploaded game content URLs
  const [game1Content1Uploaded, setGame1Content1Uploaded] = useState(false);
  const [game1Content2Uploaded, setGame1Content2Uploaded] = useState(false);
  const [game1Content3Uploaded, setGame1Content3Uploaded] = useState(false);
  const [game1Content4Uploaded, setGame1Content4Uploaded] = useState(false);
  const [game1Content5Uploaded, setGame1Content5Uploaded] = useState(false);
  const [game2Content1Uploaded, setGame2Content1Uploaded] = useState(false);
  const [game2Content2Uploaded, setGame2Content2Uploaded] = useState(false);
  const [game2Content3Uploaded, setGame2Content3Uploaded] = useState(false);
  const [game2Content4Uploaded, setGame2Content4Uploaded] = useState(false);
  const [game2Content5Uploaded, setGame2Content5Uploaded] = useState(false);
  const [game3Content1Uploaded, setGame3Content1Uploaded] = useState(false);
  const [game3Content2Uploaded, setGame3Content2Uploaded] = useState(false);
  const [game3Content3Uploaded, setGame3Content3Uploaded] = useState(false);
  const [game3Content4Uploaded, setGame3Content4Uploaded] = useState(false);
  const [game3Content5Uploaded, setGame3Content5Uploaded] = useState(false);
  const [game4Content1Uploaded, setGame4Content1Uploaded] = useState(false);
  const [game4Content2Uploaded, setGame4Content2Uploaded] = useState(false);
  const [game4Content3Uploaded, setGame4Content3Uploaded] = useState(false);
  const [game4Content4Uploaded, setGame4Content4Uploaded] = useState(false);
  const [game4Content5Uploaded, setGame4Content5Uploaded] = useState(false);



  const [hoveredDecision, setHoveredDecision] = useState(null); // State for hovered decision
  const [hoveredPlatform, setHoveredPlatform] = useState(null);
  const [inTransition, setInTransition] = useState(false);

  const [game1news1link, setGame1News1Link] = useState(''); 
  const [game1news2link, setGame1News2Link] = useState('');
  const [game1news3link, setGame1News3Link] = useState('');
  const [game1news4link, setGame1News4Link] = useState('');
  const [game2news1link, setGame2News1Link] = useState(''); 
  const [game2news2link, setGame2News2Link] = useState('');
  const [game2news3link, setGame2News3Link] = useState('');
  const [game2news4link, setGame2News4Link] = useState('');
  const [game3news1link, setGame3News1Link] = useState('');
  const [game3news2link, setGame3News2Link] = useState('');
  const [game3news3link, setGame3News3Link] = useState('');
  const [game3news4link, setGame3News4Link] = useState('');
  const [game4news1link, setGame4News1Link] = useState('');
  const [game4news2link, setGame4News2Link] = useState('');
  const [game4news3link, setGame4News3Link] = useState('');
  const [game4news4link, setGame4News4Link] = useState('');

  const [showUploads, setShowUploads] = useState(false); // New state to control visibility

  const [userData, setUserData] = useState(null); // State to hold user data

  const handleOverlayClick = () => {
    setLoading(false); // Hide the loading overlay on click
  };

  useEffect(() => {
    if (window.self !== window.top) {
      window.top.location.href = window.location.href;
    }
  }, []);

  const handleRegionChange = (event) => {
    const region = event.target.value;
    setSelectedRegion(region);

    // Update the map based on the selected region
    switch (region) {
      case 'NA':
        setMap(WorldNA);
        break;
      case 'EU':
        setMap(WorldEU); // Add the corresponding SVG for Europe
        break;
      case 'EAS':
        setMap(WorldEAS); // Add the corresponding SVG for East Asia
        break;
      case 'SA':
        setMap(WorldSA); // Add the corresponding SVG for South America
        break;
      case 'AU':
        setMap(WorldAU); // Add the corresponding SVG for Australia and PI
        break;
      case 'CAS':
        setMap(WorldCAS); // Add the corresponding SVG for Central Asia
        break;
      case 'ME':
        setMap(WorldME); // Add the corresponding SVG for Middle East
        break;
      case 'NAF':
        setMap(WorldNAF); // Add the corresponding SVG for North Africa
        break;
      case 'RU':
        setMap(WorldRU); // Add the corresponding SVG for Russia
        break;
      case 'SAF':
        setMap(WorldSAF); // Add the corresponding SVG for Sub-Saharan Africa
        break;
      case 'SAS':
        setMap(WorldSAS); // Add the corresponding SVG for South Asia
        break;
      case 'SEA':
        setMap(WorldSEA); // Add the corresponding SVG for South-East Asia
        break;
      case 'CA':
        setMap(WorldCA); // Add the corresponding SVG for Central America
        break;
      default:
        setMap(WorldMap);
    }
  };

  useEffect(() => {
    const checkUsernameUnique = async () => {
      if (currentQuestion === 0 && username) { // Only check if it's the username question
        const snapshot = await getDoc(doc(db, 'users', userIdFromState)); // Use userIdFromState to fetch user data
        if (snapshot.exists()) {
            const userData = snapshot.data(); // Get the data from the snapshot
            const existingNames = Object.values(userData)
              .map(user => user.username ? user.username.toLowerCase() : null) // Check if username exists
              .filter(username => username !== null); // Filter out null values
            setIsUsernameUnique(!existingNames.includes(username.toLowerCase())); // Convert input username to lowercase
        } else {
            console.error('No user data found for user ID:', userIdFromState);
        }
      } else {
        setIsUsernameUnique(true);
      }
    };

    const handler = setTimeout(() => {
      checkUsernameUnique();
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [username, currentQuestion, userIdFromState]); // Add currentQuestion and userIdFromState to the dependency array

  useEffect(() => {
    // Scroll to the logo when the current question changes
    if (currentQuestion >= 0 && logoRef.current) {
      const logoPosition = logoRef.current.getBoundingClientRect().top + window.scrollY;
      const offset = 50; // Adjust this value to set how high above the logo you want to scroll
      window.scrollTo({
        top: logoPosition - offset,
        behavior: 'smooth'
      });
    }
  }, [currentQuestion]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (userIdFromState) {
        const userDoc = await getDoc(doc(db, 'users', userIdFromState));
        if (userDoc.exists()) {
          const data = userDoc.data(); // Get the data from the document
          setUserData(data); // Set the user data in state
          console.log('User data fetched successfully:', data); // Log the fetched user data
        } else {
          console.error('No user data found for user ID:', userIdFromState);
        }
      }
    };

    fetchUserData();
  }, [userIdFromState]); // Fetch user data when userIdFromState changes

  const questions = [
    { question: "Your studio's username", answer: username, setAnswer: setUsername },
    { question: "Where is your studio based?", answer: selectedRegion, setAnswer: setSelectedRegion },
    { question: "Tell us more about your studio.", answer: studioBio, setAnswer: setStudioBio, titleClass: 'studio-bio-title' },
    { question: "Let's add your first game title!", answer: '', setAnswer: () => {} },
    { question: "What is your game's genre?", answer: game1genres, setAnswer: setGame1Genres },
    { question: "Where do you post updates?", answer: game1socialUrls, setAnswer: setGame1SocialUrls },
    { question: "Share your recent news", answer: game1news, setAnswer: setGame1News },
    { question: "Let's finish your game profile!", answer: '', setAnswer: () => {} },
    { question: "Do you want to add another game title?", answer: addAnotherGame, setAnswer: setAddAnotherGame },
    { question: "Let's add your second game title!", answer: '', setAnswer: () => {} },
    { question: "What is your game's genre?", answer: game2genres, setAnswer: setGame2Genres },
    { question: "Where do you post updates?", answer: game2socialUrls, setAnswer: setGame2SocialUrls },
    { question: "Share your recent news", answer: game2news, setAnswer: setGame2News },
    { question: "Let's finish your game profile!", answer: '', setAnswer: () => {} },
    { question: "Do you want to add another game title?", answer: addAnotherGame, setAnswer: setAddAnotherGame },
    { question: "Let's add your third game title!", answer: '', setAnswer: () => {} },
    { question: "What is your game's genre?", answer: game3genres, setAnswer: setGame3Genres },
    { question: "Where do you post updates?", answer: game3socialUrls, setAnswer: setGame3SocialUrls },
    { question: "Share your recent news", answer: game3news, setAnswer: setGame3News },
    { question: "Let's finish your game profile!", answer: '', setAnswer: () => {} },
    { question: "Do you want to add another game title?", answer: addAnotherGame, setAnswer: setAddAnotherGame },
    { question: "Let's add your final game title!", answer: '', setAnswer: () => {} },
    { question: "What is your game's genre?", answer: game4genres, setAnswer: setGame4Genres },
    { question: "Where do you post updates?", answer: game4socialUrls, setAnswer: setGame4SocialUrls },
    { question: "Share your recent news", answer: game4news, setAnswer: setGame4News },
    { question: "Let's finish your game profile!", answer: '', setAnswer: () => {} },
    { question: "Do you want to add another game title?", answer: addAnotherGame, setAnswer: setAddAnotherGame },
  ];

  const validateData = () => {
    // Check for empty fields
    if (currentQuestion === 0 && !username) {
        alert("Please fill in your username.");
        return false;
    }
    if (currentQuestion === 0 && !isUsernameUnique) {
        alert("Username must be unique.");
        return false;
    }
    if (currentQuestion === 1 && !selectedRegion) {
        alert("Please select a region.");
        return false;
    }
    if (currentQuestion === 2 && !studioBio) {
        alert("Please tell us more about your studio.");
        return false;
    }
    
    // Check for empty link inputs
    if (currentQuestion === 2) {
      for (let i = 0; i < linkCount; i++) {
        const linkValue = studioResources[i] || ''; // Get the link value or empty string
        if (!linkValue.trim()) { // Check if the link is empty
          alert('Please fill out all resource links.');
          return false;
        }
      }
    }

    if (currentQuestion === 2 && !studioProfilePicUploaded) {
      alert("Please add a profile picture."); 
      return false;
  }

  if (currentQuestion === 2 && !studioProfileBannerUploaded) {
    alert("Please add a profile banner.");
    return false;
  }

  if (currentQuestion === 2 && !handleLinkChange) {
    alert("Please add at least one resource link.")
    return false;
  } 

  // Check for genres in questions 4, 10, 16, and 22
  if ((currentQuestion === 4 || currentQuestion === 10 || currentQuestion === 16 || currentQuestion === 22) && game1genres.length === 0) {
    alert("Please select at least one genre.");
    return false;
  }

  // Check for platforms in questions 3, 8, 13, and 18
  if (currentQuestion === 3 && game1platforms.length === 0) {
    alert("Please select at least one platform for your first game.");
    return false;
  }

  if (currentQuestion === 9 && game2platforms.length === 0) {
    alert("Please select at least one platform for your second game.");
    return false;
  }

  if (currentQuestion === 15 && game3platforms.length === 0) {
    alert("Please select at least one platform for your third game.");
    return false;
  }

  if (currentQuestion === 21 && game4platforms.length === 0) {
    alert("Please select at least one platform for your final game.");
    return false;
  }

  // Check for selected social media platforms and their URLs for questions 5, 11, 17, and 22
  const socialQuestions = [5, 11, 17, 23];
  if (socialQuestions.includes(currentQuestion)) {
      const selectedSocials = currentQuestion === 5 ? selectedgame1Socials :
                              currentQuestion === 11 ? selectedgame2Socials :
                              currentQuestion === 17 ? selectedgame3Socials :
                              currentQuestion === 23 ? selectedgame4Socials : [];

      if (selectedSocials.length === 0) {
          alert("Please select at least one social media platform.");
          return false;
      }

      for (const social of selectedSocials) {
          const inputElement = document.querySelector(`input[placeholder="${social} URL"]`);
          if (inputElement) {
              const url = inputElement.value.trim();
              if (!url) {
                  alert(`Please enter a URL for ${social}.`);
                  return false;
              }
              if (!isValidURL(url)) {
                  alert(`Please enter a valid URL for ${social}.`);
                  return false;
              }
          } 
      }
  }

  // Validation for question 6
  if (currentQuestion === 7) {
    if (!game1description.trim()) {
      alert("Please provide a description of your game.");
      return false;
    }
    if (!game1ProfilePicUploaded) {
      alert("Please upload a game profile picture.");
      return false;
    }
    if (!game1ProfileBannerUploaded) {
      alert("Please upload a game profile banner.");
      return false;
    }
    // Check if at least one content upload handler has been triggered
    if (!game1Content1Uploaded && !game1Content2Uploaded && !game1Content3Uploaded && !game1Content4Uploaded && !game1Content5Uploaded) {
      alert("Please upload at least one featured content.");
      return false;
    }
  }

  // Validation for question 11
  if (currentQuestion === 13) {
    if (!game2description.trim()) {
      alert("Please provide a description of your game.");
      return false;
    }
    if (!game2ProfilePicUploaded) {
      alert("Please upload a game profile picture.");
      return false;
    }
    if (!game2ProfileBannerUploaded) {
      alert("Please upload a game profile banner.");
      return false;
    }
    // Check if at least one content upload handler has been triggered
    if (!game2Content1Uploaded && !game2Content2Uploaded && !game2Content3Uploaded && !game2Content4Uploaded && !game2Content5Uploaded) {
      alert("Please upload at least one featured content.");
      return false;
    }
  }

  // Validation for question 16
  if (currentQuestion === 19) {
    if (!game3description.trim()) {
      alert("Please provide a description of your game.");
      return false;
    }
    if (!game3ProfilePicUploaded) {
      alert("Please upload a game profile picture.");
      return false;
    }
    if (!game3ProfileBannerUploaded) {
      alert("Please upload a game profile banner.");
      return false;
    }
    // Check if at least one content upload handler has been triggered
    if (!game3Content1Uploaded && !game3Content2Uploaded && !game3Content3Uploaded && !game3Content4Uploaded && !game3Content5Uploaded) {
      alert("Please upload at least one featured content.");
      return false;
    }

  }

  // Validation for question 21
  if (currentQuestion === 25) {
    if (!game4description.trim()) {
      alert("Please provide a description of your game.");
      return false;
    }
    if (!game4ProfilePicUploaded) {
      alert("Please upload a game profile picture.");
      return false;
    }
    if (!game4ProfileBannerUploaded) {
      alert("Please upload a game profile banner.");
      return false;
    }
    // Check if at least one content upload handler has been triggered
    if (!game4Content1Uploaded && !game4Content2Uploaded && !game4Content3Uploaded && !game4Content4Uploaded && !game4Content5Uploaded) {
      alert("Please upload at least one featured content.");
      return false;
    }
  }

    // Add more validations as needed for other questions
    return true; // All validations passed
  };

  const handleNext = async (e) => {
    e.preventDefault();
    
    // Validate data before proceeding
    if (!validateData()) return;

    // Validate URL only when navigating to specific questions
    if (currentQuestion === 3) {
        if (!game1title || !game1website || !game1developmentStage || !game1platforms) {
            alert("Please fill out all fields for your first game title.");
            return; // Stop the function if validation fails
        }
        if (!isValidURL(game1website)) {
            alert("Please enter a valid URL for the game website.");
            return; // Stop the function if validation fails
        }
    }

    if (currentQuestion === 9) {
        if (!game2title || !game2website || !game2developmentStage || !game2platforms) {
            alert("Please fill out all fields for your second game title.");
            return; // Stop the function if validation fails
        }
        if (!isValidURL(game2website)) {
            alert("Please enter a valid URL for the game website.");
            return; // Stop the function if validation fails
        }
    }

    if (currentQuestion === 15) {
        if (!game3title || !game3website || !game3developmentStage || !game3platforms) {
            alert("Please fill out all fields for your third game title.");
            return; // Stop the function if validation fails
        }
        if (!isValidURL(game3website)) {
            alert("Please enter a valid URL for the game website.");
            return; // Stop the function if validation fails
        }
    }

    if (currentQuestion === 21) {
        if (!game4title || !game4website || !game4developmentStage || !game4platforms) {
            alert("Please fill out all fields for your final game title.");
            return; // Stop the function if validation fails
        }
        if (!isValidURL(game4website)) {
            alert("Please enter a valid URL for the game website.");
            return; // Stop the function if validation fails
        }
    }

    setInTransition(true);
    setTimeout(() => {
        setCurrentQuestion((prev) => Math.min(prev + 1, questions.length - 1));
        setInTransition(false);
    }, 500);
};

const handleBack = () => {
    if (currentQuestion > 0) {
        setCurrentQuestion(currentQuestion - 1);
    }
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Track form completion
    setLoading(true);

    try {
        // Ensure userData is available before proceeding
        if (!userData) {
          alert('User data is not available.');
          return;
        }

        // Fetch user data here if not already available
        const updates = {
            location: selectedRegion,
            studioBio,
            studioProfilePic: studioProfilePicUploaded || '',
            studioProfileBanner: studioProfileBannerUploaded || '',
            studioResources: Array.isArray(studioResources) ? studioResources.join(',') : '', // Ensure it's an array before joining
            username, // Use existing username
            email: userData.email, // Use the fetched email
            userType: 'studio', // Use the fetched user type
            name: userData.name, // Use the fetched name
            signedUp: true, // Set signedUp to true
            shared: false,
            game1: {
                game1title,
                game1website,
                game1platforms,
                game1developmentStage,
                game1genres,
                game1socialUrls,
                game1description,
                game1news: {
                    ...(game1news1pic && { game1news1pic }),
                    ...(game1news1link && { game1news1link }),
                    ...(game1news2pic && { game1news2pic }),
                    ...(game1news2link && { game1news2link }),
                    ...(game1news3pic && { game1news3pic }),
                    ...(game1news3link && { game1news3link }),
                    ...(game1news4pic && { game1news4pic }),
                    ...(game1news4link && { game1news4link }),
                },
                game1profilePic,
                game1profileBanner,
                game1content,
            },
            ...(game2title && {
                game2: {
                    game2title,
                    game2website,
                    game2platforms,
                    game2developmentStage,
                    game2genres,
                    game2socialUrls,
                    game2description,
                    game2news: {
                        ...(game2news1pic && { game2news1pic }),
                        ...(game2news1link && { game2news1link }),
                        ...(game2news2pic && { game2news2pic }),
                        ...(game2news2link && { game2news2link }),
                        ...(game2news3pic && { game2news3pic }),
                        ...(game2news3link && { game2news3link }),
                        ...(game2news4pic && { game2news4pic }),
                        ...(game2news4link && { game2news4link }),
                    },
                    game2profilePic,
                    game2profileBanner,
                    game2content,
                },
            }),
            ...(game3title && {
                game3: {
                    game3title,
                    game3website,
                    game3platforms,
                    game3developmentStage,
                    game3genres,
                    game3socialUrls,
                    game3description,
                    game3news: {
                        ...(game3news1pic && { game3news1pic }),
                        ...(game3news1link && { game3news1link }),
                        ...(game3news2pic && { game3news2pic }),
                        ...(game3news2link && { game3news2link }),
                        ...(game3news3pic && { game3news3pic }),
                        ...(game3news3link && { game3news3link }),
                        ...(game3news4pic && { game3news4pic }),
                        ...(game3news4link && { game3news4link }),
                    },
                    game3profilePic,
                    game3profileBanner,
                    game3content,
                },
            }),
            ...(game4title && {
                game4: {
                    game4title,
                    game4website,
                    game4platforms,
                    game4developmentStage,
                    game4genres,
                    game4socialUrls,
                    game4description,
                    game4news: {
                        ...(game4news1pic && { game4news1pic }),
                        ...(game4news1link && { game4news1link }),
                        ...(game4news2pic && { game4news2pic }),
                        ...(game4news2link && { game4news2link }),
                        ...(game4news3pic && { game4news3pic }),
                        ...(game4news3link && { game4news3link }),
                        ...(game4news4pic && { game4news4pic }),
                        ...(game4news4link && { game4news4link }),
                    },
                    game4profilePic,
                    game4profileBanner,
                    game4content,
                },
            }),
        };

        const userRef = doc(db, 'users', userIdFromState);
        await setDoc(userRef, updates, {
            merge: true, // Use merge to avoid overwriting existing fields
        });

        console.log('User data updated successfully:', updates);
        navigate(`/${userIdFromState}`);
    } catch (error) {
        console.error('Error updating user data:', error);
        alert('Failed to update user data: ' + error.message);
    } finally {
        setLoading(false);
    }
};

  // Calculate progress directly
  const progress = (() => {
    if (currentQuestion >= 0 && currentQuestion <= 8) {
      return (((currentQuestion + 1) / 9) * 100); // Questions 1-7 are 100%
    } else if (currentQuestion >= 9 && currentQuestion <= 14) {
      return (((currentQuestion + 1) / 15) * 100); // Questions 8-13 are also 100%
    } else if (currentQuestion >= 15 && currentQuestion <= 20) {
      return (((currentQuestion + 1) / 21) * 100); // Questions 14-19 are also 100%
    } else if (currentQuestion >= 21 && currentQuestion <= 25) {
      return (((currentQuestion + 1) / 26) * 100); // Questions 20-24 are also 100%
    }
    return 0; // Default case
  })();

  const handleDisplayNameChange = (e) => {
    const value = e.target.value.replace(/\s+/g, ''); // Remove spaces
    setUsername(value);
    questions[currentQuestion].setAnswer(value); // Update the answer in the questions array
  };


  const handleLinkChange = (e, index) => {
    const { value } = e.target; // Get the value from the input
    const updatedLinks = [...studioResources]; // Create a copy of the current links
    updatedLinks[index] = value; // Update the specific link at the given index
    setStudioResources(updatedLinks); // Update the state with the new links
    console.log(`Resource link ${index + 1} set to:`, value); // Log the change
};



const handleGame1SocialSelect = (socialName) => {
    setGame1SelectedSocials((prevSelected) => {
      let updatedGame1Selected = [...prevSelected]; // Create a shallow copy of the previous state
        if (!Array.isArray(updatedGame1Selected)) {
          updatedGame1Selected = []; // Ensure the game index is initialized as an array
        }
        if (updatedGame1Selected.includes(socialName)) {
          // If the social is already selected, remove it
          updatedGame1Selected = updatedGame1Selected.filter((name) => name !== socialName);
        } else {
          // If the social is not selected, add it
          updatedGame1Selected.push(socialName);
        }
        return updatedGame1Selected;
    });
  };

  const handleGame2SocialSelect = (socialName) => {
    setGame2SelectedSocials((prevSelected) => {
      let updatedGame2Selected = [...prevSelected]; // Create a shallow copy of the previous state
        if (!Array.isArray(updatedGame2Selected)) {
          updatedGame2Selected = []; // Ensure the game index is initialized as an array
        }
        if (updatedGame2Selected.includes(socialName)) {
          // If the social is already selected, remove it
          updatedGame2Selected = updatedGame2Selected.filter((name) => name !== socialName);
        } else {
          // If the social is not selected, add it
          updatedGame2Selected.push(socialName);
        }
        return updatedGame2Selected;
    });
  };

  const handleGame3SocialSelect = (socialName) => { 
    setGame3SelectedSocials((prevSelected) => {
      let updatedGame3Selected = [...prevSelected]; // Create a shallow copy of the previous state
        if (!Array.isArray(updatedGame3Selected)) {
          updatedGame3Selected = []; // Ensure the game index is initialized as an array
        }
        if (updatedGame3Selected.includes(socialName)) {
          // If the social is already selected, remove it
          updatedGame3Selected = updatedGame3Selected.filter((name) => name !== socialName);
        } else {
          // If the social is not selected, add it
          updatedGame3Selected.push(socialName);
        }
        return updatedGame3Selected;
    });
  };

  const handleGame4SocialSelect = (socialName) => {
    setGame4SelectedSocials((prevSelected) => {
        let updatedGame4Selected = [...prevSelected]; // Use let instead of const
        if (!Array.isArray(updatedGame4Selected)) {
            updatedGame4Selected = []; // Ensure the game index is initialized as an array
        }
        if (updatedGame4Selected.includes(socialName)) {
            // If the social is already selected, remove it
            updatedGame4Selected = updatedGame4Selected.filter((name) => name !== socialName);
        } else {
            // If the social is not selected, add it
            updatedGame4Selected.push(socialName);
        }
        return updatedGame4Selected;
    });
  };

  const handleGame1SocialsChange = (e, social) => {
    const { value } = e.target;
    setGame1SocialUrls((prev) => ({
      ...prev,
      [social]: value, // Update the URL for the specific social platform
    }));
  };

  const handleGame2SocialsChange = (e, social) => {
    const { value } = e.target;
    setGame2SocialUrls((prev) => ({
      ...prev,
      [social]: value, // Update the URL for the specific social platform
    }));
  };

  const handleGame3SocialsChange = (e, social) => {
    const { value } = e.target;
    setGame3SocialUrls((prev) => ({
      ...prev,
      [social]: value, // Update the URL for the specific social platform
    }));
  };

  const handleGame4SocialsChange = (e, social) => {
    const { value } = e.target;
    setGame4SocialUrls((prev) => ({
      ...prev,
      [social]: value, // Update the URL for the specific social platform
    }));
  };

  const handleMapClick = (event) => {
    const { offsetX, offsetY } = event.nativeEvent;

    // Log the coordinates to the console
    console.log(`Clicked coordinates: X: ${offsetX}, Y: ${offsetY}`);

    // username coordinates on the screen (optional)
    setClickedCoordinates({ x: offsetX, y: offsetY });

    // Example coordinates for regions (you will need to adjust these)
    if (isInRegion(offsetX, offsetY, 'NA')) {
      setMap(WorldNA);
      setSelectedRegion('NA');
    } else if (isInRegion(offsetX, offsetY, 'EU')) {
      setMap(WorldEU);
      setSelectedRegion('EU');
    } else if (isInRegion(offsetX, offsetY, 'EAS')) {
      setMap(WorldEAS);
      setSelectedRegion('EAS');
    } else if (isInRegion(offsetX, offsetY, 'SA')) {
      setMap(WorldSA);
      setSelectedRegion('SA');
    } else if (isInRegion(offsetX, offsetY, 'AU')) {
      setMap(WorldAU);
      setSelectedRegion('AU');
    } else if (isInRegion(offsetX, offsetY, 'CAS')) {
      setMap(WorldCAS);
      setSelectedRegion('CAS');
    } else if (isInRegion(offsetX, offsetY, 'ME')) {
      setMap(WorldME);
      setSelectedRegion('ME');
    } else if (isInRegion(offsetX, offsetY, 'NAF')) {
      setMap(WorldNAF);
      setSelectedRegion('NAF');
    } else if (isInRegion(offsetX, offsetY, 'RU')) {
      setMap(WorldRU);
      setSelectedRegion('RU');
    } else if (isInRegion(offsetX, offsetY, 'SAF')) {
      setMap(WorldSAF);
      setSelectedRegion('SAF');
    } else if (isInRegion(offsetX, offsetY, 'SAS')) {
      setMap(WorldSAS);
      setSelectedRegion('SAS');
    } else if (isInRegion(offsetX, offsetY, 'SEA')) {
      setMap(WorldSEA);
      setSelectedRegion('SEA');
    } else if (isInRegion(offsetX, offsetY, 'CA')) {
      setMap(WorldCA);
      setSelectedRegion('CA');
    }
  };

  const isInRegion = (x, y, region) => {
    const isMobile = window.innerWidth <= 480; // Mobile breakpoint
    const isTablet = window.innerWidth >= 1450; // Tablet breakpoint

    // Define the clickable areas for each region based on coordinates
    switch (region) {
      case 'NA': // North America
        if (isMobile) {
          return x > 9 && x < 147 && y > 45 && y < 165; // Mobile coordinates
        } else if (isTablet) {
          return x > 203 && x < 503 && y > 11 && y < 259; // Tablet coordinates
        } else {
          return x > 196 && x < 411 && y > 15 && y < 193; // Desktop coordinates
        }
      case 'EU': // Europe
        if (isMobile) {
          return x > 157 && x < 222 && y > 55 && y < 152; // Mobile coordinates
        } else if (isTablet) {
          return x > 513 && x < 639 && y > 59 && y < 238; // Tablet coordinates
        } else {
          return x > 418 && x < 502 && y > 35 && y < 180; // Desktop coordinates
        }
      case 'EAS': // East Asia
        if (isMobile) {
          return x > 250 && x < 302 && y > 144 && y < 172; // Mobile coordinates
        } else if (isTablet) {
          return x > 695 && x < 797 && y > 221 && y < 275; // Tablet coordinates
        } else {
          return x > 546 && x < 617 && y > 161 && y < 204; // Desktop coordinates
        }
      case 'SA': // South America
        if (isMobile) {
          return x > 84 && x < 139 && y > 192 && y < 260; // Mobile coordinates
        } else if (isTablet) {
          return x > 376 && x < 482 && y > 304 && y < 445; // Tablet coordinates
        } else {
          return x > 311 && x < 381 && y > 222 && y < 325; // Desktop coordinates
        }
      case 'AU': // Australia
        if (isMobile) {
          return x > 271 && x < 339 && y > 208 && y <248; // Mobile coordinates
        } else if (isTablet) {
          return x > 740 && x < 866 && y > 328 && y < 421; // Tablet coordinates
        } else {
          return x > 575 && x < 666 && y > 251 && y < 311; // Desktop coordinates
        }
      case 'CAS': // Central Asia
        if (isMobile) {
          return x > 215 && x < 249 && y > 148 && y < 169; // Mobile coordinates
        } else if (isTablet) {
          return x > 626 && x < 697 && y > 223 && y < 267; // Tablet coordinates
        } else {
          return x > 496 && x < 548 && y > 168 && y < 195; // Desktop coordinates
        }
      case 'ME': // Middle East
        if (isMobile) {
          return x > 197 && x < 226 && y > 164 && y < 188; // Mobile coordinates
        } else if (isTablet) {
          return x > 595 && x < 640 && y > 255 && y < 302; // Tablet coordinates
        } else {
          return x > 476 && x < 505 && y > 191 && y < 221; // Desktop coordinates
        }
      case 'NAF': // North Africa
        if (isMobile) {
          return x > 147 && x < 205 && y > 160 && y < 184; // Mobile coordinates
        } else if (isTablet) {
          return x > 497 && x < 602 && y > 251 && y < 294; // Tablet coordinates
        } else {
          return x > 401 && x < 473 && y > 184 && y < 220; // Desktop coordinates
        }
      case 'RU': // Russia
        if (isMobile) {
          return x > 230 && x < 351 && y > 59 && y < 150; // Mobile coordinates
        } else if (isTablet) {
          return x > 654 && x < 889 && y > 54 && y < 233; // Tablet coordinates
        } else {
          return x > 515 && x < 688 && y > 41 && y < 173; // Desktop coordinates
        }
      case 'SAF': // Sub-Saharan Africa
        if (isMobile) {
          return x > 149 && x < 219 && y > 185 && y < 231; // Mobile coordinates
        } else if (isTablet) {
          return x > 500 && x < 623 && y > 297 && y < 388; // Tablet coordinates
        } else {
          return x > 405 && x < 494 && y > 220 && y < 286; // Desktop coordinates
        }
      case 'SAS': // South Asia
        if (isMobile) {
          return x > 234 && x < 252 && y > 166 && y < 192; // Mobile coordinates
        } else if (isTablet) {
          return x > 664 && x < 699 && y > 262 && y < 306; // Tablet coordinates
        } else {
          return x > 523 && x < 549 && y > 193 && y < 229; // Desktop coordinates
        }
      case 'SEA': // South-East Asia
        if (isMobile) {
          return x > 253 && x < 303 && y > 171 && y < 202; // Mobile coordinates
        } else if (isTablet) {
          return x > 696 && x < 803 && y > 273 && y < 330; // Tablet coordinates
        } else {
          return x > 549 && x < 621 && y > 201 && y < 243; // Desktop coordinates
        }
      case 'CA': // Central America
        if (isMobile) {
          return x > 40 && x < 167 && y > 166 && y < 185; // Mobile coordinates
        } else if (isTablet) {
          return x > 317 && x < 410 && y > 260 && y < 298; // Tablet coordinates
        } else {
          return x > 267 && x < 335 && y > 192 && y < 221; // Desktop coordinates
        }
      default:
        return false;
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      await setPersistence(auth, browserSessionPersistence); // Clear persistence
      navigate('/login'); // Redirect to login page after logout
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const storage = getStorage(); // Initialize Firebase Storage

  const handleStudioProfilePicUpload = async (event) => {
    const file = event.target.files[0]; // Get the first file directly
    if (file) {
        const storageReference = storageRef(storage, `studioProfilePics/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            await setDoc(doc(db, 'users', userIdFromState), { studioProfilePic: downloadURL }); // Store the URL in Firestore
            setStudioProfilePicUploaded(downloadURL); // Store the URL in state
            console.log('Studio profile picture uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading studio profile picture:', error);
        }
    } else {
        console.error('No file selected for upload.');
    }
};

  const handleGame1ProfilePicUpload = async (event) => {
    const file = event.target.files[0]; // Get the first file
    if (file) {
        const storageReference = storageRef(storage, `gameProfilePics/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame1ProfilePic(downloadURL); // Store the URL in state
            console.log('Game profile picture uploaded successfully:', downloadURL);
            setGame1ProfilePicUploaded(true); // Update the state to indicate upload success
        } catch (error) {
            console.error('Error uploading game profile picture:', error);
        }
    }
  };

  const handleGame2ProfilePicUpload = async (event) => {
    const file = event.target.files[0]; // Get the first file
    if (file) {
        const storageReference = storageRef(storage, `gameProfilePics/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame2ProfilePic(downloadURL); // Store the URL in state
            console.log('Game profile picture uploaded successfully:', downloadURL);
            setGame2ProfilePicUploaded(true); // Update the state to indicate upload success
        } catch (error) {
            console.error('Error uploading game profile picture:', error);
        }
    }
  };

  const handleGame3ProfilePicUpload = async (event) => {
    const file = event.target.files[0]; // Get the first file
    if (file) {
        const storageReference = storageRef(storage, `gameProfilePics/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame3ProfilePic(downloadURL); // Store the URL in state
            console.log('Game profile picture uploaded successfully:', downloadURL);
            setGame3ProfilePicUploaded(true); // Update the state to indicate upload success
        } catch (error) {
            console.error('Error uploading game profile picture:', error);
        }
    }
  };

  const handleGame4ProfilePicUpload = async (event) => {
    const file = event.target.files[0]; // Get the first file
    if (file) {
        const storageReference = storageRef(storage, `gameProfilePics/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame4ProfilePic(downloadURL); // Store the URL in state
            console.log('Game profile picture uploaded successfully:', downloadURL);
            setGame4ProfilePicUploaded(true); // Update the state to indicate upload success
        } catch (error) {
            console.error('Error uploading game profile picture:', error);
        }
    }
  };

  const handleGame1ProfileBannerUpload = async (event) => {
    const file = event.target.files;
    if (file) {
        const storageReference = storageRef(storage, `gameProfileBanners/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame1ProfileBanner(downloadURL); // Store the URL in state
            console.log('Game profile banner uploaded successfully:', downloadURL);
            setGame1ProfileBannerUploaded(true); // Update the state to indicate upload success
        } catch (error) {
            console.error('Error uploading game profile banner:', error);
        }
    }
  };

  const handleGame2ProfileBannerUpload = async (event) => {
    const file = event.target.files;
    if (file) {
        const storageReference = storageRef(storage, `gameProfileBanners/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame2ProfileBanner(downloadURL); // Store the URL in state
            console.log('Game profile banner uploaded successfully:', downloadURL);
            setGame2ProfileBannerUploaded(true); // Update the state to indicate upload success
        } catch (error) {
            console.error('Error uploading game profile banner:', error);
        }
    }
  };

  const handleGame3ProfileBannerUpload = async (event) => {
    const file = event.target.files;
    if (file) {
        const storageReference = storageRef(storage, `gameProfileBanners/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame3ProfileBanner(downloadURL); // Store the URL in state
            console.log('Game profile banner uploaded successfully:', downloadURL);
            setGame3ProfileBannerUploaded(true); // Update the state to indicate upload success
        } catch (error) {
            console.error('Error uploading game profile banner:', error);
        }
    }
  };

  const handleGame4ProfileBannerUpload = async (event) => {
    const file = event.target.files;
    if (file) {
        const storageReference = storageRef(storage, `gameProfileBanners/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame4ProfileBanner (downloadURL); // Store the URL in state
            console.log('Game profile banner uploaded successfully:', downloadURL);
            setGame4ProfileBannerUploaded(true); // Update the state to indicate upload success
        } catch (error) {
            console.error('Error uploading game profile banner:', error);
        }
    }
  };

  const handleGame1ContentUpload1 = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `gameContent1/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame1Content((prev) => [...prev, downloadURL]); // Add the new URL to the gameContent array
            setGame1Content1Uploaded(true); // Set the state to indicate upload success
            console.log('Game content 1 uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading game content 1:', error);
        }
    }
  };

  const handleGame2ContentUpload1 = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `gameContent1/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame2Content((prev) => [...prev, downloadURL]); // Add the new URL to the gameContent array
            setGame2Content1Uploaded(true); // Set the state to indicate upload success
            console.log('Game content 1 uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading game content 1:', error);
        }
    }
  };

  const handleGame3ContentUpload1 = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `gameContent1/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame3Content((prev) => [...prev, downloadURL]); // Add the new URL to the gameContent array
            setGame3Content1Uploaded(true); // Set the state to indicate upload success
            console.log('Game content 1 uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading game content 1:', error);
        }
    }
  };

  const handleGame4ContentUpload1 = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `gameContent1/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame4Content((prev) => [...prev, downloadURL]); // Add the new URL to the gameContent array
            setGame4Content1Uploaded(true); // Set the state to indicate upload success
            console.log('Game content 1 uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading game content 1:', error);
        }
    }
  };


  const handleGame1ContentUpload2 = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `gameContent2/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame1Content((prev) => [...prev, downloadURL]); // Add the new URL to the gameContent array
            setGame1Content2Uploaded(true); // Set the state to indicate upload success
            console.log('Game content 2 uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading game content 2:', error);
        }
    }
  };

  const handleGame2ContentUpload2 = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `gameContent2/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame2Content((prev) => [...prev, downloadURL]); // Add the new URL to the gameContent array
            setGame2Content2Uploaded(true); // Set the state to indicate upload success
            console.log('Game content 2 uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading game content 2:', error);
        }
    }
  };

  const handleGame3ContentUpload2 = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `gameContent2/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame3Content((prev) => [...prev, downloadURL]); // Add the new URL to the gameContent array
            setGame3Content2Uploaded(true); // Set the state to indicate upload success
            console.log('Game content 2 uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading game content 2:', error);
        }
    }
  };

  const handleGame4ContentUpload2 = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `gameContent2/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame4Content((prev) => [...prev, downloadURL]); // Add the new URL to the gameContent array
            setGame4Content2Uploaded(true); // Set the state to indicate upload success
            console.log('Game content 2 uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading game content 2:', error);
        }
    }
  };

  const handleGame1ContentUpload3 = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `gameContent3/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame1Content((prev) => [...prev, downloadURL]); // Add the new URL to the gameContent array
            setGame1Content3Uploaded(true); // Set the state to indicate upload success
            console.log('Game content 3 uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading game content 3:', error);
        }
    }
  };

  const handleGame2ContentUpload3 = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `gameContent3/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame2Content((prev) => [...prev, downloadURL]); // Add the new URL to the gameContent array
            setGame2Content3Uploaded(true); // Set the state to indicate upload success
            console.log('Game content 3 uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading game content 3:', error);
        }
    }
  };

  const handleGame3ContentUpload3 = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `gameContent3/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame3Content((prev) => [...prev, downloadURL]); // Add the new URL to the gameContent array
            setGame3Content3Uploaded(true); // Set the state to indicate upload success
            console.log('Game content 3 uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading game content 3:', error);
        }
    }
  };

  const handleGame4ContentUpload3 = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `gameContent3/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame4Content((prev) => [...prev, downloadURL]); // Add the new URL to the gameContent array
            setGame4Content3Uploaded(true); // Set the state to indicate upload success
            console.log('Game content 3 uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading game content 3:', error);
        }
    }
  };

  const handleGame1ContentUpload4 = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `gameContent4/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame1Content((prev) => [...prev, downloadURL]); // Add the new URL to the gameContent array
            setGame1Content4Uploaded(true); // Set the state to indicate upload success
            console.log('Game content 4 uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading game content 4:', error);
        }
    }
  };

  const handleGame2ContentUpload4 = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `gameContent4/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame2Content((prev) => [...prev, downloadURL]); // Add the new URL to the gameContent array
            setGame2Content4Uploaded(true); // Set the state to indicate upload success
            console.log('Game content 4 uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading game content 4:', error);
        }
    }
  };

  const handleGame3ContentUpload4 = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `gameContent4/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame3Content((prev) => [...prev, downloadURL]); // Add the new URL to the gameContent array
            setGame3Content4Uploaded(true); // Set the state to indicate upload success
            console.log('Game content 4 uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading game content 4:', error);
        }
    }
  };

  const handleGame4ContentUpload4 = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `gameContent4/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame4Content((prev) => [...prev, downloadURL]); // Add the new URL to the gameContent array
            setGame4Content4Uploaded(true); // Set the state to indicate upload success
            console.log('Game content 4 uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading game content 4:', error);
        }
    }
  };

  const handleGame1ContentUpload5 = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `gameContent5/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame1Content((prev) => [...prev, downloadURL]); // Add the new URL to the gameContent array
            setGame1Content5Uploaded(true); // Set the state to indicate upload success
            console.log('Game content 5 uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading game content 5:', error);
        }
    }
  };

  const handleGame2ContentUpload5 = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `gameContent5/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame2Content((prev) => [...prev, downloadURL]); // Add the new URL to the gameContent array
            setGame2Content5Uploaded(true); // Set the state to indicate upload success
            console.log('Game content 5 uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading game content 5:', error);
        }
    }
  };

  const handleGame3ContentUpload5 = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `gameContent5/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame3Content((prev) => [...prev, downloadURL]); // Add the new URL to the gameContent array
            setGame3Content5Uploaded(true); // Set the state to indicate upload success
            console.log('Game content 5 uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading game content 5:', error);
        }
    }
  };

  const handleGame4ContentUpload5 = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `gameContent5/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame4Content((prev) => [...prev, downloadURL]); // Add the new URL to the gameContent array
            setGame4Content5Uploaded(true); // Set the state to indicate upload success
            console.log('Game content 5 uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading game content 5:', error);
        }
    }
  };

  const handleAddLink = () => {
    if (linkCount < 5) {
      setLinkCount(linkCount + 1); // Increment the link count
    }
  };

  const handleRemoveLink = () => {
    if (linkCount > 1) {
      setLinkCount(linkCount - 1); // Decrement the link count
    }
  };

  const handleStudioProfileBannerUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const storageReference = storageRef(storage, `studioProfileBanners/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file);
            const downloadURL = await getDownloadURL(storageReference);
            setStudioProfileBannerUploaded(downloadURL);
            console.log('Studio profile banner uploaded successfully:', downloadURL);
        } catch (error) {
            console.error('Error uploading studio profile banner:', error);
        }
    }
  };

  const handleGame1TitleChange = (value) => {
    setgame1title(value); // Directly set the title for game1
  };
  const handleGame2TitleChange = (value) => {
    setgame2title(value); // Directly set the title for game2
  };
  const handleGame3TitleChange = (value) => {
    setgame3title(value); // Directly set the title for game3
  };
  const handleGame4TitleChange = (value) => {
    setgame4title(value); // Directly set the title for game4
  };

  const handleGame1WebsiteChange = (value) => {
    setgame1website(value); // Directly set the website for game1
  };
  
  const handleGame2WebsiteChange = (value) => {
    setgame2website(value); // Directly set the website for game2
  };
  const handleGame3WebsiteChange = (value) => {
    setgame3website(value); // Directly set the website for game3
  };
  const handleGame4WebsiteChange = (value) => {
    setgame4website(value); // Directly set the website for game4
  };

  const handleGame1DevelopmentStageChange = (value) => {
    setgame1developmentStage(value); // Directly set the development stage for game1
  };
  const handleGame2DevelopmentStageChange = (value) => {
    setgame2developmentStage(value); // Directly set the development stage for game2
  };
  const handleGame3DevelopmentStageChange = (value) => {
    setgame3developmentStage(value); // Directly set the development stage for game3
  };
  const handleGame4DevelopmentStageChange = (value) => {
    setgame4developmentStage(value); // Directly set the development stage for game4
  };

  const handleGame1PlatformsChange = (platform) => {
    setGame1Platforms(prev => {
        const isSelected = prev.includes(platform);
        const updatedPlatforms = isSelected 
            ? prev.filter(p => p !== platform) // Remove platform if already selected
            : [...prev, platform]; // Add platform if not selected
        
        console.log('Selected compatible platforms:', updatedPlatforms); // Log the selected platforms
        return updatedPlatforms;
    });
  };

  const handleGame2PlatformsChange = (platform) => {
    setGame2Platforms(prev => { 
      const isSelected = prev.includes(platform);
      const updatedPlatforms = isSelected 
          ? prev.filter(p => p !== platform) // Remove platform if already selected
          : [...prev, platform]; // Add platform if not selected
      
      console.log('Selected compatible platforms:', updatedPlatforms); // Log the selected platforms
      return updatedPlatforms;
  });  };

  const handleGame3PlatformsChange = (platform) => {
    setGame3Platforms(prev => {
      const isSelected = prev.includes(platform);
      const updatedPlatforms = isSelected 
          ? prev.filter(p => p !== platform) // Remove platform if already selected
          : [...prev, platform]; // Add platform if not selected
      
      console.log('Selected compatible platforms:', updatedPlatforms); // Log the selected platforms
      return updatedPlatforms;
  }); };

  const handleGame4PlatformsChange = (platform) => {
    setGame4Platforms(prev => {
      const isSelected = prev.includes(platform);
      const updatedPlatforms = isSelected 
          ? prev.filter(p => p !== platform) // Remove platform if already selected
          : [...prev, platform]; // Add platform if not selected
      
      console.log('Selected compatible platforms:', updatedPlatforms); // Log the selected platforms
      return updatedPlatforms;
  });  };

  const handleAddNewsUpload = () => {
    setShowUploads(true); // Set to true when the icon is clicked
    setNewsUploadCount(prevCount => prevCount + 1); // Increment the news upload count
  };

  const handleGame1News1LinkChange = (value) => {
    setGame1News1Link(value);
  };

  const handleGame1News2LinkChange = (value) => {
    setGame1News2Link(value);
  };

  const handleGame1News3LinkChange = (value) => {
    setGame1News3Link(value);
  };

  const handleGame1News4LinkChange = (value) => {
    setGame1News4Link(value);
  };

  const handleGame2News1LinkChange = (value) => {
    setGame2News1Link(value);
  };

  const handleGame2News2LinkChange = (value) => {
    setGame2News2Link(value);
  };

  const handleGame2News3LinkChange = (value) => {
    setGame2News3Link(value);
  };

  const handleGame2News4LinkChange = (value) => {
    setGame2News4Link(value);
  };

  const handleGame3News1LinkChange = (value) => {
    setGame3News1Link(value);
  };

  const handleGame3News2LinkChange = (value) => {
    setGame3News2Link(value);
  };

  const handleGame3News3LinkChange = (value) => {
    setGame3News3Link(value);
  };

  const handleGame3News4LinkChange = (value) => {
    setGame3News4Link(value);
  };

  const handleGame4News1LinkChange = (value) => {
    setGame4News1Link(value);
  };

  const handleGame4News2LinkChange = (value) => {
    setGame4News2Link(value);
  };

  const handleGame4News3LinkChange = (value) => {
    setGame4News3Link(value);
  };

  const handleGame4News4LinkChange = (value) => {
    setGame4News4Link(value);
  };

  const handleGame1News1PicUpload = async (event) => {
    const file = event.target.files[0]; // Get the first file
    if (file) {
        const storageReference = storageRef(storage, `newsImages/${userIdFromState}/${file.name}`);
        try {
            await uploadBytes(storageReference, file); // Upload the file
            const downloadURL = await getDownloadURL(storageReference); // Get the download URL
            setGame1News1Pic(downloadURL); // Store the URL in state
            console.log('Game 1 News 1 picture uploaded successfully:', downloadURL);
            setGame1News1PicUploaded(true); // Update the state to indicate upload success
        } catch (error) { 
            console.error('Error uploading Game 1 News 1 picture:', error);
        }
    }
};

const handleGame1News2PicUpload = async (event) => {
  const file = event.target.files[0]; // Get the first file
  if (file) {
      const storageReference = storageRef(storage, `newsImages/${userIdFromState}/${file.name}`);
      try {
          await uploadBytes(storageReference, file); // Upload the file
          const downloadURL = await getDownloadURL(storageReference); // Get the download URL
          setGame1News2Pic(downloadURL); // Store the URL in state
          console.log('Game 1 News 2 picture uploaded successfully:', downloadURL);
          setGame1News2PicUploaded(true); // Update the state to indicate upload success
      } catch (error) { 
          console.error('Error uploading Game 1 News 2 picture:', error);
      }
  }
};

const handleGame1News3PicUpload = async (event) => {
  const file = event.target.files[0]; // Get the first file
  if (file) {
      const storageReference = storageRef(storage, `newsImages/${userIdFromState}/${file.name}`);
      try {
          await uploadBytes(storageReference, file); // Upload the file
          const downloadURL = await getDownloadURL(storageReference); // Get the download URL
          setGame1News3Pic(downloadURL); // Store the URL in state
          console.log('Game 1 News 3 picture uploaded successfully:', downloadURL);
          setGame1News3PicUploaded(true); // Update the state to indicate upload success
      } catch (error) { 
          console.error('Error uploading Game 1 News 3 picture:', error);
      }
  }
};

const handleGame1News4PicUpload = async (event) => {
  const file = event.target.files[0]; // Get the first file
  if (file) {
      const storageReference = storageRef(storage, `newsImages/${userIdFromState}/${file.name}`);
      try {
          await uploadBytes(storageReference, file); // Upload the file
          const downloadURL = await getDownloadURL(storageReference); // Get the download URL
          setGame1News4Pic(downloadURL); // Store the URL in state
          console.log('Game 1 News 4 picture uploaded successfully:', downloadURL);
          setGame1News4PicUploaded(true); // Update the state to indicate upload success
      } catch (error) { 
          console.error('Error uploading Game 1 News 4 picture:', error);
      }
  }
};

const handleGame2News1PicUpload = async (event) => {
  const file = event.target.files[0]; // Get the first file
  if (file) {
      const storageReference = storageRef(storage, `newsImages/${userIdFromState}/${file.name}`);
      try {
          await uploadBytes(storageReference, file); // Upload the file
          const downloadURL = await getDownloadURL(storageReference); // Get the download URL
          setGame2News1Pic(downloadURL); // Store the URL in state
          console.log('Game 2 News 1 picture uploaded successfully:', downloadURL);
          setGame2News1PicUploaded(true); // Update the state to indicate upload success
      } catch (error) { 
          console.error('Error uploading Game 2 News 1 picture:', error);
      }
  }
};

const handleGame2News2PicUpload = async (event) => {
const file = event.target.files[0]; // Get the first file
if (file) {
    const storageReference = storageRef(storage, `newsImages/${userIdFromState}/${file.name}`);
    try {
        await uploadBytes(storageReference, file); // Upload the file
        const downloadURL = await getDownloadURL(storageReference); // Get the download URL
        setGame2News2Pic(downloadURL); // Store the URL in state
        console.log('Game 2 News 2 picture uploaded successfully:', downloadURL);
        setGame2News2PicUploaded(true); // Update the state to indicate upload success
    } catch (error) { 
        console.error('Error uploading Game 2 News 2 picture:', error);
    }
}
};

const handleGame2News3PicUpload = async (event) => {
const file = event.target.files[0]; // Get the first file
if (file) {
    const storageReference = storageRef(storage, `newsImages/${userIdFromState}/${file.name}`);
    try {
        await uploadBytes(storageReference, file); // Upload the file
        const downloadURL = await getDownloadURL(storageReference); // Get the download URL
        setGame2News3Pic(downloadURL); // Store the URL in state
        console.log('Game 2 News 3 picture uploaded successfully:', downloadURL);
        setGame2News3PicUploaded(true); // Update the state to indicate upload success
    } catch (error) { 
        console.error('Error uploading Game 2 News 3 picture:', error);
    }
}
};

const handleGame2News4PicUpload = async (event) => {
const file = event.target.files[0]; // Get the first file
if (file) {
    const storageReference = storageRef(storage, `newsImages/${userIdFromState}/${file.name}`);
    try {
        await uploadBytes(storageReference, file); // Upload the file
        const downloadURL = await getDownloadURL(storageReference); // Get the download URL
        setGame2News4Pic(downloadURL); // Store the URL in state
        console.log('Game 2 News 4 picture uploaded successfully:', downloadURL);
        setGame2News4PicUploaded(true); // Update the state to indicate upload success
    } catch (error) { 
        console.error('Error uploading Game 2 News 4 picture:', error);
    }
}
};


const handleGame3News1PicUpload = async (event) => {
  const file = event.target.files[0]; // Get the first file
  if (file) {
      const storageReference = storageRef(storage, `newsImages/${userIdFromState}/${file.name}`);
      try { 
          await uploadBytes(storageReference, file); // Upload the file
          const downloadURL = await getDownloadURL(storageReference); // Get the download URL
          setGame3News1Pic(downloadURL); // Store the URL in state
          console.log('Game 3 News 1 picture uploaded successfully:', downloadURL);
          setGame3News1PicUploaded(true); // Update the state to indicate upload success
      } catch (error) { 
          console.error('Error uploading Game 3 News 1 picture:', error);
      }
  }
};

const handleGame3News2PicUpload = async (event) => {
const file = event.target.files[0]; // Get the first file
if (file) {
    const storageReference = storageRef(storage, `newsImages/${userIdFromState}/${file.name}`);
    try {
        await uploadBytes(storageReference, file); // Upload the file
        const downloadURL = await getDownloadURL(storageReference); // Get the download URL
        setGame3News2Pic(downloadURL); // Store the URL in state
        console.log('Game 3 News 2 picture uploaded successfully:', downloadURL);
        setGame3News2PicUploaded(true); // Update the state to indicate upload success
    } catch (error) { 
        console.error('Error uploading Game 3 News 2 picture:', error);
    }
}
};

const handleGame3News3PicUpload = async (event) => {
const file = event.target.files[0]; // Get the first file
if (file) {
    const storageReference = storageRef(storage, `newsImages/${userIdFromState}/${file.name}`);
    try {
        await uploadBytes(storageReference, file); // Upload the file
        const downloadURL = await getDownloadURL(storageReference); // Get the download URL
        setGame3News3Pic(downloadURL); // Store the URL in state
        console.log('Game 3 News 3 picture uploaded successfully:', downloadURL);
        setGame3News3PicUploaded(true); // Update the state to indicate upload success
    } catch (error) { 
        console.error('Error uploading Game 3 News 3 picture:', error);
    }
}
};

const handleGame3News4PicUpload = async (event) => {
const file = event.target.files[0]; // Get the first file
if (file) {
    const storageReference = storageRef(storage, `newsImages/${userIdFromState}/${file.name}`);
    try {
        await uploadBytes(storageReference, file); // Upload the file
        const downloadURL = await getDownloadURL(storageReference); // Get the download URL
        setGame3News4Pic(downloadURL); // Store the URL in state
        console.log('Game 3 News 4 picture uploaded successfully:', downloadURL);
        setGame3News4PicUploaded(true); // Update the state to indicate upload success
    } catch (error) { 
        console.error('Error uploading Game 3 News 4 picture:', error);
    }
}
};

const handleGame4News1PicUpload = async (event) => {
  const file = event.target.files[0]; // Get the first file
  if (file) {
      const storageReference = storageRef(storage, `newsImages/${userIdFromState}/${file.name}`);
      try {
          await uploadBytes(storageReference, file); // Upload the file
          const downloadURL = await getDownloadURL(storageReference); // Get the download URL
          setGame4News1Pic(downloadURL); // Store the URL in state
          console.log('Game 4 News 1 picture uploaded successfully:', downloadURL);
          setGame4News1PicUploaded(true); // Update the state to indicate upload success
      } catch (error) { 
          console.error('Error uploading Game 4 News 1 picture:', error);
      }
  }
};

const handleGame4News2PicUpload = async (event) => {
const file = event.target.files[0]; // Get the first file
if (file) {
    const storageReference = storageRef(storage, `newsImages/${userIdFromState}/${file.name}`);
    try {
        await uploadBytes(storageReference, file); // Upload the file
        const downloadURL = await getDownloadURL(storageReference); // Get the download URL
        setGame4News2Pic(downloadURL); // Store the URL in state
        console.log('Game 4 News 2 picture uploaded successfully:', downloadURL);
        setGame4News2PicUploaded(true); // Update the state to indicate upload success
    } catch (error) { 
        console.error('Error uploading Game 4 News 2 picture:', error);
    }
}
};

const handleGame4News3PicUpload = async (event) => {
const file = event.target.files[0]; // Get the first file
if (file) {
    const storageReference = storageRef(storage, `newsImages/${userIdFromState}/${file.name}`);
    try {
        await uploadBytes(storageReference, file); // Upload the file
        const downloadURL = await getDownloadURL(storageReference); // Get the download URL
        setGame4News3Pic(downloadURL); // Store the URL in state
        console.log('Game 4 News 3 picture uploaded successfully:', downloadURL);
        setGame4News3PicUploaded(true); // Update the state to indicate upload success
    } catch (error) { 
        console.error('Error uploading Game 4 News 3 picture:', error);
    }
}
};

const handleGame4News4PicUpload = async (event) => {
const file = event.target.files[0]; // Get the first file
if (file) {
    const storageReference = storageRef(storage, `newsImages/${userIdFromState}/${file.name}`);
    try {
        await uploadBytes(storageReference, file); // Upload the file
        const downloadURL = await getDownloadURL(storageReference); // Get the download URL
        setGame4News4Pic(downloadURL); // Store the URL in state
        console.log('Game 4 News 4 picture uploaded successfully:', downloadURL);
        setGame4News4PicUploaded(true); // Update the state to indicate upload success
    } catch (error) { 
        console.error('Error uploading Game 4 News 4 picture:', error);
    }
}
};



  return (
    <div className={`studio-typeform ${currentQuestion === 0 ? 'username-name-question' : ''} ${currentQuestion === 2 ? 'studio-bio-question' : ''} ${currentQuestion === 3 || currentQuestion === 9 || currentQuestion === 15 || currentQuestion === 21 ? 'game-title-question' : ''} ${currentQuestion === 4 || currentQuestion === 10 || currentQuestion === 16 || currentQuestion === 22 ? 'genre-question' : ''} ${currentQuestion === 5 || currentQuestion === 11 || currentQuestion === 17 || currentQuestion === 23 ? 'social-question' : ''} ${currentQuestion === 7 || currentQuestion === 13 || currentQuestion === 19 || currentQuestion === 25 ? 'game-bio-question' : ''} ${currentQuestion === 6 || currentQuestion === 12 || currentQuestion === 18 || currentQuestion === 24 ? 'news-links-question' : ''}`}>
      {loading && (
        <LoadingOverlay displayName={username} contentType="game titles" onOverlayClick={handleOverlayClick} />
      )}
      {!loading && (
        <>
          <div className="logo-container" ref={logoRef}>
            <div className="logo-and-logout">
              <img src={logo} alt="Logo" className="typeform-logo" />
              <div className="logout-button-container">
                <button className="logout-button nav-button" onClick={handleLogout}>
                  Sign Out
                </button>
              </div>
            </div>
          </div>
          <CSSTransition
            in={!inTransition}
            timeout={500}
            classNames="fade"
            unmountOnExit
          >
            <div className='Transition-container'>
              <h1 className='typeform-title fade-in'>{questions[currentQuestion].question}</h1>
              {currentQuestion === 5 && (
            <>
              <p className="social-question-description">Select all that you use to post content and updates.</p>
              <div className="socials-container">
                {socialMediaList.map((social) => (
                  <div 
                    key={social.name}
                    className={`social-item ${selectedgame1Socials.includes(social.name) ? 'selected' : ''}`}
                    onClick={() => handleGame1SocialSelect(social.name)}
                  >
                    <img
                      src={selectedgame1Socials.includes(social.name) ? require(`../assets/socials/${social.selectedIcon}`) : require(`../assets/socials/${social.icon}`)}
                      alt={social.name}
                      className={`social-icon ${social.name === 'YouTube' ? 'youtube-icon' : ''} ${social.name === 'X' ? 'x-icon' : ''}`}
                    />
                    <p className={`social-label ${social.name === 'YouTube' ? 'youtube-label' : ''} ${social.name === 'X' ? 'x-label' : ''}`}>{social.name}</p>
                    {selectedgame1Socials.includes(social.name) && (
                      <div className="input-container">
                        <input 
                          type="text"
                          placeholder={`${social.name} URL`}
                          className="social-input"
                          required
                          onChange={(e) => handleGame1SocialsChange(e, social.name)} // Update URL on change
                          onClick={(e) => e.stopPropagation()} // Prevent click from propagating
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </>
          )}

          {currentQuestion === 11 && (
            <>
            <p className="social-question-description">Select all that you use to post content and updates.</p>
            <div className="socials-container">
              {socialMediaList.map((social) => (
                <div
                  key={social.name}
                  className={`social-item ${selectedgame2Socials.includes(social.name) ? 'selected' : ''}`}
                  onClick={() => handleGame2SocialSelect(social.name)}
                >
                  <img
                    src={selectedgame2Socials.includes(social.name) ? require(`../assets/socials/${social.selectedIcon}`) : require(`../assets/socials/${social.icon}`)}
                    alt={social.name}
                    className={`social-icon ${social.name === 'YouTube' ? 'youtube-icon' : ''} ${social.name === 'X' ? 'x-icon' : ''}`}
                  />
                  <p className={`social-label ${social.name === 'YouTube' ? 'youtube-label' : ''} ${social.name === 'X' ? 'x-label' : ''}`}>{social.name}</p>
                  {selectedgame2Socials.includes(social.name) && (
                    <div className="input-container">
                      <input 
                        type="text"
                        placeholder={`${social.name} URL`}
                        className="social-input"
                        required
                        onChange={(e) => handleGame2SocialsChange(e, social.name)} // Update URL on change
                        onClick={(e) => e.stopPropagation()} // Prevent click from propagating
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
          {currentQuestion === 17 && (
            <>
            <p className="social-question-description">Select all that you use to post content and updates.</p>
            <div className="socials-container">
              {socialMediaList.map((social) => (
                <div
                  key={social.name}
                  className={`social-item ${selectedgame3Socials.includes(social.name) ? 'selected' : ''}`}
                  onClick={() => handleGame3SocialSelect(social.name)}
                >
                  <img
                    src={selectedgame3Socials.includes(social.name) ? require(`../assets/socials/${social.selectedIcon}`) : require(`../assets/socials/${social.icon}`)}
                    alt={social.name}
                    className={`social-icon ${social.name === 'YouTube' ? 'youtube-icon' : ''} ${social.name === 'X' ? 'x-icon' : ''}`}
                  />
                  <p className={`social-label ${social.name === 'YouTube' ? 'youtube-label' : ''} ${social.name === 'X' ? 'x-label' : ''}`}>{social.name}</p>
                  {selectedgame3Socials.includes(social.name) && (
                    <div className="input-container">
                      <input 
                        type="text"
                        placeholder={`${social.name} URL`}
                        className="social-input"
                        required
                        onChange={(e) => handleGame3SocialsChange(e, social.name)} // Update URL on change
                        onClick={(e) => e.stopPropagation()} // Prevent click from propagating
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
          {currentQuestion === 23 && (
            <>
            <p className="social-question-description">Select all that you use to post content and updates.</p>
            <div className="socials-container">
              {socialMediaList.map((social) => (
                <div
                  key={social.name}
                  className={`social-item ${selectedgame4Socials.includes(social.name) ? 'selected' : ''}`}
                  onClick={() => handleGame4SocialSelect(social.name)}
                >
                  <img
                    src={selectedgame4Socials.includes(social.name) ? require(`../assets/socials/${social.selectedIcon}`) : require(`../assets/socials/${social.icon}`)}
                    alt={social.name}
                    className={`social-icon ${social.name === 'YouTube' ? 'youtube-icon' : ''} ${social.name === 'X' ? 'x-icon' : ''}`}
                  />
                  <p className={`social-label ${social.name === 'YouTube' ? 'youtube-label' : ''} ${social.name === 'X' ? 'x-label' : ''}`}>{social.name}</p>
                  {selectedgame4Socials.includes(social.name) && (
                    <div className="input-container">
                      <input 
                        type="text"
                        placeholder={`${social.name} URL`}
                        className="social-input"
                        required
                        onChange={(e) => handleGame4SocialsChange(e, social.name)} // Update URL on change
                        onClick={(e) => e.stopPropagation()} // Prevent click from propagating
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </>

          
        )}
                                  {currentQuestion === 6 && (
            <div className="game-title-inputs" style={{ marginBottom: 'auto'}}>
              <p className="game-title-description" style={{ marginTop: '0px'}}>Add up to 4 pieces of news from your first game.</p>
                            <div className="news-1" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'fit-content', gap: '10px'}}>
                                <div className='file-upload news-upload'>
                                <label>                                    <input
                                        type="file"
                                        accept="image/*, gif, png, jpeg"
                                        onChange={handleGame1News1PicUpload} // Use the new function here
                                        style={{ display: 'none' }} // Hide the default file input
                                    />  
                                    <img
                                        src={game1news1picUploaded ? checkIcon : downloadIcon}
                                        alt="Upload News Link Cover"
                                        className="upload-icon"
                                    />
                                    <div className='upload-label-container'>
                                        <p className='upload-label'>Add a cover image</p>
                                        <p className='label-sub'>(16:9 aspect ratio)</p>
                                    </div>
                                </label>
                                </div>
                                <input
                                    type="text"
                                    placeholder="NEWS 1 LINK"
                                    value={game1news1link || ''} // Use an empty string if title is undefined
                                    onChange={(e) => handleGame1News1LinkChange(e.target.value)}
                                /> 
                            </div>
                            {showUploads && ( // Conditionally render uploads based on state
                    <>
                        {newsUploadCount >= 3 && (
                            <div className="news-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'fit-content', gap: '10px'}}>
                                <div className='file-upload news-upload'>
                                <label>
                                    <input
                                        type="file"
                                        accept="image/*, gif, png, jpeg"
                                        onChange={handleGame1News2PicUpload} // Use the new function here
                                        style={{ display: 'none' }} // Hide the default file input
                                        />  
                                        <img
                                        src={game1news2picUploaded ? checkIcon : downloadIcon}
                                        alt="Upload News Link Cover"
                                        className="upload-icon"
                                    />
                                    <div className='upload-label-container'>
                                        <p className='upload-label'>Add a cover image</p>
                                        <p className='label-sub'>(16:9 aspect ratio)</p>
                                    </div>
                                </label>
                                </div>
                                <input
                                    type="text"
                                    placeholder="NEWS 2 LINK"
                                    value={game1news2link || ''} // Use an empty string if title is undefined
                                    onChange={(e) => handleGame1News2LinkChange(e.target.value)}
                                />
                            </div>
                        )}
                        {newsUploadCount >= 4 && (
                            <div className="news-3" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'fit-content', gap: '10px'}}>
                                <div className='file-upload news-upload'>
                                <label>
                                    <input
                                        type="file"
                                        accept="image/*, gif, png, jpeg"
                                        onChange={handleGame1News3PicUpload} // Use the new function here
                                        style={{ display: 'none' }} // Hide the default file input
                                    />  
                                    <img
                                        src={game1news3picUploaded ? checkIcon : downloadIcon}
                                        alt="Upload News Link Cover"
                                        className="upload-icon"
                                    />
                                    <div className='upload-label-container'>
                                        <p className='upload-label'>Add a cover image</p>
                                        <p className='label-sub'>(16:9 aspect ratio)</p>
                                    </div>
                                </label>
                                </div>
                                <input
                                    type="text"
                                    placeholder="NEWS 3 LINK"
                                    value={game1news3link || ''} // Use an empty string if title is undefined
                                    onChange={(e) => handleGame1News3LinkChange(e.target.value)}
                                />  
                            </div>
                        )}


                        {newsUploadCount >= 5 && (
                            <div className="news-4" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'fit-content', gap: '10px'}}>
                                <div className='file-upload news-upload'>
                                <label>
                                    <input
                                        type="file"
                                        accept="image/*, gif, png, jpeg"
                                        onChange={handleGame1News4PicUpload} // Use the new function here
                                        style={{ display: 'none' }} // Hide the default file input
                                    />  
                                    <img
                                        src={game1news4picUploaded ? checkIcon : downloadIcon}
                                        alt="Upload News Link Cover"
                                        className="upload-icon"
                                    />
                                    <div className='upload-label-container'>
                                        <p className='upload-label'>Add a cover image</p>
                                        <p className='label-sub'>(16:9 aspect ratio)</p>
                                    </div>
                                </label>
                                </div>
                                <input
                                    type="text"
                                    placeholder="NEWS 4 LINK"
                                    value={game1news4link || ''} // Use an empty string if title is undefined
                                    onChange={(e) => handleGame1News4LinkChange(e.target.value)}
                                />  
                            </div>
                        )}
                    </>
                )}
                {newsUploadCount < 5 && (
                    <img
                        src={addLinkIcon}
                        alt="Add Link"
                        className="add-link-icon"
                        onClick={handleAddNewsUpload} // Trigger adding a new news upload
                        style={{ cursor: 'pointer'}} // Style the icon
                    />
                )}
            </div>
            
            
          )}
                                            {currentQuestion === 12 && (
            <div className="game-title-inputs" style={{ marginBottom: 'auto'}}>
              <p className="game-title-description" style={{ marginTop: '0px'}}>Add up to 4 pieces of news from your second game.</p>
                            <div className="news-1" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'fit-content', gap: '10px'}}>
                                <div className='file-upload news-upload'>
                                <label>                                    <input
                                        type="file"
                                        accept="image/*, gif, png, jpeg"
                                        onChange={handleGame2News1PicUpload} // Use the new function here
                                        style={{ display: 'none' }} // Hide the default file input
                                    />  
                                    <img
                                        src={game2news1picUploaded ? checkIcon : downloadIcon}
                                        alt="Upload News Link Cover"
                                        className="upload-icon"
                                    />
                                    <div className='upload-label-container'>
                                        <p className='upload-label'>Add a cover image</p>
                                        <p className='label-sub'>(16:9 aspect ratio)</p>
                                    </div>
                                </label>
                                </div>
                                <input
                                    type="text"
                                    placeholder="NEWS 1 LINK"
                                    value={game2news1link || ''} // Use an empty string if title is undefined
                                    onChange={(e) => handleGame2News1LinkChange(e.target.value)}
                                /> 
                            </div>
                            {showUploads && ( // Conditionally render uploads based on state
                    <>
                        {newsUploadCount >= 3 && (
                            <div className="news-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'fit-content', gap: '10px'}}>
                                <div className='file-upload news-upload'>
                                <label>
                                    <input
                                        type="file"
                                        accept="image/*, gif, png, jpeg"
                                        onChange={handleGame2News2PicUpload} // Use the new function here
                                        style={{ display: 'none' }} // Hide the default file input
                                        />  
                                        <img
                                        src={game2news2picUploaded ? checkIcon : downloadIcon}
                                        alt="Upload News Link Cover"
                                        className="upload-icon"
                                    />
                                    <div className='upload-label-container'>
                                        <p className='upload-label'>Add a cover image</p>
                                        <p className='label-sub'>(16:9 aspect ratio)</p>
                                    </div>
                                </label>
                                </div>
                                <input
                                    type="text"
                                    placeholder="NEWS 2 LINK"
                                    value={game1news2link || ''} // Use an empty string if title is undefined
                                    onChange={(e) => handleGame2News2LinkChange(e.target.value)}
                                />
                            </div>
                        )}
                        {newsUploadCount >= 4 && (
                            <div className="news-3" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'fit-content', gap: '10px'}}>
                                <div className='file-upload news-upload'>
                                <label>
                                    <input
                                        type="file"
                                        accept="image/*, gif, png, jpeg"
                                        onChange={handleGame2News3PicUpload} // Use the new function here
                                        style={{ display: 'none' }} // Hide the default file input
                                    />  
                                    <img
                                        src={game2news3picUploaded ? checkIcon : downloadIcon}
                                        alt="Upload News Link Cover"
                                        className="upload-icon"
                                    />
                                    <div className='upload-label-container'>
                                        <p className='upload-label'>Add a cover image</p>
                                        <p className='label-sub'>(16:9 aspect ratio)</p>
                                    </div>
                                </label>
                                </div>
                                <input
                                    type="text"
                                    placeholder="NEWS 3 LINK"
                                    value={game2news3link || ''} // Use an empty string if title is undefined
                                    onChange={(e) => handleGame2News3LinkChange(e.target.value)}
                                />  
                            </div>
                        )}


                        {newsUploadCount >= 5 && (
                            <div className="news-4" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'fit-content', gap: '10px'}}>
                                <div className='file-upload news-upload'>
                                <label>
                                    <input
                                        type="file"
                                        accept="image/*, gif, png, jpeg"
                                        onChange={handleGame2News4PicUpload} // Use the new function here
                                        style={{ display: 'none' }} // Hide the default file input
                                    />  
                                    <img
                                        src={game2news4picUploaded ? checkIcon : downloadIcon}
                                        alt="Upload News Link Cover"
                                        className="upload-icon"
                                    />
                                    <div className='upload-label-container'>
                                        <p className='upload-label'>Add a cover image</p>
                                        <p className='label-sub'>(16:9 aspect ratio)</p>
                                    </div>
                                </label>
                                </div>
                                <input
                                    type="text"
                                    placeholder="NEWS 4 LINK"
                                    value={game2news4link || ''} // Use an empty string if title is undefined
                                    onChange={(e) => handleGame2News4LinkChange(e.target.value)}
                                />  
                            </div>
                        )}
                    </>
                )}
                {newsUploadCount < 5 && (
                    <img
                        src={addLinkIcon}
                        alt="Add Link"
                        className="add-link-icon"
                        onClick={handleAddNewsUpload} // Trigger adding a new news upload
                        style={{ cursor: 'pointer'}} // Style the icon
                    />
                )}
            </div>
            
            
          )}

{currentQuestion === 18 && (
            <div className="game-title-inputs" style={{ marginBottom: 'auto'}}>
              <p className="game-title-description" style={{ marginTop: '0px'}}>Add up to 4 pieces of news from your second game.</p>
                            <div className="news-1" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'fit-content', gap: '10px'}}>
                                <div className='file-upload news-upload'>
                                <label>                                    <input
                                        type="file"
                                        accept="image/*, gif, png, jpeg"
                                        onChange={handleGame3News1PicUpload} // Use the new function here
                                        style={{ display: 'none' }} // Hide the default file input
                                    />  
                                    <img
                                        src={game3news1picUploaded ? checkIcon : downloadIcon}
                                        alt="Upload News Link Cover"
                                        className="upload-icon"
                                    />
                                    <div className='upload-label-container'>
                                        <p className='upload-label'>Add a cover image</p>
                                        <p className='label-sub'>(16:9 aspect ratio)</p>
                                    </div>
                                </label>
                                </div>
                                <input
                                    type="text"
                                    placeholder="NEWS 1 LINK"
                                    value={game3news1link || ''} // Use an empty string if title is undefined
                                    onChange={(e) => handleGame3News1LinkChange(e.target.value)}
                                /> 
                            </div>
                            {showUploads && ( // Conditionally render uploads based on state
                    <>
                        {newsUploadCount >= 3 && (
                            <div className="news-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'fit-content', gap: '10px'}}>
                                <div className='file-upload news-upload'>
                                <label>
                                    <input
                                        type="file"
                                        accept="image/*, gif, png, jpeg"
                                        onChange={handleGame3News2PicUpload} // Use the new function here
                                        style={{ display: 'none' }} // Hide the default file input
                                        />  
                                        <img
                                        src={game3news2picUploaded ? checkIcon : downloadIcon}
                                        alt="Upload News Link Cover"
                                        className="upload-icon"
                                    />
                                    <div className='upload-label-container'>
                                        <p className='upload-label'>Add a cover image</p>
                                        <p className='label-sub'>(16:9 aspect ratio)</p>
                                    </div>
                                </label>
                                </div>
                                <input
                                    type="text"
                                    placeholder="NEWS 2 LINK"
                                    value={game3news2link || ''} // Use an empty string if title is undefined
                                    onChange={(e) => handleGame3News2LinkChange(e.target.value)}
                                />
                            </div>
                        )}
                        {newsUploadCount >= 4 && (
                            <div className="news-3" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'fit-content', gap: '10px'}}>
                                <div className='file-upload news-upload'>
                                <label>
                                    <input
                                        type="file"
                                        accept="image/*, gif, png, jpeg"
                                        onChange={handleGame3News3PicUpload} // Use the new function here
                                        style={{ display: 'none' }} // Hide the default file input
                                    />  
                                    <img
                                        src={game3news3picUploaded ? checkIcon : downloadIcon}
                                        alt="Upload News Link Cover"
                                        className="upload-icon"
                                    />
                                    <div className='upload-label-container'>
                                        <p className='upload-label'>Add a cover image</p>
                                        <p className='label-sub'>(16:9 aspect ratio)</p>
                                    </div>
                                </label>
                                </div>
                                <input
                                    type="text"
                                    placeholder="NEWS 3 LINK"
                                    value={game3news3link || ''} // Use an empty string if title is undefined
                                    onChange={(e) => handleGame3News3LinkChange(e.target.value)}
                                />  
                            </div>
                        )}


                        {newsUploadCount >= 5 && (
                            <div className="news-4" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'fit-content', gap: '10px'}}>
                                <div className='file-upload news-upload'>
                                <label>
                                    <input
                                        type="file"
                                        accept="image/*, gif, png, jpeg"
                                        onChange={handleGame3News4PicUpload} // Use the new function here
                                        style={{ display: 'none' }} // Hide the default file input
                                    />  
                                    <img
                                        src={game3news4picUploaded ? checkIcon : downloadIcon}
                                        alt="Upload News Link Cover"
                                        className="upload-icon"
                                    />
                                    <div className='upload-label-container'>
                                        <p className='upload-label'>Add a cover image</p>
                                        <p className='label-sub'>(16:9 aspect ratio)</p>
                                    </div>
                                </label>
                                </div>
                                <input
                                    type="text"
                                    placeholder="NEWS 4 LINK"
                                    value={game3news4link || ''} // Use an empty string if title is undefined
                                    onChange={(e) => handleGame3News4LinkChange(e.target.value)}
                                />  
                            </div>
                        )}
                    </>
                )}
                {newsUploadCount < 5 && (
                    <img
                        src={addLinkIcon}
                        alt="Add Link"
                        className="add-link-icon"
                        onClick={handleAddNewsUpload} // Trigger adding a new news upload
                        style={{ cursor: 'pointer'}} // Style the icon
                    />
                )}
            </div>
            
            
          )}

{currentQuestion === 24 && (
            <div className="game-title-inputs" style={{ marginBottom: 'auto'}}>
              <p className="game-title-description" style={{ marginTop: '0px'}}>Add up to 4 pieces of news from your second game.</p>
                            <div className="news-1" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'fit-content', gap: '10px'}}>
                                <div className='file-upload news-upload'>
                                <label>                                    <input
                                        type="file"
                                        accept="image/*, gif, png, jpeg"
                                        onChange={handleGame4News1PicUpload} // Use the new function here
                                        style={{ display: 'none' }} // Hide the default file input
                                    />  
                                    <img
                                        src={game4news1picUploaded ? checkIcon : downloadIcon}
                                        alt="Upload News Link Cover"
                                        className="upload-icon"
                                    />
                                    <div className='upload-label-container'>
                                        <p className='upload-label'>Add a cover image</p>
                                        <p className='label-sub'>(16:9 aspect ratio)</p>
                                    </div>
                                </label>
                                </div>
                                <input
                                    type="text"
                                    placeholder="NEWS 1 LINK"
                                    value={game4news1link || ''} // Use an empty string if title is undefined
                                    onChange={(e) => handleGame4News1LinkChange(e.target.value)}
                                /> 
                            </div>
                            {showUploads && ( // Conditionally render uploads based on state
                    <>
                        {newsUploadCount >= 3 && (
                            <div className="news-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'fit-content', gap: '10px'}}>
                                <div className='file-upload news-upload'>
                                <label>
                                    <input
                                        type="file"
                                        accept="image/*, gif, png, jpeg"
                                        onChange={handleGame4News2PicUpload} // Use the new function here
                                        style={{ display: 'none' }} // Hide the default file input
                                        />  
                                        <img
                                        src={game4news2picUploaded ? checkIcon : downloadIcon}
                                        alt="Upload News Link Cover"
                                        className="upload-icon"
                                    />
                                    <div className='upload-label-container'>
                                        <p className='upload-label'>Add a cover image</p>
                                        <p className='label-sub'>(16:9 aspect ratio)</p>
                                    </div>
                                </label>
                                </div>
                                <input
                                    type="text"
                                    placeholder="NEWS 2 LINK"
                                    value={game4news2link || ''} // Use an empty string if title is undefined
                                    onChange={(e) => handleGame4News2LinkChange(e.target.value)}
                                />
                            </div>
                        )}
                        {newsUploadCount >= 4 && (
                            <div className="news-3" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'fit-content', gap: '10px'}}>
                                <div className='file-upload news-upload'>
                                <label>
                                    <input
                                        type="file"
                                        accept="image/*, gif, png, jpeg"
                                        onChange={handleGame4News3PicUpload} // Use the new function here
                                        style={{ display: 'none' }} // Hide the default file input
                                    />  
                                    <img
                                        src={game4news3picUploaded ? checkIcon : downloadIcon}
                                        alt="Upload News Link Cover"
                                        className="upload-icon"
                                    />
                                    <div className='upload-label-container'>
                                        <p className='upload-label'>Add a cover image</p>
                                        <p className='label-sub'>(16:9 aspect ratio)</p>
                                    </div>
                                </label>
                                </div>
                                <input
                                    type="text"
                                    placeholder="NEWS 3 LINK"
                                    value={game4news3link || ''} // Use an empty string if title is undefined
                                    onChange={(e) => handleGame4News3LinkChange(e.target.value)}
                                />  
                            </div>
                        )}


                        {newsUploadCount >= 5 && (
                            <div className="news-4" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'fit-content', gap: '10px'}}>
                                <div className='file-upload news-upload'>
                                <label>
                                    <input
                                        type="file"
                                        accept="image/*, gif, png, jpeg"
                                        onChange={handleGame4News4PicUpload} // Use the new function here
                                        style={{ display: 'none' }} // Hide the default file input
                                    />  
                                    <img
                                        src={game4news4picUploaded ? checkIcon : downloadIcon}
                                        alt="Upload News Link Cover"
                                        className="upload-icon"
                                    />
                                    <div className='upload-label-container'>
                                        <p className='upload-label'>Add a cover image</p>
                                        <p className='label-sub'>(16:9 aspect ratio)</p>
                                    </div>
                                </label>
                                </div>
                                <input
                                    type="text"
                                    placeholder="NEWS 4 LINK"
                                    value={game4news4link || ''} // Use an empty string if title is undefined
                                    onChange={(e) => handleGame4News4LinkChange(e.target.value)}
                                />  
                            </div>
                        )}
                    </>
                )}
                {newsUploadCount < 5 && (
                    <img
                        src={addLinkIcon}
                        alt="Add Link"
                        className="add-link-icon"
                        onClick={handleAddNewsUpload} // Trigger adding a new news upload
                        style={{ cursor: 'pointer'}} // Style the icon
                    />
                )}
            </div>
            
            
          )}
          {currentQuestion === 4 && (
            <>
              <p className="genre-question-description" style={{ marginTop: '0px'}}>Select all that apply.</p>
              <div className="genres-container genre-select-question">
                {genresList.map((genre) => (
                  <div
                    key={genre.name}
                    className={`genre-item ${game1genres.includes(genre.name) ? 'selected' : ''}`}
                    onClick={() => {
                      setGame1Genres((prevGenres) => {
                        if (prevGenres.includes(genre.name)) {
                          return prevGenres.filter((g) => g !== genre.name); // Remove genre if already selected
                        } else {
                          return [...prevGenres, genre.name]; // Add genre if not selected
                        }
                      });
                    }}
                  >
                    <img
                      src={game1genres.includes(genre.name) ? genre.selectedIcon : genre.icon}
                      alt={genre.name}
                      className="genre-icon"
                    />
                    <p className={`genre-label ${game1genres.includes(genre.name) ? 'selected-label' : ''}`}>
                      {genre.name}
                    </p>
                  </div>
                ))}
              </div>
            </>
          )}

          {currentQuestion === 10 && (
            <>
            <p className="genre-question-description" style={{ marginTop: '0px'}}>Select all that apply.</p>
            <div className="genres-container genre-select-question">
              {genresList.map((genre) => (
                <div
                  key={genre.name}
                  className={`genre-item ${game2genres.includes(genre.name) ? 'selected' : ''}`}
                  onClick={() => {
                    setGame2Genres((prevGenres) => {
                      if (prevGenres.includes(genre.name)) {
                        return prevGenres.filter((g) => g !== genre.name); // Remove genre if already selected
                      } else {
                        return [...prevGenres, genre.name]; // Add genre if not selected
                      }
                    });
                  }}
                >
                  <img
                    src={game2genres.includes(genre.name) ? genre.selectedIcon : genre.icon}
                    alt={genre.name}
                    className="genre-icon"
                  />
                  <p className={`genre-label ${game2genres.includes(genre.name) ? 'selected-label' : ''}`}>
                    {genre.name}
                  </p>
                </div>
              ))}
            </div>
          </>
        )}
          {currentQuestion === 16 && (
            <>
            <p className="genre-question-description" style={{ marginTop: '0px'}}>Select all that apply.</p>
            <div className="genres-container genre-select-question">
              {genresList.map((genre) => (
                <div
                  key={genre.name}
                  className={`genre-item ${game3genres.includes(genre.name) ? 'selected' : ''}`}
                  onClick={() => {
                    setGame3Genres((prevGenres) => {
                      if (prevGenres.includes(genre.name)) {
                        return prevGenres.filter((g) => g !== genre.name); // Remove genre if already selected
                      } else {
                        return [...prevGenres, genre.name]; // Add genre if not selected
                      }
                    });
                  }}
                >
                  <img
                    src={game3genres.includes(genre.name) ? genre.selectedIcon : genre.icon}
                    alt={genre.name}
                    className="genre-icon"
                  />
                  <p className={`genre-label ${game3genres.includes(genre.name) ? 'selected-label' : ''}`}>
                    {genre.name}
                  </p>
                </div>
              ))}
            </div>
          </>
        )}
          {currentQuestion === 22 && (
            <>
            <p className="genre-question-description" style={{ marginTop: '0px'}}>Select all that apply.</p>
            <div className="genres-container genre-select-question">
              {genresList.map((genre) => (
                <div
                  key={genre.name}
                  className={`genre-item ${game4genres.includes(genre.name) ? 'selected' : ''}`}
                  onClick={() => {
                    setGame4Genres((prevGenres) => {
                      if (prevGenres.includes(genre.name)) {
                        return prevGenres.filter((g) => g !== genre.name); // Remove genre if already selected
                      } else {
                        return [...prevGenres, genre.name]; // Add genre if not selected
                      }
                    });
                  }}
                >
                  <img
                    src={game4genres.includes(genre.name) ? genre.selectedIcon : genre.icon}
                    alt={genre.name}
                    className="genre-icon"
                  />
                  <p className={`genre-label ${game4genres.includes(genre.name) ? 'selected-label' : ''}`}>
                    {genre.name}
                  </p>
                </div>
              ))}
            </div>
          </>
        )}
          {currentQuestion === 1 && (
            <div className='region-container'>
              <select className='region-select' value={selectedRegion} onChange={handleRegionChange}>
                <option value="">Select a region</option>
                <option value="NA">North America</option>
                <option value="EU">Europe</option>
                <option value="EAS">East Asia</option>
                <option value="SA">South America</option>
                <option value="AU">Australia and PI</option>
                <option value="CAS">Central Asia</option>
                <option value="ME">Middle East</option>
                <option value="NAF">North Africa</option>
                <option value="RU">Russia</option>
                <option value="SAF">Sub-Saharan Africa</option>
                <option value="SAS">South Asia</option>
                <option value="SEA">South-East Asia</option>
                <option value="CA">Central America</option>
                {/* Add other regions as options */}
              </select>
              <img className='world-map' src={map} alt="World Map" onClick={handleMapClick} />
              

              
              {/* username clicked coordinates */}
              <div>
                <p className='clicked-coordinates'>Clicked Coordinates: X: {clickedCoordinates.x}, Y: {clickedCoordinates.y}</p>
              </div>
            </div>
          )}
          {currentQuestion !== 1 && currentQuestion !== 2 && currentQuestion !== 3 && currentQuestion !== 4 && currentQuestion !== 5 && currentQuestion !== 6 && currentQuestion !== 7 && currentQuestion !== 8 && currentQuestion !== 9 && currentQuestion !== 10 && currentQuestion !== 11 && currentQuestion !== 12 && currentQuestion !== 13 && currentQuestion !== 14 && currentQuestion !== 15 && currentQuestion !== 16 && currentQuestion !== 17 && currentQuestion !== 18 && currentQuestion !== 19 && currentQuestion !== 20 && currentQuestion !== 21 && currentQuestion !== 22 && currentQuestion !== 23 && currentQuestion !== 24 && currentQuestion !== 25 && currentQuestion !== 26 &&( // Exclude question 4
            <div className="input-container">
              <input 
                type="text"
                value={questions[currentQuestion].answer || ''}
                onChange={handleDisplayNameChange}
                placeholder="STUDIO USERNAME"
                required  
                className={`input 
                    ${currentQuestion === 0 && questions[currentQuestion].answer && isUsernameUnique ? 'unique' : ''} 
                    ${currentQuestion === 0 && !isUsernameUnique ? 'error' : ''} 
                    ${currentQuestion === 0 && !questions[currentQuestion].answer ? 'empty' : ''}`}
              />  
              {currentQuestion === 0 && !isUsernameUnique && (
                  <p className="username-error-message">This username is taken</p>
              )}
            </div>
          )}
          {currentQuestion === 2 && (
            <div className="question-five-container question-two-container">
              <div className="bio-container">
                <textarea
                  placeholder="Share a bit about your company and complete your bio!"
                  className="bio-input"
                  value={studioBio} // Bind the textarea value to studioBio state
                  onChange={(e) => setStudioBio(e.target.value)}
                />
              </div>
              <div className="upload-container">
                <div className="file-upload">
                  <label style={{ display: 'flex', flexDirection: 'row'}}>
                    <input
                      type="file"
                      accept="image/*, gif, png, jpeg"
                      onChange={handleStudioProfilePicUpload}
                      style={{ display: 'none' }} // Hide the default file input
                    />  
                    <img
                      src={studioProfilePicUploaded ? checkIcon : downloadIcon}
                      alt="Upload Profile Pic"
                      className="upload-icon"
                    />
                    <div className='upload-label-container'>
                      <p className='upload-label'>Add Your Logo</p>
                      <p className='label-sub'>(800 x 800 for best results)</p>
                    </div>
                  </label>
                </div>
                <div className="file-upload">
                  <label style={{ display: 'flex', flexDirection: 'row'}}>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleStudioProfileBannerUpload}
                      style={{ display: 'none' }} // Hide the default file input
                    />
                    <img
                      src={studioProfileBannerUploaded ? checkIcon : downloadIcon}
                      alt="Upload Profile Banner"
                      className="upload-icon"
                    />
                    <div className='upload-label-container'>  
                      <p className='upload-label'>Add Profile Banner</p>
                      <p className='label-sub'>(1500 x 500 for best results)</p>
                    </div>
                  </label>
                </div>
                <div className="link-inputs">
                  <p className='upload-label'>Add Studio Resources</p>
                  <p className='upload-label-sub'>add up to 4 official links</p>
                  {Array.from({ length: linkCount }, (_, index) => (
                    <div key={index} className="link-input-container">
                      <input
                        type="text"
                        placeholder={`Link ${index + 1}`}
                        onChange={(e) => handleLinkChange(e, index)}
                      />
                      {index > 0 && ( // Only show the remove icon if it's not the first link
                        <span
                          className="remove-link-icon"
                          onClick={handleRemoveLink}
                          style={{ cursor: 'pointer'}} // Increased width
                        >
                          -
                        </span>
                      )}
                    </div>
                  ))}
                  {linkCount < 4 && ( // Only show the add link icon if less than 5 links
                    <img
                      src={addLinkIcon}
                      alt="Add Link"
                      className="add-link-icon"
                      onClick={handleAddLink} // Trigger adding a new link input
                      style={{ cursor: 'pointer'}} // Style the icon
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          {currentQuestion === 3 && (
            <div className="game-title-inputs" style={{ marginBottom: 'auto'}}>
                <input
                    type="text"
                    placeholder="GAME TITLE"
                    value={game1title || ''} // Use an empty string if title is undefined
                    onChange={(e) => handleGame1TitleChange(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="GAME WEBSITE"
                    value={game1website || ''} // Use an empty string if website is undefined
                    onChange={(e) => handleGame1WebsiteChange(e.target.value)} // Validate URL on change
                />
                <select
                    value={game1developmentStage}
                    onChange={(e) => handleGame1DevelopmentStageChange(e.target.value)}
                    className="input"
                    style={{ marginBottom: '5px'}}
                >
                    <option value="">Development Stage</option>
                    <option value="Coming Soon">Coming Soon</option>
                    <option value="Prototype">Prototype</option>
                    <option value="Closed Testing">Closed Testing</option>
                    <option value="Early Access">Early Access</option>
                    <option value="Open Alpha">Open Alpha</option>
                    <option value="Closed Alpha">Closed Alpha</option>
                    <option value="Open Beta">Open Beta</option>
                    <option value="Closed Beta">Closed Beta</option>
                    <option value="Full Game">Full Game</option>
                </select>
                <div className="platform-selection-container">
                    <p style={{ margin: '5px 0px 10px 0px', textAlign: 'center'}}>Compatible Platforms</p>
                    <div className="platform-icons">
                        {['Windows', 'Mac', 'Android', 'iOS'].map((platform) => (
                            <div
                                key={platform}
                                className={`platform-item ${game1platforms.includes(platform) ? 'selected' : ''}`}
                                onClick={() => handleGame1PlatformsChange(platform)}
                                onMouseEnter={() => setHoveredPlatform(platform)}
                                onMouseLeave={() => setHoveredPlatform(null)}
                            >
                                <img
                                    src={
                                        (hoveredPlatform === platform || game1platforms.includes(platform)) 
                                            ? (platform === 'Windows' ? windowsIconLight :
                                               platform === 'Mac' ? macosIconLight :
                                               platform === 'Android' ? gplayIconLight :  // Use light versions on hover or selected
                                               platform === 'iOS' ? iosIconLight : null) // Use light versions on hover or selected
                                            : (platform === 'Windows' ? windowsIcon :
                                               platform === 'Mac' ? macosIcon :
                                               platform === 'Android' ? gplayIcon :
                                               platform === 'iOS' ? iosIcon : null) // Default to original versions
                                    
                                              }
                                    alt={platform}
                                    className="platform-icon"
                                    style={{ cursor: 'pointer', height: '50px', width: '50px'}}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
          )}
          {currentQuestion === 9 && (
            <>
                <div className="game-title-inputs" style={{ marginBottom: 'auto'}}>
                <input
                    type="text"
                    placeholder="GAME TITLE"
                    value={game2title || ''} // Use an empty string if title is undefined
                    onChange={(e) => handleGame2TitleChange(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="GAME WEBSITE"
                    value={game2website || ''} // Use an empty string if website is undefined
                    onChange={(e) => handleGame2WebsiteChange(e.target.value)}
                />
                <select
                    value={game2developmentStage}
                    onChange={(e) => handleGame2DevelopmentStageChange(e.target.value)}
                    className="input"
                >
                    <option value="">Development Stage</option>
                    <option value="Coming Soon">Coming Soon</option>
                    <option value="Prototype">Prototype</option>
                    <option value="Closed Testing">Closed Testing</option>
                    <option value="Early Access">Early Access</option>
                    <option value="Open Alpha">Open Alpha</option>
                    <option value="Closed Alpha">Closed Alpha</option>
                    <option value="Open Beta">Open Beta</option>
                    <option value="Closed Beta">Closed Beta</option>
                    <option value="Full Game">Full Game</option>
                </select>
                <div className="platform-selection-container">
                    <p style={{ margin: '5px 0px 10px 0px', textAlign: 'center'}}>Compatible Platforms</p>
                    <div className="platform-icons">
                        {['Windows', 'Mac', 'Android', 'iOS'].map((platform) => (
                            <div
                                key={platform}
                                className={`platform-item ${game2platforms.includes(platform) ? 'selected' : ''}`}
                                onClick={() => handleGame2PlatformsChange(platform)}
                                onMouseEnter={() => setHoveredPlatform(platform)}
                                onMouseLeave={() => setHoveredPlatform(null)}
                            >
                                <img
                                    src={
                                        (hoveredPlatform === platform || game2platforms.includes(platform)) 
                                            ? (platform === 'Windows' ? windowsIconLight :
                                               platform === 'Mac' ? macosIconLight :
                                               platform === 'Android' ? gplayIconLight :  // Use light versions on hover or selected
                                               platform === 'iOS' ? iosIconLight : null) // Use light versions on hover or selected
                                            : (platform === 'Windows' ? windowsIcon :
                                               platform === 'Mac' ? macosIcon :
                                               platform === 'Android' ? gplayIcon :
                                               platform === 'iOS' ? iosIcon : null) // Default to original versions
                                    
                                              }
                                    alt={platform}
                                    className="platform-icon"
                                    style={{ cursor: 'pointer', height: '50px', width: '50px'}}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            </>
          )}
          {currentQuestion === 15 && (
            <div className="game-title-inputs" style={{ marginBottom: 'auto'}}>
            <input
                type="text"
                placeholder="GAME TITLE"
                value={game3title || ''} // Use an empty string if title is undefined
                onChange={(e) => handleGame3TitleChange(e.target.value)}
            />
            <input
                type="text"
                placeholder="GAME WEBSITE"
                value={game3website || ''} // Use an empty string if website is undefined
                onChange={(e) => handleGame3WebsiteChange(e.target.value)}
            />
            <select
                value={game3developmentStage}
                onChange={(e) => handleGame3DevelopmentStageChange(e.target.value)}
                className="input"
            >
                <option value="">Development Stage</option>
                <option value="Coming Soon">Coming Soon</option>
                <option value="Prototype">Prototype</option>
                <option value="Closed Testing">Closed Testing</option>
                <option value="Early Access">Early Access</option>
                <option value="Open Alpha">Open Alpha</option>
                <option value="Closed Alpha">Closed Alpha</option>
                <option value="Open Beta">Open Beta</option>
                <option value="Closed Beta">Closed Beta</option>
                <option value="Full Game">Full Game</option>
            </select>
            <div className="platform-selection-container">
                    <p style={{ margin: '5px 0px 10px 0px', textAlign: 'center'}}>Compatible Platforms</p>
                    <div className="platform-icons">
                        {['Windows', 'Mac', 'Android', 'iOS'].map((platform) => (
                            <div
                                key={platform}
                                className={`platform-item ${game3platforms.includes(platform) ? 'selected' : ''}`}
                                onClick={() => handleGame3PlatformsChange(platform)}
                                onMouseEnter={() => setHoveredPlatform(platform)}
                                onMouseLeave={() => setHoveredPlatform(null)}
                            >
                                <img
                                    src={
                                        (hoveredPlatform === platform || game3platforms.includes(platform)) 
                                            ? (platform === 'Windows' ? windowsIconLight :
                                               platform === 'Mac' ? macosIconLight :
                                               platform === 'Android' ? gplayIconLight :  // Use light versions on hover or selected
                                               platform === 'iOS' ? iosIconLight : null) // Use light versions on hover or selected
                                            : (platform === 'Windows' ? windowsIcon :
                                               platform === 'Mac' ? macosIcon :
                                               platform === 'Android' ? gplayIcon :
                                               platform === 'iOS' ? iosIcon : null) // Default to original versions
                                    
                                              }
                                    alt={platform}
                                    className="platform-icon"
                                    style={{ cursor: 'pointer', height: '50px', width: '50px'}}
                                />
                            </div>
                        ))}
                    </div>
                </div>
        </div>
          )}
          {currentQuestion === 21 && (
            <div className="game-title-inputs" style={{ marginBottom: 'auto'}}>
            <input
                type="text"
                placeholder="GAME TITLE"
                value={game4title || ''} // Use an empty string if title is undefined
                onChange={(e) => handleGame4TitleChange(e.target.value)}
            />
            <input
                type="text"
                placeholder="GAME WEBSITE"
                value={game4website || ''} // Use an empty string if website is undefined
                onChange={(e) => handleGame4WebsiteChange(e.target.value)}
            />
            <select
                value={game4developmentStage}
                onChange={(e) => handleGame4DevelopmentStageChange(e.target.value)}
                className="input"
            >
                <option value="">Development Stage</option>
                <option value="Coming Soon">Coming Soon</option>
                <option value="Prototype">Prototype</option>
                <option value="Closed Testing">Closed Testing</option>
                <option value="Early Access">Early Access</option>
                <option value="Open Alpha">Open Alpha</option>
                <option value="Closed Alpha">Closed Alpha</option>
                <option value="Open Beta">Open Beta</option>
                <option value="Closed Beta">Closed Beta</option>
                <option value="Full Game">Full Game</option>
            </select>
            <div className="platform-selection-container">
                    <p style={{ margin: '5px 0px 10px 0px', textAlign: 'center'}}>Compatible Platforms</p>
                    <div className="platform-icons">
                        {['Windows', 'Mac', 'Android', 'iOS'].map((platform) => (
                            <div
                                key={platform}
                                className={`platform-item ${game4platforms.includes(platform) ? 'selected' : ''}`}
                                onClick={() => handleGame4PlatformsChange(platform)}
                                onMouseEnter={() => setHoveredPlatform(platform)}
                                onMouseLeave={() => setHoveredPlatform(null)}
                            >
                                <img
                                    src={
                                        (hoveredPlatform === platform || game4platforms.includes(platform)) 
                                            ? (platform === 'Windows' ? windowsIconLight :
                                               platform === 'Mac' ? macosIconLight :
                                               platform === 'Android' ? gplayIconLight :  // Use light versions on hover or selected
                                               platform === 'iOS' ? iosIconLight : null) // Use light versions on hover or selected
                                            : (platform === 'Windows' ? windowsIcon :
                                               platform === 'Mac' ? macosIcon :
                                               platform === 'Android' ? gplayIcon :
                                               platform === 'iOS' ? iosIcon : null) // Default to original versions
                                    
                                              }
                                    alt={platform}
                                    className="platform-icon"
                                    style={{ cursor: 'pointer', height: '50px', width: '50px'}}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
          )}
          {currentQuestion === 7 && (
            <div className="question-five-container game-description-container">
              <div className="bio-container">
                <textarea
                  placeholder="Share a description of your game and what makes it unique! "
                  className="bio-input"
                  onChange={(e) => setGame1Description(e.target.value)}
                />
              </div>
                <div className="upload-container">
                <div className="file-upload">
                  <label style={{ display: 'flex', flexDirection: 'row'}}>
                    <input
                      type="file"
                      accept="image/*, gif, png, jpeg"
                      onChange={handleGame1ProfilePicUpload}
                      style={{ display: 'none' }} // Hide the default file input
                    />  
                    <img
                      src={game1ProfilePicUploaded ? checkIcon : downloadIcon}
                      alt="Upload Profile Pic"
                      className="upload-icon"
                    />
                    <div className='upload-label-container'>
                      <p className='upload-label'>Add Logo or PFP</p>
                      <p className='label-sub'>(800 x 800 for best results)</p>
                    </div>
                  </label>
                </div>
                <div className="file-upload">
                  <label style={{ display: 'flex', flexDirection: 'row'}}>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleGame1ProfileBannerUpload}
                      style={{ display: 'none' }} // Hide the default file input
                    />
                    <img
                      src={game1ProfileBannerUploaded ? checkIcon : downloadIcon}
                      alt="Upload Profile Banner"
                      className="upload-icon"
                    />
                    <div className='upload-label-container'>  
                      <p className='upload-label'>Add Profile Banner</p>
                      <p className='label-sub'>(1500 x 500 for best results)</p>
                    </div>
                  </label>
                </div>
                <div className='game-content-container' style={{ gap: '10px'}}>
                  <p className='upload-label'>Add Game Content</p>
                  <p className='upload-label-sub'>Up to 5 videos or screenshots</p>
                  <div className='content-upload-container'>
                  <div className='game-content-item'>
                  <label style={{ display: 'flex', flexDirection: 'row'}}>
                    <input
                      type="file"
                      accept="image/*, gif, png, jpeg"
                      onChange={handleGame1ContentUpload1}
                      style={{ display: 'none' }} // Hide the default file input
                    />  
                    <img
                      src={game1Content1Uploaded ? checkIcon : downloadIcon}
                      alt="Upload Game Content"
                      className="upload-icon"
                    />
                  </label>
                  </div>
                    <div className='game-content-item'>
                      <label style={{ display: 'flex', flexDirection: 'row'}}>
                    <input
                      type="file"
                      accept="image/*, gif, png, jpeg"
                      onChange={handleGame1ContentUpload2}
                      style={{ display: 'none' }} // Hide the default file input
                    />  
                    <img
                      src={game1Content2Uploaded ? checkIcon : downloadIcon}
                      alt="Upload Game Content"
                      className="upload-icon"
                    />
                  </label>
                  </div>
                  <div className='game-content-item'>
                  <label style={{ display: 'flex', flexDirection: 'row'}}>
                    <input
                      type="file"
                      accept="image/*, gif, png, jpeg"
                      onChange={handleGame1ContentUpload3}
                      style={{ display: 'none' }} // Hide the default file input
                    />  
                    <img
                      src={game1Content3Uploaded ? checkIcon : downloadIcon}
                      alt="Upload Game Content"
                      className="upload-icon"
                    />
                  </label>
                  </div>
                  <div className='game-content-item'>
                  <label style={{ display: 'flex', flexDirection: 'row'}}>
                    <input
                      type="file"
                      accept="image/*, gif, png, jpeg"
                      onChange={handleGame1ContentUpload4}
                      style={{ display: 'none' }} // Hide the default file input
                    />  
                    <img
                      src={game1Content4Uploaded ? checkIcon : downloadIcon}
                      alt="Upload Game Content"
                      className="upload-icon"
                    />
                  </label>
                  </div>
                  <div className='game-content-item'>
                  <label style={{ display: 'flex', flexDirection: 'row'}}>
                    <input
                      type="file"
                      accept="image/*, gif, png, jpeg"
                      onChange={handleGame1ContentUpload5}
                      style={{ display: 'none' }} // Hide the default file input
                    />  
                    <img
                      src={game1Content5Uploaded ? checkIcon : downloadIcon}
                      alt="Upload Game Content"
                      className="upload-icon"
                    />
                  </label>
                  </div>
                  </div>
                </div>
                
              </div>
            </div>
          )}

          {currentQuestion === 13 && (
            <div className="question-five-container game-description-container">
            <div className="bio-container">
              <textarea
                placeholder="Share a description of your game and what makes it unique! "
                className="bio-input"
                onChange={(e) => setGame2Description(e.target.value)}
              />
            </div>
              <div className="upload-container">
              <div className="file-upload">
                <label style={{ display: 'flex', flexDirection: 'row'}}>
                  <input
                    type="file"
                    accept="image/*, gif, png, jpeg"
                    onChange={handleGame2ProfilePicUpload}
                    style={{ display: 'none' }} // Hide the default file input
                  />  
                  <img
                    src={game2ProfilePicUploaded ? checkIcon : downloadIcon}
                    alt="Upload Profile Pic"
                    className="upload-icon"
                  />
                  <div className='upload-label-container'>
                    <p className='upload-label'>Add Logo or PFP</p>
                    <p className='label-sub'>(800 x 800 for best results)</p>
                  </div>
                </label>
              </div>
              <div className="file-upload">
                <label style={{ display: 'flex', flexDirection: 'row'}}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleGame2ProfileBannerUpload}
                    style={{ display: 'none' }} // Hide the default file input
                  />
                  <img
                    src={game2ProfileBannerUploaded ? checkIcon : downloadIcon}
                    alt="Upload Profile Banner"
                    className="upload-icon"
                  />
                  <div className='upload-label-container'>  
                    <p className='upload-label'>Add Profile Banner</p>
                    <p className='label-sub'>(1500 x 500 for best results)</p>
                  </div>
                </label>
              </div>
              <div className='game-content-container' style={{ gap: '10px'}}>
              <p className='upload-label'>Add Game Content</p>
              <p className='upload-label-sub'>Up to 5 videos or screenshots</p>
                <div className='content-upload-container'>
                <div className='game-content-item'>
                <label style={{ display: 'flex', flexDirection: 'row'}}>
                  <input
                    type="file"
                    accept="image/*, gif, png, jpeg"
                    onChange={handleGame2ContentUpload1}
                    style={{ display: 'none' }} // Hide the default file input
                  />  
                  <img
                    src={game2Content1Uploaded ? checkIcon : downloadIcon}
                    alt="Upload Game Content"
                    className="upload-icon"
                  />
                </label>
                </div>
                  <div className='game-content-item'>
                    <label style={{ display: 'flex', flexDirection: 'row'}}>
                  <input
                    type="file"
                    accept="image/*, gif, png, jpeg"
                    onChange={handleGame2ContentUpload2}
                    style={{ display: 'none' }} // Hide the default file input
                  />  
                  <img
                    src={game2Content2Uploaded ? checkIcon : downloadIcon}
                    alt="Upload Game Content"
                    className="upload-icon"
                  />
                </label>
                </div>
                <div className='game-content-item'>
                <label style={{ display: 'flex', flexDirection: 'row'}}>
                  <input
                    type="file"
                    accept="image/*, gif, png, jpeg"
                    onChange={handleGame2ContentUpload3}
                    style={{ display: 'none' }} // Hide the default file input
                  />  
                  <img
                    src={game2Content3Uploaded ? checkIcon : downloadIcon}
                    alt="Upload Game Content"
                    className="upload-icon"
                  />
                </label>
                </div>
                <div className='game-content-item'>
                <label style={{ display: 'flex', flexDirection: 'row'}}>
                  <input
                    type="file"
                    accept="image/*, gif, png, jpeg"
                    onChange={handleGame2ContentUpload4}
                    style={{ display: 'none' }} // Hide the default file input
                  />  
                  <img
                    src={game2Content4Uploaded ? checkIcon : downloadIcon}
                    alt="Upload Game Content"
                    className="upload-icon"
                  />
                </label>
                </div>
                <div className='game-content-item'>
                <label style={{ display: 'flex', flexDirection: 'row'}}>
                  <input
                    type="file"
                    accept="image/*, gif, png, jpeg"
                    onChange={handleGame2ContentUpload5}
                    style={{ display: 'none' }} // Hide the default file input
                  />  
                  <img
                    src={game2Content5Uploaded ? checkIcon : downloadIcon}
                    alt="Upload Game Content"
                    className="upload-icon"
                  />
                </label>
                </div>
                </div>
              </div>
              
            </div>
          </div>
        )}
          {currentQuestion === 19 && (
            <div className="question-five-container game-description-container">
            <div className="bio-container">
              <textarea
                placeholder="Share a description of your game and what makes it unique! "
                className="bio-input"
                onChange={(e) => setGame3Description(e.target.value)}
              />
            </div>
              <div className="upload-container">
              <div className="file-upload">
                <label style={{ display: 'flex', flexDirection: 'row'}}>
                  <input
                    type="file"
                    accept="image/*, gif, png, jpeg"
                    onChange={handleGame3ProfilePicUpload}
                    style={{ display: 'none' }} // Hide the default file input
                  />  
                  <img
                    src={game3ProfilePicUploaded ? checkIcon : downloadIcon}
                    alt="Upload Profile Pic"
                    className="upload-icon"
                  />
                  <div className='upload-label-container'>
                    <p className='upload-label'>Add Logo or PFP</p>
                    <p className='label-sub'>(800 x 800 for best results)</p>
                  </div>
                </label>
              </div>
              <div className="file-upload">
                <label style={{ display: 'flex', flexDirection: 'row'}}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleGame3ProfileBannerUpload}
                    style={{ display: 'none' }} // Hide the default file input
                  />
                  <img
                    src={game3ProfileBannerUploaded ? checkIcon : downloadIcon}
                    alt="Upload Profile Banner"
                    className="upload-icon"
                  />
                  <div className='upload-label-container'>  
                    <p className='upload-label'>Add Profile Banner</p>
                    <p className='label-sub'>(1500 x 500 for best results)</p>
                  </div>
                </label>
              </div>
              <div className='game-content-container' style={{ gap: '10px'}}>
              <p className='upload-label'>Add Game Content</p>
              <p className='upload-label-sub'>Up to 5 videos or screenshots</p>
                <div className='content-upload-container'>
                <div className='game-content-item'>
                <label style={{ display: 'flex', flexDirection: 'row'}}>
                  <input
                    type="file"
                    accept="image/*, gif, png, jpeg"
                    onChange={handleGame3ContentUpload1}
                    style={{ display: 'none' }} // Hide the default file input
                  />  
                  <img
                    src={game3Content1Uploaded ? checkIcon : downloadIcon}
                    alt="Upload Game Content"
                    className="upload-icon"
                  />
                </label>
                </div>
                  <div className='game-content-item'>
                    <label style={{ display: 'flex', flexDirection: 'row'}}>
                  <input
                    type="file"
                    accept="image/*, gif, png, jpeg"
                    onChange={handleGame3ContentUpload2}
                    style={{ display: 'none' }} // Hide the default file input
                  />  
                  <img
                    src={game3Content2Uploaded ? checkIcon : downloadIcon}
                    alt="Upload Game Content"
                    className="upload-icon"
                  />
                </label>
                </div>
                <div className='game-content-item'>
                <label style={{ display: 'flex', flexDirection: 'row'}}>
                  <input
                    type="file"
                    accept="image/*, gif, png, jpeg"
                    onChange={handleGame3ContentUpload3}
                    style={{ display: 'none' }} // Hide the default file input
                  />  
                  <img
                    src={game3Content3Uploaded ? checkIcon : downloadIcon}
                    alt="Upload Game Content"
                    className="upload-icon"
                  />
                </label>
                </div>
                <div className='game-content-item'>
                <label style={{ display: 'flex', flexDirection: 'row'}}>
                  <input
                    type="file"
                    accept="image/*, gif, png, jpeg"
                    onChange={handleGame3ContentUpload4}
                    style={{ display: 'none' }} // Hide the default file input
                  />  
                  <img
                    src={game3Content4Uploaded ? checkIcon : downloadIcon}
                    alt="Upload Game Content"
                    className="upload-icon"
                  />
                </label>
                </div>
                <div className='game-content-item'>
                <label style={{ display: 'flex', flexDirection: 'row'}}>
                  <input
                    type="file"
                    accept="image/*, gif, png, jpeg"
                    onChange={handleGame3ContentUpload5}
                    style={{ display: 'none' }} // Hide the default file input
                  />  
                  <img
                    src={game3Content5Uploaded ? checkIcon : downloadIcon}
                    alt="Upload Game Content"
                    className="upload-icon"
                  />
                </label>
                </div>
                </div>
              </div>
              
            </div>
          </div>
        )}
          {currentQuestion === 25 && (
            <div className="question-five-container game-description-container">
            <div className="bio-container">
              <textarea
                placeholder="Share a description of your game and what makes it unique! "
                className="bio-input"
                onChange={(e) => setGame4Description(e.target.value)}
              />
            </div>
              <div className="upload-container">
              <div className="file-upload">
                <label style={{ display: 'flex', flexDirection: 'row'}}>
                  <input
                    type="file"
                    accept="image/*, gif, png, jpeg"
                    onChange={handleGame4ProfilePicUpload}
                    style={{ display: 'none' }} // Hide the default file input
                  />  
                  <img
                    src={game4ProfilePicUploaded ? checkIcon : downloadIcon}
                    alt="Upload Profile Pic"
                    className="upload-icon"
                  />
                  <div className='upload-label-container'>
                    <p className='upload-label'>Add Logo or PFP</p>
                    <p className='label-sub'>(800 x 800 for best results)</p>
                  </div>
                </label>
              </div>
              <div className="file-upload">
                <label style={{ display: 'flex', flexDirection: 'row'}}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleGame4ProfileBannerUpload}
                    style={{ display: 'none' }} // Hide the default file input
                  />
                  <img
                    src={game4ProfileBannerUploaded ? checkIcon : downloadIcon}
                    alt="Upload Profile Banner"
                    className="upload-icon"
                  />
                  <div className='upload-label-container'>  
                    <p className='upload-label'>Add Profile Banner</p>
                    <p className='label-sub'>(1500 x 500 for best results)</p>
                  </div>
                </label>
              </div>
              <div className='game-content-container' style={{ gap: '10px'}}>
              <p className='upload-label'>Add Game Content</p>
              <p className='upload-label-sub'>Up to 5 videos or screenshots</p>
                <div className='content-upload-container'>
                <div className='game-content-item'>
                <label style={{ display: 'flex', flexDirection: 'row'}}>
                  <input
                    type="file"
                    accept="image/*, gif, png, jpeg"
                    onChange={handleGame4ContentUpload1}
                    style={{ display: 'none' }} // Hide the default file input
                  />  
                  <img
                    src={game4Content1Uploaded ? checkIcon : downloadIcon}
                    alt="Upload Game Content"
                    className="upload-icon"
                  />
                </label>
                </div>
                  <div className='game-content-item'>
                    <label style={{ display: 'flex', flexDirection: 'row'}}>
                  <input
                    type="file"
                    accept="image/*, gif, png, jpeg"
                    onChange={handleGame4ContentUpload2}
                    style={{ display: 'none' }} // Hide the default file input
                  />  
                  <img
                    src={game4Content2Uploaded ? checkIcon : downloadIcon}
                    alt="Upload Game Content"
                    className="upload-icon"
                  />
                </label>
                </div>
                <div className='game-content-item'>
                <label style={{ display: 'flex', flexDirection: 'row'}}>
                  <input
                    type="file"
                    accept="image/*, gif, png, jpeg"
                    onChange={handleGame4ContentUpload3}
                    style={{ display: 'none' }} // Hide the default file input
                  />  
                  <img
                    src={game4Content3Uploaded ? checkIcon : downloadIcon}
                    alt="Upload Game Content"
                    className="upload-icon"
                  />
                </label>
                </div>
                <div className='game-content-item'>
                <label style={{ display: 'flex', flexDirection: 'row'}}>
                  <input
                    type="file"
                    accept="image/*, gif, png, jpeg"
                    onChange={handleGame4ContentUpload4}
                    style={{ display: 'none' }} // Hide the default file input
                  />  
                  <img
                    src={game4Content4Uploaded ? checkIcon : downloadIcon}
                    alt="Upload Game Content"
                    className="upload-icon"
                  />
                </label>
                </div>
                <div className='game-content-item'>
                <label style={{ display: 'flex', flexDirection: 'row'}}>
                  <input
                    type="file"
                    accept="image/*, gif, png, jpeg"
                    onChange={handleGame4ContentUpload5}
                    style={{ display: 'none' }} // Hide the default file input
                  />  
                  <img
                    src={game4Content5Uploaded ? checkIcon : downloadIcon}
                    alt="Upload Game Content"
                    className="upload-icon"
                  />
                </label>
                </div>
                </div>
              </div>
              
            </div>
          </div>
        )}
          {currentQuestion === 8 && (
            <div className='question-seven-container'>
              <div className='decision-container'>
                <div 
                  className='decision-yes' 
                  style={{ cursor: 'pointer'}}
                  onMouseEnter={() => setHoveredDecision('yes')}
                  onMouseLeave={() => setHoveredDecision(null)}
                  onClick={() => {
                    setAddAnotherGame('yes'); // Update the state for adding another game
                    setCurrentQuestion(9); // Redirect to question 8
                  }}
                >
                  <img src={hoveredDecision === 'yes' ? yesSelectedIcon : yesIcon} alt="Yes" className="decision-icon" />
                  <h2 className='decision-label'>Add Another Game</h2>
                </div>
                <div 
                  className='decision-no' 
                  style={{ cursor: 'pointer'}}
                  onMouseEnter={() => setHoveredDecision('no')}
                  onMouseLeave={() => setHoveredDecision(null)}
                  onClick={(e) => {
                    handleSubmit(e); // Submit the form without an event
                  }}
                >
                  <img src={hoveredDecision === 'no' ? noSelectedIcon : noIcon} alt="No" className="decision-icon" />
                  <h2 className='decision-label'>Build My Profile</h2>
                </div>
              </div>
            </div>
          )}
          {currentQuestion === 14 && (
            <div className='question-twelve-container'>
              <div className='decision-container'>
                <div 
                  className='decision-yes' 
                  style={{ cursor: 'pointer'}}
                  onMouseEnter={() => setHoveredDecision('yes')}
                  onMouseLeave={() => setHoveredDecision(null)}
                  onClick={() => {
                    setAddAnotherGame('yes'); // Update the state for adding another game
                    setCurrentQuestion(15); // Redirect to question 13
                  }}
                >
                  <img src={hoveredDecision === 'yes' ? yesSelectedIcon : yesIcon} alt="Yes" className="decision-icon" />
                  <h2 className='decision-label'>Add Another Game</h2>
                </div>
                <div 
                  className='decision-no' 
                  style={{ cursor: 'pointer'}}
                  onMouseEnter={() => setHoveredDecision('no')}
                  onMouseLeave={() => setHoveredDecision(null)}
                  onClick={(e) => {
                    handleSubmit(e); // Submit the form without an event
                  }}
                >
                  <img src={hoveredDecision === 'no' ? noSelectedIcon : noIcon} alt="No" className="decision-icon" />
                  <h2 className='decision-label'>Build My Profile</h2>
                </div>
              </div>
            </div>
          )}
          {currentQuestion === 20 && (
            <div className='question-seventeen-container'>
              <div className='decision-container'>
                <div 
                  className='decision-yes' 
                  style={{ cursor: 'pointer'}}
                  onMouseEnter={() => setHoveredDecision('yes')}
                  onMouseLeave={() => setHoveredDecision(null)}
                  onClick={() => {
                    setAddAnotherGame('yes'); // Update the state for adding another game
                    setCurrentQuestion(21); // Redirect to question 18
                  }}
                >
                  <img src={hoveredDecision === 'yes' ? yesSelectedIcon : yesIcon} alt="Yes" className="decision-icon" />
                  <h2 className='decision-label'>Add Another Game</h2>
                </div>
                <div 
                  className='decision-no' 
                  style={{ cursor: 'pointer'}}
                  onMouseEnter={() => setHoveredDecision('no')}
                  onMouseLeave={() => setHoveredDecision(null)}
                  onClick={(e) => {
                    handleSubmit(e); // Submit the form without an event
                  }}
                >
                  <img src={hoveredDecision === 'no' ? noSelectedIcon : noIcon} alt="No" className="decision-icon" />
                  <h2 className='decision-label'>Build My Profile</h2>
                </div>
              </div>
            </div>
          )}
          {currentQuestion === 26 && (
            <div className='question-seven-container'>
                <div className='decision-container'>
                    <div 
                        className='decision-no' 
                        style={{ cursor: 'pointer'}}
                        onMouseEnter={() => setHoveredDecision('no')}
                        onMouseLeave={() => setHoveredDecision(null)} 
                        onClick={(e) => {
                            handleSubmit(e); // Pass the event object to handleSubmit
                        }}
                    >
                  <img src={hoveredDecision === 'no' ? noSelectedIcon : noIcon} alt="No" className="decision-icon" />
                  <h2 className='decision-label'>Build My Profile</h2>
                    </div>
                </div>
            </div>
          )}
          </div>
          </CSSTransition>
          <form className='form-container' onSubmit={handleNext}>
            <div className='creator-typeform-buttons'>
              <button type="button" className="back-button" onClick={handleBack} disabled={currentQuestion === 0} >
                <img src={backIcon} alt="Back" className="backIcon" />
                BACK
              </button>
              <button className="next-button" type="submit" onClick={handleNext}>
                {currentQuestion < questions.length - 1 ? "NEXT" : " "}
                <img src={continueIcon} alt="Continue" className="icon" />
              </button>
            </div>
          </form> 
          <ProgressBar className='progress-bar' progress={progress} />
        </>
      )}
    </div>
  );
};

export default StudioTypeform;
