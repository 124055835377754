import React, { useEffect, useState } from 'react'; // Import useState and useEffect
import { useParams, useNavigate } from 'react-router-dom';
import { getFirestore, doc, onSnapshot, updateDoc } from "firebase/firestore"; // Import Firestore functions
import { auth } from '../firebase'; // Import Firebase auth
import { signOut } from 'firebase/auth'; // Import signOut from Firebase
import './CreatorPage.css'; // Import the CSS file
import Adventureicon from '../assets/genres/adventure-dark.svg';
import Battleroyalicon from '../assets/genres/battleroyale-dark.svg';
import Fightingicon from '../assets/genres/fighting-dark.svg';
import FPSicon from '../assets/genres/fps-dark.svg';
import Minigamesicon from '../assets/genres/minigames-dark.svg';
import Racingicon from '../assets/genres/racing-dark.svg';
import RPGicon from '../assets/genres/rpg-dark.svg';
import Strategyicon from '../assets/genres/strategy-dark.svg';
import Survivalicon from '../assets/genres/survival-dark.svg';
import locationDropIcon from '../assets/images/location-drop-icon.svg';
import ReactFlagsSelect from 'react-flags-select'; // Import ReactFlagsSelect
import InstaIcon from '../assets/socials/insta-dark.svg';
import TiktokIcon from '../assets/socials/tiktok-dark.svg';
import XIcon from '../assets/socials/X-dark.svg';
import YoutubeSocialIcon from '../assets/socials/youtube-dark.svg';
import FacebookIcon from '../assets/socials/facebook-dark.svg';
import TwitchIcon from '../assets/socials/twitch-dark.svg';
import axios from 'axios'; // Import Axios  
import MessageShareDark from '../assets/share/message-share-dark.svg';
import XShareDark from '../assets/share/x-share-dark.svg';
import LinkShareDark from '../assets/share/Link-share-dark.svg';
import LinkShareLight from '../assets/share/Link-share.svg'; // Import light version
import MessageShareLight from '../assets/share/message-share.svg'; // Import light version
import XShareLight from '../assets/share/x-share.svg'; // Import light version
import { db } from '../firebase';

const genreIcons = {
  Adventure: Adventureicon,
  'Battle Royale': Battleroyalicon,
  Fighting: Fightingicon,
  FPS: FPSicon,
  'Minigames': Minigamesicon,
  Racing: Racingicon,
  RPG: RPGicon,
  Strategy: Strategyicon,
  Survival: Survivalicon,
};

const languageFlags = {
  Afrikaans: 'ZA',
  Albanian: 'AL',
  Arabic: 'AE',
  Armenian: 'AM',
  Basque: 'ES',
  Bengali: 'BD',
  Bulgarian: 'BG',
  Catalan: 'ES',
  Chinese: 'CN',
  Croatian: 'HR',
  Czech: 'CZ',
  Danish: 'DK',
  Dutch: 'NL',
  English: 'US',
  Estonian: 'EE',
  Finnish: 'FI',
  French: 'FR',
  Filipino: 'PH',
  Galician: 'ES',
  Georgian: 'GE',
  German: 'DE',
  Greek: 'GR',
  Hebrew: 'IL',
  Hindi: 'IN',
  Hungarian: 'HU',
  Icelandic: 'IS',
  Indonesian: 'ID',
  Irish: 'IE',
  Italian: 'IT',
  Japanese: 'JP',
  Korean: 'KR',
  Latvian: 'LV',
  Lithuanian: 'LT',
  Macedonian: 'MK',
  Malay: 'MY',
  Maltese: 'MT',
  Norwegian: 'NO',
  Persian: 'IR',
  Polish: 'PL',
  Portuguese: 'PT',
  Romanian: 'RO',
  Russian: 'RU',
  Serbian: 'RS',
  Slovak: 'SK',
  Slovenian: 'SI',
  Spanish: 'ES',
  Swahili: 'KE',
  Swedish: 'SE',
  Thai: 'TH',
  Turkish: 'TR',
  Ukrainian: 'UA',
  Vietnamese: 'VN',
  Welsh: 'GB',
  Yiddish: 'IL',
};

const regionNames = {
  NA: "North America",
  EU: "Europe",
  EAS: "East Asia",
  SA: "South America",
  AU: "Australia and Pacific Islands",
  CAS: "Central Asia",
  ME: "Middle East",
  NAF: "North Africa",
  RU: "Russia",
  SAF: "Sub-Saharan Africa",
  SAS: "South Asia",
  SEA: "South-East Asia",
  CA: "Central America",
};

const getHandleFromUrl = (url, platform) => {
  const urlObj = new URL(url);
  switch (platform) {
    case 'instagram':
      return urlObj.pathname.split('/')[1]; // Get the handle from the path
    case 'youtube':
      return urlObj.pathname.split('/')[1]; // Get the handle from the path
    case 'x':
      return urlObj.pathname.split('/')[1]; // Get the handle from the path
    case 'tiktok':
      return urlObj.pathname.split('/')[1]; // Get the handle from the path
    case 'twitch':
      return urlObj.pathname.split('/')[1]; // Get the handle from the path
    case 'facebook':
      return urlObj.pathname.split('/')[1]; // Get the handle from the path
    default:
      return '';
  }
};

const API_KEY = 'd56fb269b2d345f3a6261a0dc8a34a28'; // Your Embedly API key

const fetchEmbedData = async (url) => {
  if (!url) {
    console.error('No URL provided for embed data fetching');
    return null; // Return null if the URL is empty
  }
  
  try {
    const response = await axios.get(`https://api.embedly.com/1/oembed?key=${API_KEY}&url=${encodeURIComponent(url)}&native=true`);
    return response.data;
  } catch (error) {
    console.error('Error fetching embed data:', error);
    return null;
  }
};

const updateShareStatus = async (userId) => {
  const userRef = doc(db, 'users', userId); // Reference to the user's document
  try {
    await updateDoc(userRef, { shared: true }); // Update the shared property to true
    console.log('User share status updated successfully');
  } catch (error) {
    console.error('Error updating share status:', error);
  }
};

const CreatorPage = () => {
  const { userId } = useParams(); // Get userId from URL parameters
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const [embedData, setEmbedData] = useState([]); // State for embed data
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 850);
  const [isShareSelected, setIsShareSelected] = useState(false); // State to manage share button selection
  const [isHovered, setIsHovered] = useState({
    link: false,
    message: false,
    insta: false,
    x: false,
  });
  const [username, setUsername] = useState(''); // Define username state
  const [copyMessageVisible, setCopyMessageVisible] = useState(false); // State to manage visibility of copy message
  const db = getFirestore(); // Initialize Firestore

  useEffect(() => {
    if (window.self !== window.top) {
      window.top.location.href = window.location.href;
    }
  }, []);

  useEffect(() => {
    const userRef = doc(db, 'users', userId); // Use Firestore document reference
    const unsubscribe = onSnapshot(userRef, async (snapshot) => {
      const data = snapshot.data(); // Fetch data from Firestore
      if (data) {
        setUserData(data);
        setUsername(data.username); // Set username from user data
        // Fetch embed data for featured content
        if (data.featureContent) {
          const links = data.featureContent.split(',').map(link => link.trim()).filter(link => link); // Trim and filter out empty links
          const embedPromises = links.map(link => {
            console.log('Fetching embed data for URL:', link); // Log the URL
            return fetchEmbedData(link);
          });
          const embeds = await Promise.all(embedPromises);
          setEmbedData(embeds.filter(embed => embed)); // Filter out null responses
        }
      } else {
        console.error('No data found for user:', userId);
      }
      setLoading(false); // Set loading to false after data is fetched
    });

    return () => unsubscribe(); // Clean up the listener on unmount
  }, [userId, db]);

  useEffect(() => {
    const handleResize = (e) => {
      let data;
      if (data && data.context === 'iframe.resize') { // Check if data is defined and context matches
        const iframe = document.querySelector(`iframe[src="${data.src}"]`); // Check for the iframe
        if (iframe && data.height) {
          iframe.height = data.height; // Set the iframe height if available
        }
      }
    };

    window.addEventListener('message', handleResize);

    return () => {
      window.removeEventListener('message', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 850);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Set meta tags for sharing on X
    const setMetaTags = () => {
      if (!userData) return; // Check if userData is null

      const metaTags = [
        { name: 'title', content: `Check out my profile on New Game+` },
        { name: 'description', content: userData.creatorBio || 'Check out my profile on New Game+!' },
        { name: 'image', content: userData.profilePic || 'default-image-url.jpg' },
        { name: 'url', content: `https://app.newgame.me/${username}` },
        { name: 'card', content: 'summary_large_image' },
        { name: 'site', content: 'https://app.newgame.me' },
        { name: 'creator', content: '@newgameUS' },
        { name: 'twitter:site', content: 'https://app.newgame.me' },
        { name: 'twitter:creator', content: '@newgameUS' },
        { name: 'twitter:title', content: `Check out ${userData.name}'s profile on New Game+` },
        { name: 'twitter:description', content: userData.creatorBio || 'Check out my profile on New Game+!' },
        { name: 'twitter:image', content: userData.profileBanner || 'default-image-url.jpg' },
        { name: 'twitter:url', content: `https://app.newgame.me/${username}` },
        { name: 'twitter:card', content: 'summary_large_image' },
      ];

      metaTags.forEach(tag => {
        const meta = document.createElement('meta');
        Object.keys(tag).forEach(key => {
          meta.setAttribute(key, tag[key]);
        });
        document.head.appendChild(meta);
      });
    };

    setMetaTags();

    // Cleanup function to remove meta tags
    return () => {
      const tagsToRemove = ['title', 'description', 'image', 'url', 'card', 'site', 'creator', 'twitter:site', 'twitter:creator', 'twitter:title', 'twitter:description', 'twitter:image', 'twitter:url', 'twitter:card'];
      tagsToRemove.forEach(tag => {
        const meta = document.querySelector(`meta[name="${tag}"]`);
        if (meta) {
          document.head.removeChild(meta);
        }
      });
    };
  }, [userData, username]); // Run effect when userData or username changes

  const handleSignOut = async () => {
    try {
      await signOut(auth); // Sign out the user
      navigate('/login'); // Redirect to the login page after sign-out
    } catch (error) {
      console.error('Error signing out:', error); // Log any errors
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <h2>Building your profile...</h2>
        <div className="loading-spinner"></div>
      </div>
    ); // Show loading state while fetching data
  }

  if (!userData) {
    return (
      <div className="creator-page header-app-route">
        <p>Oops! We couldn't find your profile. Please sign out and try again.</p>
        <button className="sign-out-button" onClick={handleSignOut}>
          Sign Out
        </button>
      </div>
    ); // Ensure this block is correctly formatted
  }

  return (
    <>
      <div className="creator-page header-app-route">
        <div className="profile-banner">
          <img src={userData.profileBanner} alt="Profile Banner" className="banner-image" />
          <div className="overlay"></div>
          <div className="edit-button">
            <button className="nav-button" onClick={() => navigate(`/edit-creator-page/${userId}`)}>Edit Profile</button>
          </div>
          <div className="share-button" style={{display: 'flex', flexDirection: 'column'}}>
            <button 
              className={`nav-button ${isShareSelected ? 'share-selected' : ''}`} 
              onClick={() => setIsShareSelected(!isShareSelected)}
            >
              {isShareSelected ? (
                <div className="share-icons">
                  <img 
                    src={isHovered.link ? LinkShareLight : LinkShareDark} 
                    alt="Link Share" 
                    onMouseEnter={() => setIsHovered(prev => ({ ...prev, link: true }))}
                    onMouseLeave={() => setIsHovered(prev => ({ ...prev, link: false }))}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the click from triggering isShareSelected
                      updateShareStatus(userId); // Pass userId to the function
                      navigator.clipboard.writeText(`https://app.newgame.me/${username}`); // Copy the profile URL to clipboard
                      setCopyMessageVisible(true); // Show the copy message
                      setTimeout(() => setCopyMessageVisible(false), 2000); // Hide the message after 2 seconds
                    }}
                  />
                  <img 
                    src={isHovered.message ? MessageShareLight : MessageShareDark} 
                    alt="Message Share" 
                    onMouseEnter={() => setIsHovered(prev => ({ ...prev, message: true }))}
                    onMouseLeave={() => setIsHovered(prev => ({ ...prev, message: false }))}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the click from triggering isShareSelected
                      updateShareStatus(userId); // Pass userId to the function
                      const message = `Check out my profile on New Game+: https://app.newgame.me/${username}`; // Customize your message
                      window.open(`sms:?body=${encodeURIComponent(message)}`); // Initiate SMS share
                    }}
                  />
                  <img 
                    src={isHovered.x ? XShareLight : XShareDark} 
                    alt="X Share" 
                    onMouseEnter={() => setIsHovered(prev => ({ ...prev, x: true }))}
                    onMouseLeave={() => setIsHovered(prev => ({ ...prev, x: false }))}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the click from triggering isShareSelected
                      updateShareStatus(userId); // Pass userId to the function
                      const postUrl = `https://x.com/intent/tweet?text=Check out my profile on @newgameUS : https://app.newgame.me/${username}`;
                      window.open(postUrl, '_blank'); // Open the post in a new tab
                    }}
                  />
                </div>
              ) : (
                <p>Share</p>
              )}
            </button>
            {copyMessageVisible && <div className="copy-message">{isMobile ? "Copied to clipboard!" : "Copied to clipboard!"}</div>}
          </div>

          <div className="profile-info">
            <img src={userData.profilePic} alt="Profile" className="profile-pic" />
            <h2 className="profile-displayname">{userData.name}</h2>
            <div className="profile-location">
              <img src={locationDropIcon} alt="Location Icon" className="location-drop-icon" />
              <p>{regionNames[userData.locationAnswer] || userData.locationAnswer}</p>
            </div>
            {!isMobile ? (
              <div className={`question-container bio desktop-bio`}>
                <p>{userData.creatorBio}</p>
              </div>
            ) : null}
          </div>
        </div>
        <div className="profile-details-container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <div className="profile-details">
          {isMobile ? (
              <div className={`question-container bio`}>
                <p>{userData.creatorBio}</p>
              </div>
            ) : null}
            <div className="columns">
              <div className="column left-column">
              <div className="question-container social-accounts">
                <div className="section-title">
                  <h3>Socials</h3>
                </div>
                <div className="social-accounts-list">
                  {userData && userData.contentPlatforms && typeof userData.contentPlatforms === 'string' && userData.contentPlatforms.split(',').map((contentPlatform, index) => {
                    const trimmedPlatform = contentPlatform.trim();
                    const socialUrl = userData.socialUrls[trimmedPlatform];

                    return (
                      socialUrl && ( // Only render if socialUrl is populated
                        <div key={index} className="social-account-item">
                          <div className="social-links">
                            <div className="social-link-item">
                              {trimmedPlatform === 'instagram' && (
                                <>
                                  <img src={InstaIcon} alt="Instagram" className="social-icon" />
                                  <a href={socialUrl} target="_blank" rel="noopener noreferrer">
                                    @{getHandleFromUrl(socialUrl, 'instagram')}
                                  </a>
                                </>
                              )}
                              {trimmedPlatform === 'tiktok' && (
                                <>
                                  <img src={TiktokIcon} alt="TikTok" className="social-icon" />
                                  <a href={socialUrl} target="_blank" rel="noopener noreferrer">
                                    @{getHandleFromUrl(socialUrl, 'tiktok').replace('@', '')}
                                  </a>
                                </>
                              )}
                              {trimmedPlatform === 'x' && (
                                <>
                                  <img src={XIcon} alt="X" className="social-icon" />
                                  <a className="handle-link" href={socialUrl} target="_blank" rel="noopener noreferrer">
                                    @{getHandleFromUrl(socialUrl, 'x')}
                                  </a>
                                </>
                              )}
                              {trimmedPlatform === 'youtube' && (
                                <>
                                  <img src={YoutubeSocialIcon} alt="YouTube" className="social-icon youtube-icon" />
                                  <a href={socialUrl} target="_blank" rel="noopener noreferrer">
                                    @{getHandleFromUrl(socialUrl, 'youtube').replace('@', '')}
                                  </a>
                                </>
                              )}
                              {trimmedPlatform === 'facebook' && (
                                <>
                                  <img src={FacebookIcon} alt="Facebook" className="social-icon" />
                                  <a href={socialUrl} target="_blank" rel="noopener noreferrer">
                                    @{getHandleFromUrl(socialUrl, 'facebook')}
                                  </a>
                                </>
                              )}
                              {trimmedPlatform === 'twitch' && (
                                <>
                                  <img src={TwitchIcon} alt="Twitch" className="social-icon" />
                                  <a href={socialUrl} target="_blank" rel="noopener noreferrer">
                                    @{getHandleFromUrl(socialUrl, 'twitch')}
                                  </a>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="column center-column">
              <div className="question-container featured-content">
                <div className="section-title featured-content">
                  <h3>Featured Content</h3>
                </div>
                {embedData.map((embed, index) => (
                  <div key={index} className="embedded-content">
                    <div dangerouslySetInnerHTML={{ __html: embed.html }} />
                  </div>
                ))}
              </div>
            </div>
            <div className="column right-column">
              <div className="question-container genres">
                <div className="section-title">
                  <h3>Genres</h3>
                </div>
                <div className="genre-icons">
                  {userData.genrePreference.split(',').map((genre, index) => (
                    <img 
                      key={index} 
                      src={genreIcons[genre.trim()]} 
                      alt={genre.trim()} 
                      className="genre-icon" 
                    />
                  ))}
                </div>
              </div>
              <div className="question-container languages">
                <div className="section-title">
                  <h3>Languages</h3>
                </div>
                <div className="languages-list">
                  {userData.language.split(',').map((language, index) => {
                    const trimmedLanguage = language.trim();
                    const countryCode = languageFlags[trimmedLanguage]; // Get the country code

                    return (
                      <div key={index} className="language-item">
                        <ReactFlagsSelect style={{ backgroundColor: 'transparent' }}
                          selected={countryCode}
                          countries={Object.values(languageFlags)}
                          customLabels={{
                            [countryCode]: trimmedLanguage // Use the language name as the label
                          }}
                          disabled
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default CreatorPage;