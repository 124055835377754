import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import downloadIcon from '../assets/images/Download-icon.svg'; // Path to your download icon
import profileIcon from '../assets/images/Profile-icon.svg'; // Path to your profile icon
import YoutubeIcon from '../assets/socials/youtube-light.svg'; // Path to your Youtube icon
import ContinueIcon from '../assets/images/angle-grey.svg'; // Path to your continue icon
import locationIcon from '../assets/images/location-drop-icon.svg'; // Path to your location icon
import linkIcon from '../assets/images/link-chain-icon.svg'; // Path to your link icon
import UsernameIcon from '../assets/images/@.svg'; // Path to your username icon
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import './EditCreatorPage.css';
import './CreatorPage.css';
import checkIcon from '../assets/images/Check-icon.svg'; // Import the check icon
import BioIcon from '../assets/images/bio-icon.svg'; // Import the bio icon
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore"; // Import Firestore methods

const regions = [ 
  { value: "NA", label: "North America" },
  { value: "EU", label: "Europe" },
  { value: "EAS", label: "East Asia" },
  { value: "SA", label: "South America" },
  { value: "AU", label: "Australia and PI" },
  { value: "CAS", label: "Central Asia" },
  { value: "ME", label: "Middle East" },
  { value: "NAF", label: "North Africa" },
  { value: "RU", label: "Russia" },
  { value: "SAF", label: "Sub-Saharan Africa" },
  { value: "SAS", label: "South Asia" },
  { value: "SEA", label: "South-East Asia" },
  { value: "CA", label: "Central America" },
];

const EditStudioPage = () => {
  const { userId } = useParams();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [bannerFile, setBannerFile] = useState(null);
  const [profileFile, setProfileFile] = useState(null);
  const [profilePicUploaded, setProfilePicUploaded] = useState(false); // State for profile picture upload
  const [profileBannerUploaded, setProfileBannerUploaded] = useState(false); // State for banner upload
  const [isEditingDisplayName, setIsEditingDisplayName] = useState(false);
  const [tempDisplayName, setTempDisplayName] = useState(userData?.name || ''); // Temporary state for display name input
  const [tempUsername, setTempUsername] = useState(userData?.username || ''); // Temporary state for username input
  const [isEditingUsername, setIsEditingUsername] = useState(false); // State to manage editing username
  const [usernameError, setUsernameError] = useState(''); // State for username validation error
  const [usernameUpdated, setUsernameUpdated] = useState(false); // Track if username has been updated
  const [displayNameUpdated, setDisplayNameUpdated] = useState(false); // Track if display name has been updated
  const [studioResources, setStudioResources] = useState({
    link1: '',
    link2: '',
    link3: '',
    link4: ''
  });
  const [isEditingResources, setIsEditingResources] = useState(false);
  const [studioResourcesUpdated, setStudioResourcesUpdated] = useState(false); // New state for tracking social links updates

  // Temporary state for social links
  const [tempStudioResources, setTempStudioResources] = useState({
    link1: '',
    link2: '',
    link3: '',
    link4: ''
  });

  const [tempBio, setTempBio] = useState(''); // Temporary state for bio
  const [bioUpdated, setBioUpdated] = useState(false); // Track if bio has been updated
  const [error, setError] = useState(''); // State for error messages
  const [isEditingBio, setIsEditingBio] = useState(false); // State to manage editing bio
  const [isEditingFeaturedContent, setIsEditingFeaturedContent] = useState(false); // State to manage editing featured content
  const [tempFeaturedContent, setTempFeaturedContent] = useState(['', '', '', '']); // Temporary state for featured content inputs
  const [featuredContentUpdated, setFeaturedContentUpdated] = useState(false); // State to track if featured content is updated
  const [isEditingLocation, setIsEditingLocation] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedGenres, setSelectedGenres] = useState([]);
  const db = getFirestore(); // Initialize Firestore

  const fetchUserData = useCallback(async () => {
    const userRef = doc(db, 'users', userId); // Use Firestore document reference
    const snapshot = await getDoc(userRef); // Fetch the document once
    const data = snapshot.data(); // Get data from snapshot
    if (data) { 
      setUserData(data);
      setStudioResources({
        link1: data.studioResources?.link1 || '', // First link
        link2: data.studioResources?.link2 || '', // Second link
        link3: data.studioResources?.link3 || '', // Third link
        link4: data.studioResources?.link4 || ''  // Fourth link
      });
      setTempBio(data.bio || ''); // Initialize temporary bio
      // Set the initial state for featured content based on existing data
      const existingFeaturedContent = data.featureContent ? data.featureContent.split(',') : [];
      setTempFeaturedContent(existingFeaturedContent.concat(Array(4 - existingFeaturedContent.length).fill(''))); // Fill with empty strings if less than 4
      setSelectedGenres(data.genrePreference ? data.genrePreference.split(',').map(genre => genre.trim()) : []);
    } else {
      console.error('No data found for user:', userId);
    }
    setLoading(false);
  }, [userId, db]); // Include userId and db in dependencies

  useEffect(() => {
    fetchUserData(); // Call the fetch function
  }, [fetchUserData]); // Use fetchUserData as a dependency

  useEffect(() => {
    const validateUsername = async () => {
        if (tempUsername) { 
            const userRef = doc(db, 'users'); // Use Firestore document reference
            const snapshot = await getDoc(userRef); // Fetch the document once
            const data = snapshot.data(); // Get data from snapshot
            const existingUsernames = Object.values(data).map(user => user.username);

            if (existingUsernames.includes(tempUsername)) {
                setUsernameError('This username is unavailable'); // Set error message if username is taken
            } else {
                setUsernameError(''); // Clear error message if username is available
            }
        } else {
            setUsernameError(''); // Clear error message if input is empty
        }
    };

    const handler = setTimeout(() => {
        validateUsername();
    }, 500); // Validate after 500ms of inactivity

    return () => {
        clearTimeout(handler); // Cleanup timeout on unmount or when tempUsername changes
    };
  }, [tempUsername, db]);

  // URL validation function
  const isValidURL = (url) => {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z0-9\\-]+\\.)+[a-z]{2,})|' + // domain name
      'localhost|' + // localhost
      '\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}|' + // IP address
      '\\[?[a-f0-9:\\.]+\\])' + // IPv6
      '(\\:\\d+)?(\\/[-a-z0-9%_.~+@]*)*' + // port and path (allow @ in path)
      '(\\?[;&a-z0-9%_.~+=-]*)?' + // query string
      '(\\#[-a-z0-9_]*)?$','i'); // fragment locator
    return !!pattern.test(url);
  };

  const handleUpdate = async () => {
    // Validate URLs
    for (const [key, value] of Object.entries(tempStudioResources)) {
      if (value && !isValidURL(value)) {
        setError(`Invalid URL for ${key.charAt(0).toUpperCase() + key.slice(1)}`);
        return; // Stop the update if any URL is invalid
      }
    }

    // Clear error if all URLs are valid
    setError('');

    // Check if there are any updates
    const hasUpdates = 
      tempDisplayName !== userData.name ||
      tempUsername !== userData.username ||
      tempBio !== userData.bio ||
      JSON.stringify(tempStudioResources) !== JSON.stringify(userData.studioResources) ||
      selectedGenres !== userData.genrePreference ||
      studioResources !== userData.studioResources ||
      selectedRegion !== userData.location ||
      JSON.stringify(tempFeaturedContent) !== JSON.stringify(userData.featureContent.split(',')); // Check for featured content changes

    if (!hasUpdates) {
        // No updates made, navigate back to the profile page
        navigate(`/${userId}`);
        return;
    }

    const updates = {
      name: tempDisplayName || userData.name, // Use existing name if tempDisplayName is empty
      username: tempUsername || userData.username, // Use existing username if tempUsername is empty
      studioProfileBanner: userData.studioProfileBanner,
      studioProfilePic: userData.studioProfilePic,
      location: selectedRegion || userData.location,
      studioResources: tempStudioResources || userData.studioResources,
      studioBio: tempBio || userData.studioBio,
    };

    // Handle file uploads if any
    if (bannerFile) {
      const bannerURL = await uploadFile(bannerFile, `profileBanners/${userId}/${bannerFile.name}`);
      updates.profileBanner = bannerURL;  
    }

    if (profileFile) {
      const profileURL = await uploadFile(profileFile, `profilePics/${userId}/${profileFile.name}`);
      updates.profilePic = profileURL;
    }

    const userRef = doc(db, 'users', userId); // Use Firestore document reference
    await updateDoc(userRef, updates); // Update Firestore document
    navigate(`/${userId}`); // Redirect after update
  };

  const uploadFile = async (file, path) => {
    const storage = getStorage();
    const storageReference = storageRef(storage, path);
    await uploadBytes(storageReference, file);
    return await getDownloadURL(storageReference);
  };

  const handleDisplayNameClick = () => {
    setIsEditingDisplayName(!isEditingDisplayName);
  };

  const handleLocationClick = () => {
    setIsEditingLocation(!isEditingLocation);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <ClipLoader size={50} color={"#123abc"} loading={loading} />
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="edit-creator-page header-app-route">
      <div className="profile-banner">
        <img src={userData.studioprofileBanner} alt="Profile Banner" className="banner-image" />
        <div className="profile-info">
          <img src={userData.studioProfilePic} alt="Profile" className="profile-pic" />
          <h2>{userData.name}</h2>
        </div>
      </div>
      <div className="edit-buttons-container">
        <div className="edit-buttons">
          <button onClick={handleDisplayNameClick}>
            <div className="edit-buttons-icon" style={{ backgroundColor: displayNameUpdated ? '#E608C6' : '#5E00D4' }}>
                <img src={profileIcon} alt="Profile-icon" />
            </div>
            Edit Display Name
            <img className="continue-icon" src={ContinueIcon} alt="Continue-icon" />
            </button>
            {isEditingDisplayName && (
                <input
                    type="text"
                    className="input"
                    placeholder={userData.name} // Assuming userData.name holds the current display name
                    value={tempDisplayName} // Bind the input value to tempDisplayName
                    onChange={(e) => {
                        setTempDisplayName(e.target.value);
                        setDisplayNameUpdated(true); // Mark as updated
                    }}
                    style={{
                      padding: '8px 10px', // Increase padding for height
                      fontSize: '16px', // Adjust font size
                      lineHeight: '1.5', // Adjust line height
                      height: 'auto', // Allow height to adjust based on content
                      display: 'block', // Ensure it behaves like a block element
                    }}
                />
            )}

            <button onClick={() => setIsEditingUsername(!isEditingUsername)}>
            <div className="edit-buttons-icon" style={{ backgroundColor: usernameUpdated ? '#E608C6' : '#5E00D4' }}>
                <img src={UsernameIcon} alt="Username-icon" />
            </div>
            Edit Username
            <img className="continue-icon" src={ContinueIcon} alt="Continue-icon" />
            </button>
            {isEditingUsername && (
                <>
                    <input
                        type="text"
                        className="input"
                        placeholder={userData.username} // Assuming userData.username holds the current username
                        value={tempUsername} // Bind the input value to tempUsername
                        onChange={(e) => {
                          setTempUsername(e.target.value);
                          setUsernameUpdated(true); // Mark as updated
                        }}
                        style={{
                          padding: '8px 10px', // Increase padding for height
                          fontSize: '16px', // Adjust font size
                          lineHeight: '1.5', // Adjust line height
                          height: 'auto', // Allow height to adjust based on content
                          display: 'block', // Ensure it behaves like a block element
                        }}
                    />
                    {usernameError && <p style={{ color: 'red' }}>{usernameError}</p>} {/* Display error message */}
                </>
            )}

            <button onClick={() => document.getElementById('banner-input').click()}>
            <div className="edit-buttons-icon" style={{ backgroundColor: profileBannerUploaded ? '#E608C6' : '#5E00D4' }}>
                <img src={profileBannerUploaded ? checkIcon : downloadIcon} alt="Banner-icon" />
            </div>
            Edit Banner Picture
            <img className="continue-icon" src={ContinueIcon} alt="Continue-icon" />
            </button>
        <input
          type="file"
          id="banner-input"
          style={{ display: 'none' }}
          onChange={(e) => {
            setBannerFile(e.target.files[0]);
            setProfileBannerUploaded(true); // Set to true when a file is selected
          }}
        />
        <button onClick={() => document.getElementById('profile-input').click()}>
            <div className="edit-buttons-icon" style={{ backgroundColor: profilePicUploaded ? '#E608C6' : '#5E00D4' }}>
                <img src={profilePicUploaded ? checkIcon : downloadIcon} alt="Profile-icon" />
            </div>
            Edit Profile Picture
            <img className="continue-icon" src={ContinueIcon} alt="Continue-icon" />
            </button>
        <input
          type="file"
          id="profile-input"
          style={{ display: 'none' }}
          onChange={(e) => {
            setProfileFile(e.target.files[0]);
            setProfilePicUploaded(true); // Set to true when a file is selected
          }}
        />
        <button onClick={() => setIsEditingResources(!isEditingResources)}>
            <div className="edit-buttons-icon" style={{ backgroundColor: studioResourcesUpdated ? '#E608C6' : '#5E00D4' }}>
                <img src={YoutubeIcon} alt="Youtube-icon" />
            </div>
            Edit Studio Resources
            <img className="continue-icon" src={ContinueIcon} alt="Continue-icon" />
            </button>
        {isEditingResources && (
          <div className="social-links-container">
            <div className="social-link">
              <input
                type="text"
                placeholder="Resource 1"
                value={tempStudioResources}
                onChange={(e) => {
                  setTempStudioResources(prev => ({ ...prev, link1: e.target.value }));
                  setStudioResourcesUpdated(true); // Mark as updated
                }}
                className='input'
              />
            </div>
            <div className="social-link">
              <input
                type="text"
                placeholder="Resource 2" 
                value={tempStudioResources}
                onChange={(e) => {
                  setTempStudioResources(prev => ({ ...prev, link2: e.target.value }));
                  setStudioResourcesUpdated(true); // Mark as updated
                }}
                className='input'
              />
            </div>
            <div className="social-link">
              <input
                type="text"
                placeholder="Resource 3"
                value={tempStudioResources}
                onChange={(e) => {
                  setTempStudioResources(prev => ({ ...prev, link3: e.target.value }));
                  setStudioResourcesUpdated(true); // Mark as updated
                }}
                className='input'
              />
            </div>
            <div className="social-link">
              <input
                type="text"
                placeholder="Resource 4"
                value={tempStudioResources}
                onChange={(e) => {
                  setTempStudioResources(prev => ({ ...prev, link4: e.target.value }));
                  setStudioResourcesUpdated(true); // Mark as updated
                }}
                className='input'
              />
            </div>
          </div>
        )}
        <button onClick={() => setIsEditingBio(!isEditingBio)}>
          <div className="edit-buttons-icon" style={{ backgroundColor: bioUpdated ? '#E608C6' : '#5E00D4' }}>
            <img src={BioIcon} alt="Bio-icon" />
          </div>
          Edit Bio
          <img className="continue-icon" src={ContinueIcon} alt="Continue-icon" />
        </button>
        {isEditingBio && (
          <>
            <input
                type="text"
                placeholder="Write your bio here..."
                value={tempBio}
                onChange={(e) => {
                    if (e.target.value.length <= 250) { // Check if the length is within the limit
                        setTempBio(e.target.value);
                        setBioUpdated(true); // Mark as updated
                    }
                }}
                className='input'
                maxLength={250} // Limit input to 250 characters
                style={{
                    padding: '8px 10px', // Increase padding for height
                    fontSize: '16px', // Adjust font size
                    lineHeight: '1.5', // Adjust line height
                    height: 'auto', // Allow height to adjust based on content
                    display: 'block', // Ensure it behaves like a block element
                }}
            />
            <p style={{fontSize: '12px', color: '#5E00D4', margin: '0px'}}>{250 - tempBio.length} characters remaining</p> {/* Display remaining characters */}
          </>
        )}
        {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error message */}
        <button onClick={() => setIsEditingFeaturedContent(!isEditingFeaturedContent)}>
            <div className="edit-buttons-icon" style={{backgroundColor: featuredContentUpdated ? '#E608C6' : '#5E00D4'}}>
                <img src={linkIcon} alt="Link-icon" />
            </div>
            Edit Featured Content
            <img className="continue-icon" src={ContinueIcon} alt="Continue-icon" />
        </button>
        {isEditingFeaturedContent && (
            <div className="featured-content-inputs" style = {{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                {tempFeaturedContent.map((content, index) => (
                    <div className="content-input-container" key={index}>
                        <input
                            type="text"
                            placeholder={content || `Enter URL ${index + 1}`} // Use current content as placeholder
                            value={content}
                            onChange={(e) => {
                                const newContent = [...tempFeaturedContent];
                                newContent[index] = e.target.value;
                                setTempFeaturedContent(newContent);
                                setFeaturedContentUpdated(true); // Mark as updated
                            }}
                            className='input'
                        />
                    </div>
                ))}
            </div>
        )}
        <button onClick={handleLocationClick}>
          <div className="edit-buttons-icon">
            <img src={locationIcon} alt="Location-icon" />
          </div>
          Edit Location
          <img className="continue-icon" src={ContinueIcon} alt="Continue-icon" />
        </button>
        {isEditingLocation && (
          <select
            className="input"
            style={{
              padding: '8px 10px', // Increase padding for height
              fontSize: '16px', // Adjust font size
              lineHeight: '1.5', // Adjust line height
              height: 'auto', // Allow height to adjust based on content
              display: 'block', // Ensure it behaves like a block element
            }}
            onChange={(e) => setSelectedRegion(e.target.value)}
          >
            <option value="">Select a region</option>
            {regions.map((region) => (
              <option key={region.value} value={region.value}>
                {region.label}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className="update-button-container">
        <button className="update-button" style={{textAlign: 'center'}} onClick={handleUpdate}>Update Profile</button>
      </div>
    </div>
    </div>
  );
};

export default EditStudioPage;
